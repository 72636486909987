import React from 'react';
import Button from '../Button';
import ExitIcon from './ExitIcon';
import IconButton from './IconButton';

export default function Modal({
    title,
    subtitle,
    children,
    isOpen,
    onClose,
    onAction,
    loading,
    actionText,
}: {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
    isOpen: boolean;
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onAction?: () => void;
    loading?: boolean;
    actionText?: string;
}) {
    if (!isOpen) {
        return <></>;
    }

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-400 bg-opacity-60 flex">
            <div className="relative bg-white m-auto flex-col flex rounded-md">
                <div>
                    <div className="text-xl text-white p-6 mb-2 bg-gray-500">{title}</div>
                    {/* <hr /> */}
                    {subtitle && (
                        <div className="text-sm pt-6 pl-6 pr-6 mb-2">
                            <div>{subtitle}</div>
                        </div>
                    )}
                    <div className="p-6">{children}</div>
                    {onAction && (
                        <div>
                            <hr />
                            <div className="flex flex-row-reverse">
                                <div className="m-5">
                                    <Button disabled={loading} onClick={onAction}>
                                        {actionText || 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <span className="absolute top-0 right-0 p-4">
                    <IconButton onClick={onClose}>
                        <ExitIcon />
                    </IconButton>
                </span>
            </div>
        </div>
    );
}
