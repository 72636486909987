import { useParams, RouteComponentProps } from '@reach/router';
import React, { useEffect, useState, useRef } from 'react';
import { addDays, format } from 'date-fns';
import { BlobServiceClient } from '@azure/storage-blob';
import md5 from 'md5';
import * as uuid from 'uuid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactSelect from 'react-select';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import TypeAhead from '../../components/TypeAhead';
import TextArea from '../../components/TextArea';
import { useUser } from '../../queries/Accounts';
import { Creditor, useGetCreditors } from '../../queries/Creditors';
import { OrderDTO, useCreateOrder } from '../../queries/Orders';
import {
    useGetRequisition,
    useUpdateRequisition,
    useCreateRequisitionItem,
    RequisitionItem,
    useApproveRequisition,
    Requisition,
    useSendRFQ,
    SendRFQDTO,
    useUploadDocument,
    UpsertDocumentDTO,
    useDeleteRequisitionItem,
    EditableRequisitionItem,
    useEditRequisitionItem,
    useToggleReviewRequisition,
    useCancelRequisition,
    useAddNote,
    useRemoveNote,
} from '../../queries/Requisitions';
import { CostCode, useGetCostCodes } from '../../queries/CostCodes';
import { components } from '../../queries/api/specs';
import { useGetUnits } from '../../queries/Units';
import { Currency, Vat } from '../../queries/BusinessUnits';
import { domain } from '../../d-man';
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import {
    forceDownload,
    handleWebDownload,
    validateEmail,
    fomatCurrencySymbol,
} from '../../utils/Utils';
import FileUpload from '../../components/FileUpload';
import { useGetTrades } from '../../queries/Trades';
import { useGetUsers } from '../../queries/Users';
import { useCreateMaterial, useGetMaterials } from '../../queries/Materials';
import Select from '../../components/Select';
import { useGetUserRoles } from '../../queries/Roles';
import { useGetPermissions } from '../../queries/Permissions';
import { ProjectAddress } from '../../queries/Projects';
import { TrashCan } from '../../components/Icons';

type schemas = components['schemas'];

const FILE_TYPES = {
    QUOTE: 'QUOTE',
    DOCUMENT: 'DOCUMENT',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function RequisitionDetail(props: RouteComponentProps) {
    const { navigate } = props;
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
    const [openSendRFQModal, setOpenSendRFQModal] = useState(false);
    const [openApproveRFQModal, setOpenApproveRFQModal] = useState(false);
    const [openDownloadRFQModal, setOpenDownloadRFQModal] = useState(false);
    const [openUploadQuotesModal, setOpenUploadQuotesModal] = useState(false);
    const [openUploadDocumentsModal, setOpenUploadDocumentsModal] = useState(false);
    const [openImportLineItemsModal, setOpenImportLineItemsModal] = useState(false);
    const [openViewQuotesModal, setOpenViewQuotesModal] = useState(false);
    const user = useUser();

    const MAX_USERS = 10000;
    const users = useGetUsers(0, MAX_USERS);
    const usersRefs = useRef(users);
    const userRoles = useGetUserRoles();

    const createMaterial = useCreateMaterial();

    const [orderErrorMessage, setOrderErrorMessage] = useState(undefined as string | undefined);
    const [sendRFQErrorMessage, setSendRFQErrorMessage] = useState('');
    const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState('');
    const [importCsvErrorMessage, setImportCsvErrorMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [createLineItemLoading, setCreateLineItemLoading] = useState(false);

    const [orderDescription, setOrderDescription] = useState(undefined as string | undefined);
    const [orderOrderType, setOrderOrderType] = useState('0' as string | undefined);
    const [orderRequiredAt, setOrderRequiredAt] = useState(
        format(addDays(new Date(), 1), 'yyyy-MM-dd')
    );

    const [deliveryAddressId, setDeliveryAddressId] = useState('');

    const [dateRequiredAt, setDateRequiredAt] = useState(new Date());

    const [filteredCreditors, setFilteredCreditors] = useState(
        [] as { id: string; name: string }[]
    );
    const [selectedCreditorId, setSelectedCreditorId] = useState('');

    const [unitId, setUnitId] = useState(undefined as string | undefined);
    const [unitName, setUnitName] = useState(undefined as string | undefined);

    const [vatType, setVatType] = useState(undefined as string | undefined);
    const [vatTypeName, setVatTypeName] = useState(undefined as string | undefined);

    const [creditorId, setCreditorId] = useState(undefined as string | null | undefined);
    const [creditorName, setCreditorName] = useState(undefined as string | null | undefined);

    const [costCodeId, setCostCodeId] = useState(undefined as string | undefined);
    const [costCodeName, setCostCodeName] = useState(undefined as string | null | undefined);

    const [companyId, setCompanyId] = useState(undefined as string | undefined);

    const [description, setDescription] = useState(undefined as string | undefined);
    const [quantity, setQuantity] = useState(undefined as string | undefined);
    const [currency, setCurrency] = useState(undefined as string | undefined);
    const [vat, setVat] = useState(undefined as string | undefined);
    const [allowable, setAllowable] = useState(undefined as string | undefined);
    const [buyOutRate, setBuyOutRate] = useState(undefined as string | undefined);
    const [email, setEmail] = useState('');
    const [cc, setCC] = useState('');
    const [allocation, setAllocation] = useState('');
    const [quoteFiles, setQuoteFiles] = useState([] as File[]);
    const [documentFiles, setDocumentFiles] = useState([] as File[]);
    const [importFile, setImportFile] = useState(undefined as File | undefined);
    const [selectedLineItems, setSelectedLineItems] = useState(
        [] as { lineItemId: string; checked: boolean }[]
    );

    const [requisitionHash, setRequisitionHash] = useState(undefined as string | undefined);
    const [tableLineItems, setTableLineItems] = useState([] as EditableRequisitionItem[]);

    const params = useParams();
    const getId = () => params.id;

    const requisition = useGetRequisition(getId());
    const requisitionRef = useRef(requisition);
    const requisitionCancel = useCancelRequisition(getId());
    const updateRequisition = useUpdateRequisition(getId());
    const requisitionApprove = useApproveRequisition(getId());
    const requisitionSubmitReview = useToggleReviewRequisition(getId(), true);
    const requisitionRetractReview = useToggleReviewRequisition(getId(), false);
    const sendRFQ = useSendRFQ();
    const uploadDocument = useUploadDocument(getId());
    const createRequisitionItem = useCreateRequisitionItem(getId());
    const editRequisitionItem = useEditRequisitionItem();
    const deleteRequisitionItem = useDeleteRequisitionItem();
    const order = useCreateOrder();

    const addNote = useAddNote();
    const removeNote = useRemoveNote();

    const [commentsEditable, setCommentsEditable] = useState(false);
    const [commentsField, setCommentsField] = useState('');
    const [note, setNote] = useState('');

    const trades = useGetTrades();

    const getCreditorsService = useGetCreditors(
        undefined,
        undefined,
        undefined,
        undefined,
        companyId
    );

    const getRequisitionTrade = () =>
        trades.data?.items.find((i) => i.id === requisition.data?.tradeId);
    const materials = useGetMaterials();
    const costCodes = useGetCostCodes(undefined, undefined, requisition?.data?.tradeId);
    const units = useGetUnits();

    const getPermissions = useGetPermissions('REQUISITION', getId());

    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find(
            (p) => p.code === 'Requisition_Show_Requisition_Details_screen'
        )?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const calculateBuyoutRateTotal = (lineItems: RequisitionItem[]) => {
        return (
            (lineItems &&
                lineItems.reduce(
                    (a, b) => ({
                        displayAmount: a.displayAmount + (b.quantity || 0) * (b.buyOutRate || 0),
                    }),
                    {
                        displayAmount: 0,
                    }
                ).displayAmount) ||
            0
        );
    };

    const calculateAllowableTotal = (lineItems: RequisitionItem[]) => {
        return (
            (lineItems &&
                lineItems.reduce(
                    (a, b) => ({
                        displayAmount: a.displayAmount + (b.quantity || 0) * (b.allowable || 0),
                    }),
                    {
                        displayAmount: 0,
                    }
                ).displayAmount) ||
            0
        );
    };

    useEffect(() => {
        if (users.data) {
            setCC(users.data?.find((i) => i.id === user?.userid)?.email || '');
        }
    }, [users.data, user, setCC]);

    useEffect(() => {
        if (requisition.data && requisition.data.lineItems?.length && selectedCreditorId) {
            const creditor = requisition.data.lineItems.find(
                (i) => i.creditor?.id === selectedCreditorId
            )?.creditor;
            setEmail(creditor?.email || '');
        }

        if (requisition.data && requisition.data.notes?.length) {
            setNote(requisition.data.notes[requisition.data.notes?.length - 1].note);
        }
    }, [requisition.data, selectedCreditorId, setEmail]);

    useEffect(() => {
        if (requisition.data?.description) {
            setCommentsField(requisition.data?.description);
        }
        if (requisition.data?.project?.addresses && requisition.data?.project?.addresses?.length) {
            setDeliveryAddressId(requisition.data?.project?.addresses[0].id || '');
        }
    }, [requisition.data]);

    useEffect(() => {
        requisitionRef.current.reset();
    }, [requisitionRef]);

    useEffect(() => {
        usersRefs.current.reset();
    }, [usersRefs]);

    useEffect(() => {
        if (
            requisition.data &&
            (!requisitionHash || requisitionHash !== md5(JSON.stringify(requisition.data)))
        ) {
            setRequisitionHash(md5(JSON.stringify(requisition.data)));
        }
    }, [requisition.data, requisitionHash]);

    useEffect(() => {
        if (requisition.data && requisition.data.project && requisition.data.project.businessUnit) {
            const { defaultCurrency, defaultVat } = requisition.data.project.businessUnit;
            setCurrency(defaultCurrency?.symbol || '');
            setVat(defaultVat?.amount?.toString() || '');
            setVatType(
                (defaultVat && defaultVat.amount && defaultVat.amount > 0 ? '0' : '1') || ''
            );

            setCompanyId(requisition.data.project.businessUnit.company?.id);
        }
    }, [requisition.data]);

    useEffect(() => {
        if (companyId) {
            getCreditorsService
                .execute({
                    companyId,
                })
                .then(() => getCreditorsService.reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, requisition.data]);

    function submitUpdate(date?: Date) {
        if (requisition.data) {
            updateRequisition
                .execute({
                    description: commentsField,
                    requiredAt: date || dateRequiredAt,
                })
                .then(() => requisition?.reset());
        }
    }

    useEffect(() => {
        if (editRequisitionItem.error) {
            setErrorMessage(editRequisitionItem.error || 'Failed to update Requisition Item');
        }

        if (createRequisitionItem.error) {
            setErrorMessage(createRequisitionItem.error || 'Failed to create new Requisition Item');
        }

        if (requisitionApprove.error) {
            setErrorMessage(requisitionApprove.error || 'Failed to approve Requisition');
        }
    }, [editRequisitionItem.error, createRequisitionItem.error, requisitionApprove.error]);

    const clearFields = () => {
        setAllowable(undefined);
        setDescription(undefined);
        setQuantity(undefined);
        setVatType(undefined);
        setVatTypeName(undefined);
        setCostCodeName(undefined);
        setCostCodeId(undefined);
        setUnitId(undefined);
        setUnitName(undefined);
        setCreditorId(undefined);
        setCreditorName(undefined);
        setBuyOutRate(undefined);
        setVat(undefined);
        setCurrency(undefined);
    };

    useEffect(() => {
        if (requisition.data?.lineItems && getPermissions.data) {
            clearFields();
            const tempItems = [...requisition.data.lineItems]; // NOTE: remember to copy not re-assign
            if (user) {
                if (
                    (!requisition.data.approvedAt &&
                        !requisition.data.approvedBy &&
                        getPermissions.data?.items.find(
                            (p) => p.code === 'Requisition_Edit_LineItem'
                        )?.hasAccess) ||
                    getPermissions.data?.items.find(
                        (p) => p.code === 'Requisition_Edit_LineItem_Override'
                    )?.hasAccess
                ) {
                    // NOTE: always add an empty row for creating a new line item
                    tempItems.push({ editing: true } as EditableRequisitionItem);
                }
                if (requisition.data?.lineItems.length) {
                    tempItems.push({
                        allowable: calculateAllowableTotal(requisition.data?.lineItems),
                        buyOutRate: calculateBuyoutRateTotal(requisition.data?.lineItems),
                        currency: requisition.data?.lineItems[0].currency,
                        totalField: true,
                    });
                }

                setTableLineItems(tempItems);
            }
        }
    }, [requisition.data, user, getPermissions.data]);

    const getStatus = (requisitionItem: Requisition) => {
        if (
            requisitionItem.isCancelled ||
            requisitionItem.cancelledBy !== null ||
            requisitionItem.cancelledAt !== null
        ) {
            return 'Cancelled';
        }
        if (
            (requisitionItem.approvedAt !== null || requisitionItem.approvedBy !== null) &&
            requisitionItem.lineItems &&
            requisitionItem?.lineItems.length > 0 &&
            requisitionItem.lineItems.every((i: RequisitionItem) => i.orderId !== null)
        ) {
            return 'Completed';
        }
        if (
            (requisitionItem.reviewRequestedAt !== null ||
                requisitionItem.reviewRequestedBy !== null) &&
            (requisitionItem.approvedAt !== null || requisitionItem.approvedBy !== null)
        ) {
            return 'Sent back by Buyer';
        }
        if (requisitionItem.approvedAt !== null || requisitionItem.approvedBy !== null) {
            return 'Approved';
        }
        if (
            requisitionItem.reviewRequestedAt !== null ||
            requisitionItem.reviewRequestedBy !== null
        ) {
            return 'Submitted to QS';
        }
        return 'Draft';
    };

    const handleAzureDownload = async (file: {
        id?: string | undefined;
        name?: string | undefined;
        requisitionId?: string | null | undefined;
        deliveryId?: string | null | undefined;
        returnId?: string | null | undefined;
        blobId?: string | null | undefined;
        uploadedAt?: string | null | undefined;
    }) => {
        if (file && file.blobId) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            const blockBlobClient = containerClient.getBlockBlobClient(file.blobId);
            // eslint-disable-next-line no-await-in-loop
            const uploadBlobResponse = await blockBlobClient.download();
            if (uploadBlobResponse.requestId) {
                const blobby = (await uploadBlobResponse.blobBody) || new Blob();
                const url = window.URL.createObjectURL(new Blob([blobby]));
                forceDownload(url, file.name || '');
            }
        }
    };

    const uploadFiles = async (fileType: string, files: File[]) => {
        const blobs: { id: string; name: string; type: string }[] = [];
        if (files) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            // eslint-disable-next-line no-restricted-syntax
            for (const file of files) {
                const id = uuid.v4();
                const blockBlobClient = containerClient.getBlockBlobClient(id);
                // eslint-disable-next-line no-await-in-loop
                const uploadBlobResponse = await blockBlobClient.uploadData(file);
                if (uploadBlobResponse.requestId) {
                    blobs.push({
                        id,
                        name: file.name,
                        type: fileType,
                    });
                }
            }
        }
        return blobs;
    };

    const deleteFile = async (id: string) => {
        const remove = domain.delete(`/files/${id}`);
        await remove.execute();
        requisition.reset();
    };

    const importCsv = async () => {
        if (importFile) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', importFile);
            const [err, data] = await domain
                .post(`/requisitions/${getId()}/lineitem/bulk`, {})
                .execute(formData);
            if (!data) {
                setImportCsvErrorMessage(err || '');
                setIsLoading(false);
                return;
            }
            requisition.reset();
            setIsLoading(false);
            setOpenImportLineItemsModal(false);
        }
    };

    const handleSelectOrderCreditor = (id: string) => {
        setSelectedLineItems([{ lineItemId: id, checked: false }]);
        setSelectedCreditorId(id);
    };

    const handleCommentsChange = (comments: string) => {
        setCommentsField(comments);
    };

    const filterCreditors = (creatingOrders?: boolean, allowNulls?: boolean) => {
        const distinctCreditorIds = [] as string[];
        const filteredCreditorsArr: { id: string; name: string }[] =
            requisition.data?.lineItems
                ?.filter((i) => !creatingOrders || !i.orderId) // not when creating orders or are already orders
                .filter((i) => i.creditor) // where creditor is not null
                .map((el) => ({
                    id: el.creditor?.id || '',
                    name: el.creditor?.name || '',
                })) || [];

        let distinctFilteredCreditors = filteredCreditorsArr.filter((el) => {
            if (!distinctCreditorIds.includes(el.id)) {
                distinctCreditorIds.push(el.id);
                return true;
            }
            return false;
        });

        if (allowNulls) {
            // Catch all
            distinctFilteredCreditors = distinctFilteredCreditors.concat({
                id: '-1',
                name: 'N/A ',
            });
        }

        setFilteredCreditors(distinctFilteredCreditors);
        setSelectedCreditorId(distinctFilteredCreditors[0].id);
    };

    const filterCheckedArray = (id: string) => {
        const currentCheckbox = selectedLineItems.find((el) => el.lineItemId === id);
        return currentCheckbox?.checked;
    };

    const handleSendRFQModalOpen = () => {
        filterCreditors(undefined, true);
        setOpenSendRFQModal(true);
    };

    const handleSendRFQModalClose = () => {
        setSelectedLineItems([]);
        setOpenSendRFQModal(false);
    };

    const handleDownloadRFQModalOpen = () => {
        filterCreditors(undefined, true);
        setOpenDownloadRFQModal(true);
    };

    const handleDownloadRFQModalClose = () => {
        setSelectedLineItems([]);
        setOpenDownloadRFQModal(false);
    };

    const handleUploadQuotesModalOpen = () => {
        setOpenUploadQuotesModal(true);
    };

    const handleUploadQuotesModalClose = () => {
        setOpenUploadQuotesModal(false);
        setUploadFileErrorMessage('');
    };

    const handleUploadDocumentsModalOpen = () => {
        setOpenUploadDocumentsModal(true);
    };

    const handleUploadDocumentsModalClose = () => {
        setOpenUploadDocumentsModal(false);
        setUploadFileErrorMessage('');
    };

    const handleViewQuotesModalClose = () => {
        setOpenViewQuotesModal(false);
    };

    const updateUploadedQuotes = (files: File[]) => {
        setQuoteFiles(files);
    };

    const updateUploadedDocuments = (files: File[]) => {
        setDocumentFiles(files);
    };

    const handleCreateOrderModalOpen = () => {
        filterCreditors(true);
        setOpenCreateOrderModal(true);
    };

    const handleCreateOrderModalClose = () => {
        setSelectedLineItems([]);
        setOpenCreateOrderModal(false);
    };

    const handleCSVTemplateDownload = async () => {
        setIsLoading(true);
        const [, data, response] = await domain
            .get('requisitions/lineitem/bulk/template', {
                requestConfig: {
                    responseType: 'blob', // important
                },
            })
            .execute();
        if (!data) {
            setIsLoading(false);
            return;
        }
        handleWebDownload(data, response);
        setIsLoading(false);
    };

    const handlePDFDownload = async () => {
        if (requisition.data) {
            if (!selectedCreditorId || selectedLineItems.length <= 0) {
                setSendRFQErrorMessage('Please select at least one line item');
                return;
            }
            setIsLoading(true);
            const [, data, response] = await domain
                .post('requisitions/download', {
                    requestConfig: {
                        responseType: 'blob', // important
                    },
                })
                .execute({
                    creditorId: selectedCreditorId === '-1' ? null : selectedCreditorId,
                    lineItemIds: selectedLineItems
                        .filter((i) => i.checked)
                        .map((i) => i.lineItemId),
                    comments: requisition.data.description,
                    deliveryAddressId,
                });
            if (!data) {
                setIsLoading(false);
                return;
            }
            handleWebDownload(data, response);
            setIsLoading(false);
        }
    };

    const formatVats = (): { id: string; name: string }[] => {
        if (requisition.data) {
            const { vats, businessUnit } = requisition.data.project || {};
            const defaultVat = businessUnit?.defaultVat;
            const mappedVats =
                (vats && (vats.map((formattedVat: Vat) => formattedVat.amount) as number[])) || [];
            if (defaultVat && defaultVat.amount && !mappedVats.includes(defaultVat?.amount)) {
                mappedVats.push(defaultVat?.amount);
            }
            return mappedVats.map((formatAmount: number) => ({
                id: formatAmount.toString(),
                name: formatAmount.toString(),
            }));
        }
        return [];
    };

    const formatVATTypes = () => {
        return [
            { value: 'NORMAL_VAT', label: 'Normal' },
            { value: 'ZERO_RATED', label: 'Zero Rated' },
            { value: 'NO_VAT', label: 'No VAT' },
        ].map(
            (vatTypeItem) =>
                ({
                    value: vatTypeItem.value,
                    label: vatTypeItem.label,
                } as { value: string; label: string })
        );
    };

    const formatMaterials = () => {
        if (materials && materials.data) {
            return materials.data?.items.map(
                (material) =>
                    ({
                        id: material.description,
                        name: material.description,
                    } as { id: string; name: string })
            );
        }
        return [];
    };

    const formatOrderTypes = () => {
        const orderTypes = [
            { value: '0', label: 'Single' },
            { value: '1', label: 'Bulk' },
        ];
        return orderTypes;
    };

    const formatCreditors = () => {
        if (getCreditorsService && getCreditorsService.data && getCreditorsService.data.items) {
            return getCreditorsService.data.items.map(
                (creditor: Creditor) =>
                    ({
                        id: creditor.id,
                        name: `${creditor.code} - ${creditor.name}`,
                    } as { id: string; name: string })
            );
        }
        return [];
    };

    useEffect(() => {
        if (order.error) {
            return setOrderErrorMessage(order.error);
        }
        return setOrderErrorMessage('');
    }, [order.error, orderErrorMessage]);

    const formatCostCodes = () => {
        if (costCodes && costCodes.data && costCodes.data.items.length > 0) {
            return costCodes.data.items
                .filter(
                    (ccode: { trade: { id: string } }) =>
                        ccode.trade.id === requisition.data?.tradeId
                )
                .map((costCode: CostCode) => ({
                    value: costCode.id,
                    label: `${costCode.code} - ${costCode.name}`,
                }));
        }
        return [];
    };

    const formatEmails = () => {
        if (users && users.data && users.data?.length > 0) {
            return users.data.map((theUser: { email: string }) => ({
                id: theUser.email,
                name: theUser.email,
            }));
        }
        return [];
    };

    const formatDeliveryAddresses = () => {
        if (requisition.data && requisition.data?.project?.addresses) {
            const filteredAddresses = requisition.data?.project?.addresses.map(
                (address: ProjectAddress, i) =>
                    ({
                        value: address.id,
                        label: `Address ${i + 1}`,
                    } as { value: string; label: string })
            );
            return filteredAddresses;
        }
        return [];
    };

    const formatDeliveryAddressSelections = () => {
        if (requisition.data && requisition.data?.project?.addresses) {
            const filteredAddresses = requisition.data?.project?.addresses.map(
                (address: ProjectAddress) =>
                    ({
                        value: address.id,
                        label: `${address.address1} ${address.address2} ${address.address3}`,
                    } as { value: string; label: string })
            );
            return filteredAddresses;
        }
        return [];
    };

    const getDeliveryAddressDetails = () => {
        if (requisition.data && requisition.data.project?.addresses && deliveryAddressId) {
            const filteredAddress = requisition.data.project?.addresses.find(
                (i) => i.id === deliveryAddressId
            );
            const addressLines = [];
            if (filteredAddress?.address1) {
                addressLines.push(filteredAddress?.address1);
            }
            if (filteredAddress?.address2) {
                addressLines.push(filteredAddress?.address2);
            }
            if (filteredAddress?.address3) {
                addressLines.push(filteredAddress?.address3);
            }
            if (filteredAddress?.postCode) {
                addressLines.push(filteredAddress?.postCode);
            }
            return addressLines.join('\n') || '';
        }
        return '';
    };

    const formatAllocations = () => {
        if (userRoles && userRoles.data && users && users.data && users.data?.length > 0) {
            return users.data
                .filter((filterUser) =>
                    userRoles.data
                        ?.filter((j) => j.roleName.toUpperCase() === 'BUYER')
                        .some((j) => j.userName.toLowerCase() === filterUser.userName.toLowerCase())
                )
                .map((theUser: { id: string; firstName: string; lastName: string }) => ({
                    value: theUser.id,
                    label: `${theUser.firstName} ${theUser.lastName}`,
                }));
        }
        return [];
    };

    const formatUnits = () => {
        if (units && units.data && units.data.items?.length > 0) {
            return units.data.items.map((unit: { id: string; description: string }) => ({
                value: unit.id,
                label: unit.description,
            }));
        }
        return [];
    };

    const formatCurrencies = (): { value: string; label: string }[] => {
        if (requisition.data) {
            const { currencies, businessUnit } = requisition.data.project || {};
            const defaultCurrency = businessUnit?.defaultCurrency;
            const mappedCurrencies =
                (currencies &&
                    (currencies.map(
                        (formattedCurrency: Currency) => formattedCurrency.symbol
                    ) as string[])) ||
                [];
            if (!mappedCurrencies.includes(defaultCurrency?.symbol as string)) {
                mappedCurrencies.push(defaultCurrency?.symbol as string);
            }
            return mappedCurrencies.map((formatSymbol: string) => ({
                value: formatSymbol,
                label: fomatCurrencySymbol(formatSymbol),
            }));
        }
        return [];
    };

    const formatBuyoutRate = (value: number) => {
        const numberFormatter = Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return numberFormatter.format(value);
    };

    const formatAllowable = (value: number) => {
        const numberFormatter = Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return numberFormatter.format(value);
    };

    const handleSelectedCreditor = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = (event.target as unknown) as { id: string };
        setCreditorId(id);
    };

    const handleSelectedUnit = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = (event.target as unknown) as { value: string };
        setUnitId(value);
    };

    const handleSelectedCurrency = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = (event.target as unknown) as { value: string };
        setCurrency(value);
    };

    const handleSelectedVat = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = (event.target as unknown) as { id: string };
        setVat(id);
    };

    const post = async (existingId?: string | undefined) => {
        setErrorMessage('');
        if (
            !description ||
            !quantity ||
            !vat ||
            !vatType ||
            !costCodeId ||
            !unitId ||
            !buyOutRate
        ) {
            setErrorMessage('Please capture all the fields');
        } else if (parseFloat(buyOutRate) < 0) {
            setErrorMessage('Buy Out Rate must be more than or equal to 0');
        } else if (parseFloat(quantity) <= 0) {
            setErrorMessage('Quantity must be more than 0');
        } else {
            setCreateLineItemLoading(true);
            const data = {
                description,
                quantity: parseFloat(quantity),
                vat: parseFloat(vat),
                vatType: (vatType as unknown) as schemas['VatType'],
                currency,
                allowable: parseFloat(allowable || ''),
                buyOutRate: parseFloat(buyOutRate),
                costCodeId,
                creditorId,
                unitId,
            };

            if (!existingId) {
                await createRequisitionItem.execute(data);
            } else {
                const editData = { id: existingId, ...data };
                await editRequisitionItem.execute(editData);
            }
            const materialCheck = materials.data?.items.some(
                (i) => i.description === description.trim()
            );
            if (!materialCheck) {
                createMaterial
                    .execute({
                        description: description.trim(),
                        tradeId: requisition.data?.tradeId,
                    })
                    .then(() => materials.reset());
            }

            requisition.reset();

            setCreateLineItemLoading(false);
        }
    };

    /* const toOrderDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/orders/${id}`);
        }
    }; */

    const postCreateOrder = async () => {
        if (!orderOrderType) {
            setOrderErrorMessage('Please select an order type');
        } else if (selectedLineItems.length < 1) {
            setOrderErrorMessage('Please capture all the fields above');
        } else {
            setIsLoading(true);
            const data: OrderDTO = {
                requisitionId: requisition.data?.id,
                description: orderDescription,
                orderType: parseInt(orderOrderType, 10) as schemas['OrderType'],
                requiredAt: orderRequiredAt,
                lineItemIds: selectedLineItems.filter((i) => i.checked).map((i) => i.lineItemId),
            };

            await order.execute(data);
            requisition.reset();
        }
    };

    useEffect(() => {
        if (order.error) {
            setOpenCreateOrderModal(true);
            setOrderErrorMessage(order.error);
            setIsLoading(false);
        }
    }, [order.error]);

    useEffect(() => {
        if (order.success) {
            setOpenCreateOrderModal(false);
            setIsLoading(false);
        }
    }, [order.success]);

    const handleCancel = () => {
        setIsLoading(true);
        requisitionCancel.execute().then(() => {
            requisition.reset();

            if (props.navigate) {
                setIsLoading(false);
                props.navigate(`/requisitions`);
            }
        });
    };

    const postSendRFQ = async () => {
        setSendRFQErrorMessage('');
        if (!selectedCreditorId || selectedLineItems.length <= 0) {
            return setSendRFQErrorMessage('Please capture all the fields above');
        }
        if (email) {
            if (!validateEmail(email)) {
                return setSendRFQErrorMessage('Please provide a valid email address');
            }
        }
        if (!validateEmail(cc)) {
            return setSendRFQErrorMessage('Please provide a valid CC email address');
        }
        setIsLoading(true);

        const data: SendRFQDTO = {
            creditorId: selectedCreditorId === '-1' ? undefined : selectedCreditorId,
            lineItemIds: selectedLineItems.filter((i) => i.checked).map((i) => i.lineItemId),
            email,
            cc,
            deliveryAddressId,
        };
        await sendRFQ.execute(data);
        // I cant get this error to work!
        if (sendRFQ.error) {
            // Display create order error here
        } else {
            handleSendRFQModalClose();
        }
        requisition.reset();

        return setIsLoading(false);
    };

    const postUploadFile = async (fileType: string, files: File[]) => {
        setUploadFileErrorMessage('');
        setIsLoading(true);
        if (files.length <= 0) {
            setUploadFileErrorMessage('You must upload at least 1 file');
            setIsLoading(false);
        } else {
            const blobs = await uploadFiles(fileType, files);
            if (blobs.length !== files.length) {
                setUploadFileErrorMessage('Not all files were uploaded successfully');
                setIsLoading(false);
                return;
            }
            const data: UpsertDocumentDTO = {
                blobs,
            };

            await uploadDocument.execute(data);
            // I cant get this error to work!
            if (uploadDocument.error) {
                // Display create order error here
            } else {
                handleUploadQuotesModalClose();
                handleUploadDocumentsModalClose();
                setIsLoading(false);
            }
            requisition.reset();
        }
    };

    const postAddNote = async () => {
        await addNote.execute({
            note,
            requisitionId: requisition.data?.id || '',
            type: 'BUYER',
        });
        requisition.reset();
        setNote('');
    };

    const postRemoveNote = async (id?: string) => {
        await removeNote.execute(id);
        requisition.reset();
    };

    const handleDeleteLine = async (id: string) => {
        if (!requisition.data?.approvedAt && !requisition.data?.approvedBy) {
            await deleteRequisitionItem.execute({ id }).then(() => {
                requisition.reset();
            });
        }
    };

    const handleEditLine = async (lineItem: EditableRequisitionItem) => {
        clearFields();
        setTableLineItems([
            ...tableLineItems
                .filter((i) => i.id)
                .map((filteredLine) => ({
                    ...filteredLine,
                    editing: filteredLine.id === lineItem.id,
                })),
        ]);
    };

    const handleCancelLine = async () => {
        clearFields();
        if (!requisition.data?.approvedAt && !requisition.data?.approvedBy) {
            setTableLineItems([
                ...tableLineItems.map((filteredLine) => ({ ...filteredLine, editing: false })),
                { editing: true },
                // Add the totals for buyout rate and allowable
                {
                    allowable: calculateAllowableTotal(requisition.data?.lineItems || []),
                    buyOutRate: calculateBuyoutRateTotal(requisition.data?.lineItems || []),
                    currency: requisition.data?.lineItems?.length
                        ? requisition.data?.lineItems[0].currency
                        : '',
                    totalField: true,
                },
            ]);
        } else {
            setTableLineItems([
                ...tableLineItems.map((filteredLine) => ({ ...filteredLine, editing: false })),
            ]);
        }
    };

    const handleDateChange = (date: Date) => {
        setDateRequiredAt(date);
        submitUpdate(date);
    };

    const getLineItemNumber = (row: RequisitionItem) => {
        if (!row?.id) return '';
        const lineItemIndex = requisition?.data?.lineItems?.findIndex((item) => item.id === row.id);
        return !lineItemIndex || lineItemIndex === -1 ? 1 : lineItemIndex + 1;
    };

    const getUserFullName = (userId: string) => {
        const chosenUser =
            userId &&
            users.data?.find((userItem) => userItem.id?.toUpperCase() === userId.toUpperCase());
        if (chosenUser) {
            return `${chosenUser.firstName} ${chosenUser.lastName}`;
        }
        return '';
    };

    useEffect(() => {
        setOrderRequiredAt(format(addDays(dateRequiredAt, 1), 'yyyy-MM-dd'));
    }, [dateRequiredAt]);

    const formatTableColumns = () => {
        const cols: {
            key: string;
            title: string;
            width?: string;
            onClick?: (i: EditableRequisitionItem) => void;
            render?: (i: EditableRequisitionItem) => React.ReactNode;
        }[] = [
            {
                key: '',
                title: 'No',
                render: (i) => <div className="p-1">{getLineItemNumber(i)}</div>,
            },
            {
                key: 'quantity',
                title: 'Quantity',
                render: (i: EditableRequisitionItem) => {
                    if (quantity === undefined && i.editing) {
                        setQuantity(i.quantity?.toString() || '0');
                    }
                    return (
                        <>
                            {i.editing && (
                                <Input
                                    value={quantity}
                                    type="number"
                                    // issue with the event type not matching an element
                                    // eslint-disable-next-line
                                    onClick={(e: any) => e.target.select()}
                                    onChange={(event) => setQuantity(event.target.value)}
                                />
                            )}

                            {!i.editing && <div>{i.quantity?.toString()}</div>}
                        </>
                    );
                },
            },
            {
                key: 'unit',
                title: 'Unit',
                render: (i: EditableRequisitionItem) => {
                    if (unitId === undefined && i.editing) {
                        setUnitId(i.unit?.id);
                        setUnitName(i.unit?.description || '');
                    }
                    return (
                        <>
                            {i.editing && (
                                <Select
                                    placeholder={unitName || 'Search Units'}
                                    // caseSensitive={false}
                                    options={formatUnits()}
                                    // value={unitName}
                                    onChange={(event) => handleSelectedUnit(event)}
                                />
                            )}

                            {!i.editing && <div>{i.unit?.description?.toString()}</div>}
                        </>
                    );
                },
            },
            {
                key: 'description',
                title: 'Description',
                render: (i: EditableRequisitionItem) => {
                    if (description === undefined && i.editing) {
                        setDescription(i.description || '');
                    }
                    return (
                        <>
                            {i.editing && (
                                <TypeAhead
                                    placeholder="Search Descriptions"
                                    hideNoMatchMsg
                                    caseSensitive={false}
                                    options={formatMaterials()}
                                    value={description}
                                    onClick={(event) => {
                                        setDescription(
                                            ((event.target as unknown) as {
                                                id: string;
                                            }).id?.toUpperCase()
                                        );
                                    }}
                                    onChanged={(event) =>
                                        setDescription(
                                            ((event.target as unknown) as {
                                                value: string;
                                            }).value?.toUpperCase()
                                        )
                                    }
                                />
                            )}

                            {!i.editing && <div>{i.description}</div>}
                        </>
                    );
                },
                width: 'w-1/5',
            },
            {
                key: 'allowable',
                title: 'Allowable',
                render: (i: EditableRequisitionItem) => {
                    if (allowable === undefined && i.editing) {
                        setAllowable(i.allowable?.toString() || '0');
                    }
                    return (
                        <>
                            {i.editing &&
                                ((!requisition.data?.approvedAt && !requisition.data?.approvedBy) ||
                                    getPermissions.data?.items.find(
                                        (p) => p.code === 'Requisition_Edit_LineItem_Override'
                                    )?.hasAccess) && (
                                    <Input
                                        value={allowable}
                                        type="number"
                                        // NOTE: removed 2022-03-11 on request
                                        // disabled={user && hasAtMostRole(user.role, 'level1')}
                                        // issue with the event type not matching an element
                                        // eslint-disable-next-line
                                        onClick={(e: any) => e.target.select()}
                                        onChange={(event) => setAllowable(event.target.value)}
                                    />
                                )}

                            {(!i.editing ||
                                requisition.data?.approvedAt ||
                                requisition.data?.approvedBy) &&
                                typeof i.allowable === 'number' && (
                                    <div className="flex justify-between">
                                        <span>{fomatCurrencySymbol(i.currency || '')}</span>
                                        <span>{formatAllowable(i.allowable)}</span>
                                    </div>
                                )}
                        </>
                    );
                },
            },
            {
                key: 'buyOutRate',
                title: 'Buy Out Rate',
                render: (i: EditableRequisitionItem) => {
                    if (buyOutRate === undefined && i.editing) {
                        setBuyOutRate(i.buyOutRate?.toString() || '0');
                    }
                    return (
                        <>
                            {i.editing && (
                                <Input
                                    value={buyOutRate}
                                    type="number"
                                    // issue with the event type not matching an element
                                    // eslint-disable-next-line
                                    onClick={(e: any) => e.target.select()}
                                    onChange={(event) => {
                                        if (parseFloat(event.target.value) >= 0) {
                                            setBuyOutRate(event.target.value);
                                        }
                                    }}
                                />
                            )}

                            {!i.editing && i.buyOutRate && (
                                <div className="flex justify-between">
                                    <span>{fomatCurrencySymbol(i.currency || '')}</span>
                                    <span>{formatBuyoutRate(i.buyOutRate)}</span>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                key: 'costCode',
                title: 'Cost Code',
                render: (i: EditableRequisitionItem) => {
                    if (costCodeId === undefined && i.editing) {
                        setCostCodeId(i.costCode?.id);
                        if (i.costCode?.code && i.costCode?.name) {
                            setCostCodeName(`${i.costCode?.code} - ${i.costCode?.name}`);
                        }
                    }
                    return (
                        <>
                            {i.editing &&
                                ((!requisition.data?.approvedAt && !requisition.data?.approvedBy) ||
                                    getPermissions.data?.items.find(
                                        (p) => p.code === 'Requisition_Edit_LineItem_Override'
                                    )?.hasAccess) && (
                                    <div>
                                        <ReactSelect
                                            options={formatCostCodes()}
                                            placeholder={costCodeName || 'Cost Code'}
                                            onChange={(obj) => {
                                                if (obj) {
                                                    setCostCodeId(obj.value);
                                                }
                                            }}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    height: 20,
                                                    minHeight: 20,
                                                }),
                                                valueContainer: (base) => ({
                                                    ...base,
                                                    height: 20,
                                                    minHeight: 20,
                                                    width: 100,
                                                    alignItems: 'left',
                                                }),
                                                indicatorsContainer: (base) => ({
                                                    ...base,
                                                    height: 20,
                                                    minHeight: 20,
                                                    width: 30,
                                                    alignItems: 'center',
                                                }),
                                                input: (base) => ({
                                                    ...base,
                                                    width: 120,
                                                    marginBottom: 10,
                                                }),
                                            }}
                                        />
                                    </div>
                                )}

                            {(!i.editing ||
                                requisition.data?.approvedAt ||
                                requisition.data?.approvedBy) && (
                                <div>
                                    {i.costCode?.code &&
                                        i.costCode?.name &&
                                        `${i.costCode?.code} - ${i.costCode?.name}`}
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                key: 'vatType',
                title: 'Vat Type',
                render: (i: EditableRequisitionItem) => {
                    const vatTypeId = i.vatType?.toString() || 'NORMAL_VAT';
                    if (vatType === undefined && i.editing) {
                        setVatType(vatTypeId);
                        setVatTypeName(
                            formatVATTypes().find((j) => j.value === vatTypeId)?.label || ''
                        );
                    }

                    return (
                        <>
                            {i.editing && (
                                <Select
                                    disabled={
                                        !getPermissions.data?.items.find(
                                            (p) => p.code === 'Requisition_Edit_LineItem_Enable_VAT'
                                        )?.hasAccess
                                    }
                                    placeholder={vatTypeName || 'Search VAT Type'}
                                    options={formatVATTypes()}
                                    onChange={(event) => {
                                        const { value } = (event.target as unknown) as {
                                            value: string;
                                        };
                                        setVatType(value);
                                        const [firstVAT] = formatVats();
                                        setVat(value !== 'NORMAL_VAT' ? '0' : firstVAT.id);
                                    }}
                                />
                            )}

                            {!i.editing && !i.totalField && (
                                <div>
                                    {formatVATTypes().find((j) => j.value === vatTypeId)?.label}
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                key: 'vat',
                title: 'Vat',
                render: (i: EditableRequisitionItem) => {
                    if (vat === undefined && i.editing) {
                        setVat(
                            i.vat?.toString() ||
                                requisition.data?.project?.businessUnit?.defaultVat?.amount?.toString()
                        );
                    }

                    return (
                        <>
                            {i.editing &&
                                ((!requisition.data?.approvedAt &&
                                    !requisition.data?.approvedBy &&
                                    vat !== '0') ||
                                    getPermissions.data?.items.find(
                                        (p) => p.code === 'Requisition_Edit_LineItem_Override'
                                    )?.hasAccess) && (
                                    <TypeAhead
                                        placeholder="Search Vat"
                                        caseSensitive={false}
                                        value={vat}
                                        options={formatVats()}
                                        onClick={(event) => handleSelectedVat(event)}
                                    />
                                )}

                            {(!i.editing ||
                                ((requisition.data?.approvedAt || requisition.data?.approvedBy) &&
                                    vat !== '0')) && <div>{i.vat?.toString()}</div>}
                        </>
                    );
                },
            },
            {
                key: 'creditor',
                title: 'Creditor Name',
                render: (i: EditableRequisitionItem) => {
                    if (creditorId === undefined && i.editing) {
                        setCreditorId(i.creditor?.id);
                        if (i.creditor?.code && i.creditor?.name) {
                            setCreditorName(`${i.creditor?.code} - ${i.creditor?.name}`);
                        }
                    }
                    return (
                        <>
                            {i.editing && (
                                <TypeAhead
                                    placeholder="Search Creditors"
                                    caseSensitive={false}
                                    options={formatCreditors()}
                                    value={creditorName}
                                    onClick={(event) => handleSelectedCreditor(event)}
                                    onCleared={() => {
                                        setCreditorId(null);
                                        setCreditorName(null);
                                    }}
                                />
                            )}

                            {!i.editing && (
                                <div>
                                    {i.creditor?.code &&
                                        i.creditor?.name &&
                                        `${i.creditor?.code} - ${i.creditor?.name}`}
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                key: 'currency',
                title: 'Currency',
                render: (i: EditableRequisitionItem) => {
                    if (currency === undefined && i.editing) {
                        setCurrency(
                            fomatCurrencySymbol(
                                i.currency ||
                                    requisition.data?.project?.businessUnit?.defaultCurrency
                                        ?.symbol ||
                                    ''
                            )
                        );
                    }
                    return (
                        <>
                            {i.editing &&
                                ((!requisition.data?.approvedAt && !requisition.data?.approvedBy) ||
                                    getPermissions.data?.items.find(
                                        (p) => p.code === 'Requisition_Edit_LineItem_Override'
                                    )?.hasAccess) && (
                                    <Select
                                        placeholder={currency || 'Search Currencies'}
                                        options={formatCurrencies()}
                                        onChange={(event) => handleSelectedCurrency(event)}
                                    />
                                )}

                            {(!i.editing ||
                                requisition.data?.approvedAt ||
                                requisition.data?.approvedBy) &&
                                !i.totalField && (
                                    <div>{fomatCurrencySymbol(i.currency?.toString() || '')}</div>
                                )}
                        </>
                    );
                },
            },
        ];

        if (
            requisition.data?.lineItems?.length === 0 ||
            requisition.data?.lineItems?.some((i) => !i.orderId) ||
            getPermissions.data?.items.find((p) => p.code === 'Requisition_Edit_LineItem_Override')
                ?.hasAccess
        ) {
            if (
                getPermissions.data?.items.find((p) => p.code === 'Requisition_Action_LineItems')
                    ?.hasAccess
            ) {
                cols.push({
                    key: 'actions',
                    title: 'Action',
                    render: (i: EditableRequisitionItem) => (
                        <div className="flex flex-row">
                            {i.editing && i.id && (
                                <div>
                                    <Button onClick={() => post(i.id)}>Save</Button>
                                </div>
                            )}
                            {i.editing && i.id && (
                                <div>
                                    <Button onClick={() => handleCancelLine()}>Cancel</Button>
                                </div>
                            )}
                            {i.editing &&
                                ((!requisition.data?.approvedAt && !requisition.data?.approvedBy) ||
                                    getPermissions.data?.items.find(
                                        (p) => p.code === 'Requisition_Edit_LineItem_Override'
                                    )?.hasAccess) && (
                                    <div>
                                        <Button
                                            onClick={() => post()}
                                            disabled={createLineItemLoading}
                                        >
                                            {createLineItemLoading ? 'Loading' : 'Create'}
                                        </Button>
                                    </div>
                                )}
                            {!i.editing && !i.orderId && !i.totalField && (
                                <div>
                                    <Button onClick={() => handleEditLine(i)}>Edit</Button>
                                </div>
                            )}
                            {!i.editing &&
                                !i.orderId &&
                                !i.totalField &&
                                !requisition.data?.approvedAt &&
                                !requisition.data?.approvedBy && (
                                    <div>
                                        <Button onClick={() => handleDeleteLine(i?.id || '')}>
                                            Delete
                                        </Button>
                                    </div>
                                )}
                        </div>
                    ),
                });
            }
        }

        return cols;
    };

    const handleCheckbox = (checked: boolean, id: string, items: { id?: string }[]) => {
        setSelectedLineItems(
            items.map((i) => ({
                checked:
                    ((i.id || '') === id && checked === true) ||
                    ((i.id || '') !== id &&
                        selectedLineItems.find((j) => j.lineItemId === i.id)?.checked) ||
                    false,
                lineItemId: i.id || '',
            }))
        );
    };

    const getRequisitionTradeDescription = () => {
        const trade = getRequisitionTrade();
        return `${trade?.code} ${trade?.code ? '-' : ''} ${trade?.description}`;
    };

    const renderRequisition = () => {
        if (!requisition.data && requisition?.loading) {
            return <Loader message="Fetching Requisition from server" />;
        }
        if (!requisition.data && requisition?.error) {
            return (
                <Error
                    message={`Failed to fetch Requisition from the server (${requisition.error})`}
                />
            );
        }
        if (requisition.data) {
            const {
                number,
                project,
                createdAt,
                requiredAt,
                createdBy,
                approvedAt,
                approvedBy,
            } = requisition.data;
            const prefix = project?.businessUnit?.prefixRequisition || '';
            return (
                <div className="p-4 space-y-4">
                    <div className="bg-gray-400 border rounded-md">
                        <div className="w-full flex items-center">
                            <div className="w-1/3">
                                <div className="text-l text-xl p-3 font-bold">
                                    Requisition Information
                                </div>
                            </div>
                            <div className="w-2/3 flex">
                                <div className="w-1/2 pl-3">
                                    <div className="flex">
                                        <div className="flex items-center pr-4 font-bold">
                                            Quotes
                                        </div>
                                        <div>
                                            {getPermissions.data?.items.find(
                                                (p) => p.code === 'Requisition_Upload_Quotes'
                                            )?.hasAccess &&
                                                requisition.data && (
                                                    <div>
                                                        <Button
                                                            onClick={handleUploadQuotesModalOpen}
                                                        >
                                                            Upload Quotes
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="flex">
                                        <div className="flex items-center pr-4 font-bold">
                                            Documents
                                        </div>
                                        <div>
                                            {getPermissions.data?.items.find(
                                                (p) => p.code === 'Requisition_Upload_Documents'
                                            )?.hasAccess &&
                                                requisition.data && (
                                                    <div>
                                                        <Button
                                                            onClick={handleUploadDocumentsModalOpen}
                                                        >
                                                            Upload Documents
                                                        </Button>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="p-4">
                            <div className="flex">
                                <div className="flex flex-col w-1/3 text-xs">
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Req No.</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={`${
                                                    project && project.businessUnit && prefix
                                                }${number.toString().padStart(6, '0')}`}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Created At</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={format(
                                                    new Date(createdAt || ''),
                                                    'yyyy-MM-dd HH:mm:ss'
                                                )}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Created By</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={getUserFullName(createdBy || '')}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Trade</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={getRequisitionTradeDescription()}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Job Code</div>
                                        <div className="w-1/2">
                                            <Input value={project?.jobCode} type="text" disabled />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Status</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={getStatus(requisition.data)}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="text-xs text-black font-bold w-1/2">
                                            Required At
                                        </div>
                                        <div className="w-1/2">
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                selected={new Date(requiredAt)}
                                                onChange={(date: Date) => handleDateChange(date)}
                                                className="border rounded-sm w-full pl-0.5"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {project?.addresses && (
                                            <div className="mb-1 flex justify-between">
                                                <div className="font-bold">
                                                    {project?.addresses.length > 1
                                                        ? 'Delivery Addresses'
                                                        : 'Delivery Address'}
                                                </div>
                                                <div className="w-1/2">
                                                    <Select
                                                        placeholder={`${project?.addresses[0]?.address1}, ${project?.addresses[0]?.address2}, ${project?.addresses[0]?.address3}`}
                                                        options={formatDeliveryAddressSelections()}
                                                        dark
                                                        width="w-full"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Approved At</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={
                                                    approvedAt
                                                        ? format(
                                                              new Date(approvedAt || ''),
                                                              'yyyy-MM-dd HH:mm:ss'
                                                          )
                                                        : ''
                                                }
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-1 flex justify-between">
                                        <div className="font-bold w-1/2">Approved By</div>
                                        <div className="w-1/2">
                                            <Input
                                                value={getUserFullName(approvedBy || '')}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* File section */}
                                <div className="w-2/3 flex pl-2 gap-2">
                                    {/* quotes block */}
                                    <div className="w-1/2 flex-col h-220 overflow-hidden overflow-y-auto">
                                        {requisition.data?.files &&
                                            requisition.data?.files.length > 0 &&
                                            requisition.data && (
                                                <Table<{
                                                    id?: string | undefined;
                                                    name?: string | undefined;
                                                    requisitionId?: string | null | undefined;
                                                    deliveryId?: string | null | undefined;
                                                    returnId?: string | null | undefined;
                                                    blobId?: string | null | undefined;
                                                    uploadedAt?: string | null | undefined;
                                                }>
                                                    loading={
                                                        !requisition.data &&
                                                        requisition.loading &&
                                                        'Fetching Files from server'
                                                    }
                                                    error={
                                                        !requisition.data &&
                                                        requisition.error &&
                                                        `Failed to fetch File list from the server (${requisition.error})`
                                                    }
                                                    data={
                                                        requisition.data?.files?.filter(
                                                            (file) =>
                                                                !file.isDeleted &&
                                                                file.type === 'QUOTE'
                                                        ) || []
                                                    }
                                                    columns={[
                                                        {
                                                            key: 'name',
                                                            title: 'File Name',
                                                            onClick: (file) =>
                                                                handleAzureDownload(file),
                                                            render: (file) => (
                                                                <div className="">{file.name}</div>
                                                            ),
                                                            width: '',
                                                        },
                                                        {
                                                            key: 'uploadedAt',
                                                            title: 'Created At',
                                                            render: (file) => (
                                                                <div className="whitespace-nowrap">
                                                                    {format(
                                                                        new Date(
                                                                            file.uploadedAt || ''
                                                                        ),
                                                                        'yyyy-MM-dd HH:mm:ss'
                                                                    )}
                                                                </div>
                                                            ),
                                                            onClick: (file) =>
                                                                handleAzureDownload(file),
                                                        },
                                                        {
                                                            key: 'delete',
                                                            title: '',
                                                            render: (file) => (
                                                                <TrashCan
                                                                    className="cursor-pointer pl-2"
                                                                    id={file.id}
                                                                    onClick={() =>
                                                                        deleteFile(file.id || '')
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            )}
                                        {requisition.data?.files &&
                                            requisition.data?.files.filter(
                                                (file) => file.type === 'QUOTE' && !file.isDeleted
                                            ).length === 0 && (
                                                <div className="pl-1 bg-white text-xs border">
                                                    No Quotes uploaded
                                                </div>
                                            )}
                                    </div>
                                    {/* Docs block */}
                                    <div className="w-1/2 flex-col h-220 overflow-hidden overflow-y-auto">
                                        {requisition.data?.files &&
                                            requisition.data?.files.length > 0 &&
                                            requisition.data && (
                                                <Table<{
                                                    id?: string | undefined;
                                                    name?: string | undefined;
                                                    requisitionId?: string | null | undefined;
                                                    deliveryId?: string | null | undefined;
                                                    returnId?: string | null | undefined;
                                                    blobId?: string | null | undefined;
                                                    uploadedAt?: string | null | undefined;
                                                }>
                                                    loading={
                                                        !requisition.data &&
                                                        requisition.loading &&
                                                        'Fetching Files from server'
                                                    }
                                                    error={
                                                        !requisition.data &&
                                                        requisition.error &&
                                                        `Failed to fetch File list from the server (${requisition.error})`
                                                    }
                                                    data={
                                                        requisition.data?.files?.filter(
                                                            (file) =>
                                                                !file.isDeleted &&
                                                                file.type === 'DOCUMENT'
                                                        ) || []
                                                    }
                                                    columns={[
                                                        {
                                                            key: 'name',
                                                            title: 'File Name',
                                                            onClick: (file) =>
                                                                handleAzureDownload(file),
                                                        },
                                                        {
                                                            key: 'uploadedAt',
                                                            title: 'Created At',
                                                            render: (file) =>
                                                                format(
                                                                    new Date(file.uploadedAt || ''),
                                                                    'yyyy-MM-dd HH:mm:ss'
                                                                ),
                                                            onClick: (file) =>
                                                                handleAzureDownload(file),
                                                        },
                                                        {
                                                            key: 'delete',
                                                            title: '',
                                                            render: (file) => (
                                                                <TrashCan
                                                                    className="cursor-pointer pl-2"
                                                                    id={file.id}
                                                                    onClick={() =>
                                                                        deleteFile(file.id || '')
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            )}
                                        {requisition.data?.files &&
                                            requisition.data?.files.filter(
                                                (file) =>
                                                    file.type === 'DOCUMENT' && !file.isDeleted
                                            ).length === 0 && (
                                                <div className="pl-1 bg-white text-xs border">
                                                    No Documents uploaded
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="space-y-3 mt-5">
                                <Table data={tableLineItems} columns={formatTableColumns()} />
                                <div className="flex mb-5 text-red-500">{errorMessage}</div>
                            </div>
                            <div className="mb-5 space-y-4">
                                {user &&
                                    getPermissions.data?.items.find(
                                        (p) => p.code === 'Requisition_Remove_Comment'
                                    )?.hasAccess &&
                                    requisition.data.notes &&
                                    requisition.data.notes.map((noteItem) => (
                                        <div className="bg-red-100 p-3 flex justify-between">
                                            <div className="self-center">{noteItem.note}</div>
                                            <Button onClick={() => postRemoveNote(noteItem.id)}>
                                                Remove
                                            </Button>
                                        </div>
                                    ))}
                            </div>
                            <div className="mb-5 space-y-4 py-4">
                                <>
                                    <div className="flex justify-between">
                                        <TextArea
                                            label="Notes to Buyer"
                                            value={note}
                                            height="h-16"
                                            onChange={(e) => setNote(e.target.value)}
                                        />
                                    </div>
                                    <div />
                                    <div className="flex mb-5 mt-5 text-red-500">
                                        {sendRFQErrorMessage}
                                    </div>
                                    <div className="flex flex-row-reverse">
                                        <div className="pl-2" />
                                        <div>
                                            <Button
                                                onClick={postAddNote}
                                                disabled={isLoading || note.length <= 0}
                                            >
                                                {!isLoading ? 'Submit' : <div>Loading</div>}
                                            </Button>
                                        </div>
                                    </div>
                                </>
                                <TextArea
                                    label="RFQ Notes"
                                    value={commentsField}
                                    onChange={(e) => handleCommentsChange(e.target.value)}
                                    disabled={!commentsEditable}
                                    height="h-16"
                                />
                                {getPermissions.data?.items.find(
                                    (p) => p.code === 'Requisition_Edit_Comment'
                                )?.hasAccess && (
                                    <div className="flex justify-between">
                                        <Button
                                            disabled={commentsEditable}
                                            onClick={() => setCommentsEditable(true)}
                                        >
                                            Edit comments
                                        </Button>

                                        <Button
                                            disabled={!commentsEditable}
                                            onClick={() => {
                                                submitUpdate();
                                                setCommentsEditable(false);
                                            }}
                                        >
                                            Save comments
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <div>
                            {getPermissions.data?.items.find((p) => p.code === 'Requisition_Cancel')
                                ?.hasAccess && (
                                <div>
                                    <Button onClick={() => setOpenCancelModal(true)}>
                                        Cancel Requisition
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="flex space-x-4">
                            {requisition.data?.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                getPermissions.data?.items.find(
                                    (p) => p.code === 'Requisition_Download'
                                )?.hasAccess && (
                                    <div>
                                        <Button onClick={handleDownloadRFQModalOpen}>
                                            Download RFQ
                                        </Button>
                                    </div>
                                )}
                            {requisition.data?.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                getPermissions.data?.items.find(
                                    (p) => p.code === 'Requisition_Send'
                                )?.hasAccess &&
                                requisition.data && (
                                    <div>
                                        <Button onClick={handleSendRFQModalOpen}>Send RFQ</Button>
                                    </div>
                                )}

                            {((!requisition.data?.approvedAt && !requisition.data?.approvedBy) ||
                                getPermissions.data?.items.find(
                                    (p) => p.code === 'Requisition_Import'
                                )?.hasAccess) && (
                                <div>
                                    <Button onClick={() => setOpenImportLineItemsModal(true)}>
                                        Import Line Items
                                    </Button>
                                </div>
                            )}

                            {getPermissions.data?.items.find(
                                (p) => p.code === 'Requisition_Submit_QS'
                            )?.hasAccess &&
                                requisition.data?.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                !requisition.data.reviewRequestedAt &&
                                !requisition.data.reviewRequestedBy && (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                requisitionSubmitReview.execute().then(() => {
                                                    requisition.reset();

                                                    if (navigate) {
                                                        navigate(`/requisitions`);
                                                    }
                                                });
                                            }}
                                        >
                                            Submit to QS
                                        </Button>
                                    </div>
                                )}

                            {getPermissions.data?.items.find(
                                (p) => p.code === 'Requisition_Send_To_Clerk'
                            )?.hasAccess &&
                                requisition.data?.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                requisition.data.approvedAt === null &&
                                requisition.data.approvedBy === null &&
                                requisition.data.reviewRequestedAt &&
                                requisition.data.reviewRequestedBy && (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                requisitionRetractReview.execute().then(() => {
                                                    requisition.reset();

                                                    if (navigate) {
                                                        navigate(`/requisitions`);
                                                    }
                                                });
                                            }}
                                        >
                                            Send back to Clerk
                                        </Button>
                                    </div>
                                )}

                            {getPermissions.data?.items.find(
                                (p) => p.code === 'Requisition_Send_To_Buyer'
                            )?.hasAccess &&
                                requisition.data?.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                (requisition.data.approvedAt !== null ||
                                    requisition.data.approvedBy !== null) &&
                                requisition.data.reviewRequestedAt &&
                                requisition.data.reviewRequestedBy && (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                requisitionRetractReview.execute().then(() => {
                                                    requisition.reset();

                                                    if (navigate) {
                                                        navigate(`/requisitions`);
                                                    }
                                                });
                                            }}
                                        >
                                            Send back to Buyer
                                        </Button>
                                    </div>
                                )}

                            {getPermissions.data?.items.find(
                                (p) => p.code === 'Requisition_Approve'
                            )?.hasAccess &&
                                requisition.data?.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                requisition.data.approvedAt === null &&
                                requisition.data.approvedBy === null && (
                                    <div>
                                        <Button onClick={() => setOpenApproveRFQModal(true)}>
                                            Approve
                                        </Button>
                                    </div>
                                )}

                            {getPermissions.data?.items.find(
                                (p) => p.code === 'Requisition_Create_Order'
                            )?.hasAccess &&
                                (requisition.data.approvedAt !== null ||
                                    requisition.data.approvedBy !== null) &&
                                requisition.data.lineItems &&
                                requisition.data?.lineItems.length > 0 &&
                                requisition.data.lineItems.some(
                                    (i: RequisitionItem) => i.orderId === null
                                ) && (
                                    <div>
                                        <Button
                                            onClick={() => {
                                                if (
                                                    requisition.data?.lineItems
                                                        ?.filter((i) => !i.orderId)
                                                        .every((i) => !i.creditor)
                                                ) {
                                                    setErrorMessage(
                                                        "Can't create orders if no Creditors have been set"
                                                    );
                                                } else {
                                                    handleCreateOrderModalOpen();
                                                }
                                            }}
                                        >
                                            Create Order
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    if (requisition.data && getPermissions.data) {
        return (
            <div>
                <Modal
                    title="Create Order"
                    subtitle="Create an order from existing materials"
                    onClose={handleCreateOrderModalClose}
                    isOpen={openCreateOrderModal}
                >
                    Creditor
                    <div>
                        <select onChange={(e) => handleSelectOrderCreditor(e.target.value)}>
                            {filteredCreditors.map((obj, key) => (
                                <option key={key} value={obj.id}>
                                    {obj.name}
                                </option>
                            ))}{' '}
                        </select>
                    </div>
                    <div className="flex justify-between mt-5">
                        <Table
                            data={
                                requisition.data.lineItems?.filter(
                                    (el: RequisitionItem) =>
                                        el.creditor?.id === selectedCreditorId && !el.orderId
                                ) || undefined
                            }
                            columns={[
                                {
                                    key: 'description',
                                    title: 'Description',
                                },
                                {
                                    key: 'quantity',
                                    title: 'Quantity',
                                },
                                {
                                    key: 'select',
                                    title: 'Select',
                                    renderHeader: (lines) => (
                                        <div className="flex space-x-3 justify-between">
                                            <div className="self-center">Select</div>
                                            <div className="self-center">
                                                <input
                                                    onChange={(e) =>
                                                        setSelectedLineItems(
                                                            lines.map((line) => ({
                                                                lineItemId: line.id || '',
                                                                checked: e.target.checked,
                                                            }))
                                                        )
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        selectedLineItems.length > 0 &&
                                                        selectedLineItems.every(
                                                            (i) => i.checked === true
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ),
                                    render: (lines) => (
                                        <div className="flex justify-end">
                                            <input
                                                onChange={(e) =>
                                                    handleCheckbox(
                                                        e.target.checked,
                                                        lines.id || '',
                                                        requisition.data?.lineItems?.filter(
                                                            (el: RequisitionItem) =>
                                                                el.creditor?.id ===
                                                                    selectedCreditorId &&
                                                                !el.orderId
                                                        ) || []
                                                    )
                                                }
                                                type="checkbox"
                                                checked={filterCheckedArray(lines?.id || '')}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div className="mt-5">
                        <Input
                            label="Description"
                            value={orderDescription}
                            type="text"
                            onChange={(event) => setOrderDescription(event.target.value)}
                        />
                    </div>
                    <div className="mt-5">
                        <Select
                            placeholder="Search Order Type"
                            // caseSensitive={false}
                            // value={formatOrderTypes().find((o) => o.value === orderOrderType)?.label}
                            label="Type"
                            options={formatOrderTypes()}
                            onChange={(event) => {
                                const { value } = (event.target as unknown) as { value: string };
                                setOrderOrderType(value);
                            }}
                        />
                    </div>
                    <div className="flex flex-col mt-5">
                        <div>Required At</div>
                        <div>
                            <DatePicker
                                dateFormat="yyyy-MM-dd"
                                selected={dateRequiredAt}
                                disabled
                                onChange={(date: Date) => handleDateChange(date)}
                            />
                        </div>
                    </div>
                    <div className="flex mt-5 mb-5 text-red-500">{orderErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2" />
                        <div>
                            <Button
                                onClick={postCreateOrder}
                                disabled={
                                    isLoading ||
                                    selectedLineItems.length <= 0 ||
                                    selectedLineItems.every((i) => !i.checked)
                                }
                            >
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Cancel Requisition"
                    onClose={() => setOpenCancelModal(false)}
                    isOpen={openCancelModal}
                >
                    <div className="w-72">
                        <div className="flex justify-between mt-5">
                            Are you sure you want to cancel this requisition?
                        </div>
                        <div />
                        <div className="flex flex-row-reverse">
                            <div className="ml-3">
                                <Button onClick={handleCancel} disabled={isLoading}>
                                    {isLoading ? 'Loading' : 'Yes'}
                                </Button>
                            </div>
                            <div>
                                <Button secondary onClick={() => setOpenCancelModal(false)}>
                                    No
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Download RFQ"
                    onClose={handleDownloadRFQModalClose}
                    isOpen={openDownloadRFQModal}
                >
                    <div className="mb-5">
                        <Select
                            label="Delivery Address"
                            placeholder="Address 1"
                            options={formatDeliveryAddresses()}
                            onChange={(event) => setDeliveryAddressId(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <TextArea label="" value={getDeliveryAddressDetails()} disabled />
                    </div>
                    <div className="flex flex-col text-center justify-center border border-gray-200">
                        <h3 className="font-semibold">Creditor</h3>
                        <div className="border-t border-gray-200 p-1">
                            <select onChange={(e) => handleSelectOrderCreditor(e.target.value)}>
                                {filteredCreditors.map((obj, key) => (
                                    <option key={key} value={obj.id}>
                                        {obj.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-between mt-5">
                        <Table
                            data={
                                requisition.data.lineItems?.filter(
                                    (el: RequisitionItem) =>
                                        selectedCreditorId === '-1' ||
                                        el.creditor?.id === selectedCreditorId
                                ) || undefined
                            }
                            columns={[
                                {
                                    key: 'description',
                                    title: 'Description',
                                },
                                {
                                    key: 'quantity',
                                    title: 'Quantity',
                                },
                                {
                                    key: 'select',
                                    title: 'Select',
                                    renderHeader: (lines) => (
                                        <div className="flex space-x-3 justify-between">
                                            <div className="self-center">Select</div>
                                            <div className="self-center">
                                                <input
                                                    onChange={(e) =>
                                                        setSelectedLineItems(
                                                            lines.map((line) => ({
                                                                lineItemId: line.id || '',
                                                                checked: e.target.checked,
                                                            }))
                                                        )
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        selectedLineItems.length > 0 &&
                                                        selectedLineItems.every(
                                                            (i) => i.checked === true
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ),
                                    render: (lines) => (
                                        <div className="flex justify-end">
                                            <input
                                                onChange={(e) =>
                                                    handleCheckbox(
                                                        e.target.checked,
                                                        lines.id || '',
                                                        requisition.data?.lineItems?.filter(
                                                            (el: RequisitionItem) =>
                                                                selectedCreditorId === '-1' ||
                                                                el.creditor?.id ===
                                                                    selectedCreditorId
                                                        ) || []
                                                    )
                                                }
                                                type="checkbox"
                                                checked={filterCheckedArray(lines?.id || '')}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div />
                    <div className="flex mb-5 mt-5 text-red-500">{sendRFQErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2" />
                        <div>
                            <Button
                                onClick={handlePDFDownload}
                                disabled={
                                    isLoading ||
                                    selectedLineItems.length <= 0 ||
                                    selectedLineItems.every((i) => !i.checked)
                                }
                            >
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Send RFQ"
                    subtitle="Email RFQ to email address"
                    onClose={handleSendRFQModalClose}
                    isOpen={openSendRFQModal}
                >
                    <div className="mb-5">
                        <Select
                            label="Delivery Address"
                            placeholder="Address 1"
                            options={formatDeliveryAddresses()}
                            onChange={(event) => setDeliveryAddressId(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <TextArea label="" value={getDeliveryAddressDetails()} disabled />
                    </div>
                    <div className="flex flex-col text-center justify-center border border-gray-200">
                        <h3 className="font-semibold">Creditor</h3>
                        <div className="border-t border-gray-200 p-1">
                            <select onChange={(e) => handleSelectOrderCreditor(e.target.value)}>
                                {filteredCreditors.map((obj, key) => (
                                    <option key={key} value={obj.id}>
                                        {obj.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex justify-between mt-5">
                        <Table
                            data={
                                requisition.data.lineItems?.filter(
                                    (el: RequisitionItem) =>
                                        selectedCreditorId === '-1' ||
                                        el.creditor?.id === selectedCreditorId
                                ) || undefined
                            }
                            columns={[
                                {
                                    key: 'description',
                                    title: 'Description',
                                },
                                {
                                    key: 'quantity',
                                    title: 'Quantity',
                                },
                                {
                                    key: 'select',
                                    title: 'Select',
                                    renderHeader: (lines) => (
                                        <div className="flex space-x-3 justify-between">
                                            <div className="self-center">Select</div>
                                            <div className="self-center">
                                                <input
                                                    onChange={(e) =>
                                                        setSelectedLineItems(
                                                            lines.map((line) => ({
                                                                lineItemId: line.id || '',
                                                                checked: e.target.checked,
                                                            }))
                                                        )
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        selectedLineItems.length > 0 &&
                                                        selectedLineItems.every(
                                                            (i) => i.checked === true
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ),
                                    render: (lines) => (
                                        <div className="flex justify-end">
                                            <input
                                                onChange={(e) =>
                                                    handleCheckbox(
                                                        e.target.checked,
                                                        lines.id || '',
                                                        requisition.data?.lineItems?.filter(
                                                            (el: RequisitionItem) =>
                                                                selectedCreditorId === '-1' ||
                                                                el.creditor?.id ===
                                                                    selectedCreditorId
                                                        ) || []
                                                    )
                                                }
                                                type="checkbox"
                                                checked={filterCheckedArray(lines?.id || '')}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div className="mb-5 mt-5">
                        <Input
                            label="Email Address"
                            value={email}
                            placeholder="Provide Email Address"
                            onChange={(event) => {
                                setEmail(
                                    ((event.target as unknown) as {
                                        value: string;
                                    }).value
                                );
                            }}
                        />
                    </div>
                    <div className="mb-5 mt-5">
                        <TypeAhead
                            label="Email CC Address"
                            value={cc}
                            options={formatEmails()}
                            hideNoMatchMsg
                            placeholder="Provide Email CC Address"
                            onClick={(event) =>
                                setCC(
                                    ((event.target as unknown) as {
                                        id: string;
                                    }).id
                                )
                            }
                            onChanged={(event) =>
                                setCC(
                                    ((event.target as unknown) as {
                                        value: string;
                                    }).value
                                )
                            }
                        />
                    </div>
                    <div />
                    <div className="flex mb-5 mt-5 text-red-500">{sendRFQErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2" />
                        <div>
                            <Button
                                onClick={postSendRFQ}
                                disabled={
                                    isLoading ||
                                    selectedLineItems.length <= 0 ||
                                    selectedLineItems.every((i) => !i.checked)
                                }
                            >
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Approve RFQ"
                    onClose={() => setOpenApproveRFQModal(false)}
                    isOpen={openApproveRFQModal}
                >
                    <div className="mb-5 mt-5">
                        <Select
                            label="Allocate Buyer (Optional)"
                            options={formatAllocations()}
                            placeholder={allocation || 'Provide a buyer'}
                            onChange={(event) => {
                                const { value } = (event.target as unknown) as { value: string };
                                setAllocation(value);
                            }}
                        />
                    </div>
                    <div />
                    <div className="flex mb-5 mt-5 text-red-500">{sendRFQErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2" />
                        <div>
                            <Button
                                onClick={() => {
                                    setIsLoading(true);
                                    requisitionApprove.execute(allocation).then(() => {
                                        requisition.reset();
                                        setIsLoading(false);
                                        setOpenApproveRFQModal(false);
                                    });
                                }}
                                disabled={isLoading}
                            >
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Import Line Items"
                    // subtitle={`${order.data.number.toString().padStart(6, '0')}`}
                    onClose={() => setOpenImportLineItemsModal(false)}
                    isOpen={openImportLineItemsModal}
                    onAction={importCsv}
                    actionText={isLoading ? 'Loading...' : ''}
                >
                    <div className="flex justify-between">
                        <div className="ml-5">
                            <div className="mb-5">
                                <Button disabled={isLoading} onClick={handleCSVTemplateDownload}>
                                    Download CSV Template
                                </Button>
                            </div>
                            <div className="mb-5">
                                <FileUpload
                                    updateFilesCb={(files) => {
                                        setImportFile(files[0]);
                                    }}
                                    multiple={false}
                                    // accept=".csv .xlsx .xls"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex mb-5 text-red-500">{importCsvErrorMessage}</div>
                </Modal>
                <Modal
                    title="Upload Quotes"
                    // subtitle={`${order.data.number.toString().padStart(6, '0')}`}
                    onClose={handleUploadQuotesModalClose}
                    isOpen={openUploadQuotesModal}
                    onAction={() => postUploadFile(FILE_TYPES.QUOTE, quoteFiles)}
                    actionText={isLoading ? 'Loading...' : ''}
                >
                    <div className="flex justify-between">
                        <div className="ml-5">
                            <div className="mb-5">
                                <FileUpload
                                    updateFilesCb={updateUploadedQuotes}
                                    // accept=".jpg,.png,.jpeg"
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex mb-5 text-red-500">{uploadFileErrorMessage}</div>
                </Modal>
                <Modal
                    title="Upload Documents"
                    onClose={handleUploadDocumentsModalClose}
                    isOpen={openUploadDocumentsModal}
                    onAction={() => postUploadFile(FILE_TYPES.DOCUMENT, documentFiles)}
                    actionText={isLoading ? 'Loading...' : ''}
                >
                    <div className="flex justify-between">
                        <div className="ml-5">
                            <div className="mb-5">
                                <FileUpload
                                    updateFilesCb={updateUploadedDocuments}
                                    // accept=".jpg,.png,.jpeg"
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex mb-5 text-red-500">{uploadFileErrorMessage}</div>
                </Modal>
                <Modal
                    title="Download Quotes"
                    onClose={handleViewQuotesModalClose}
                    isOpen={openViewQuotesModal}
                >
                    <div className="flex justify-between">
                        <Table<{
                            id?: string | undefined;
                            name?: string | undefined;
                            requisitionId?: string | null | undefined;
                            deliveryId?: string | null | undefined;
                            returnId?: string | null | undefined;
                            blobId?: string | null | undefined;
                            uploadedAt?: string | null | undefined;
                        }>
                            loading={
                                !requisition.data &&
                                requisition.loading &&
                                'Fetching Files from server'
                            }
                            error={
                                !requisition.data &&
                                requisition.error &&
                                `Failed to fetch File list from the server (${requisition.error})`
                            }
                            data={requisition.data?.files?.filter((file) => !file.isDeleted) || []}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'File Name',
                                    onClick: (file) => handleAzureDownload(file),
                                },
                                {
                                    key: 'uploadedAt',
                                    title: 'Created At',
                                    render: (file) =>
                                        format(new Date(file.uploadedAt || ''), 'yyyy-MM-dd'),
                                    onClick: (file) => handleAzureDownload(file),
                                },
                                {
                                    key: 'delete',
                                    title: '',
                                    render: (file) => (
                                        <TrashCan
                                            className="cursor-pointer pl-2"
                                            id={file.id}
                                            onClick={() => deleteFile(file.id || '')}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div />
                </Modal>
                <div>{renderRequisition()}</div>
            </div>
        );
    }
    return <> {!getPermissions.data && <Loader message="Loading requisition..." />}</>;
}
