import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type Company = schemas['Company'];
export type UpsertCompanyDTO = schemas['UpsertCompanyDTO'];

export function useGetCompanies(skip?: number, take?: number) {
    return domain.useGet<ObjectList<Company>>('/companies', {
        requestConfig: {
            queryParams: {
                skip,
                take,
            },
        },
    });
}

export function useGetCompany(id: string) {
    return domain.useGet<Company>(`/companies/${id}`);
}

export function useCreateCompany() {
    return domain.usePost<UpsertCompanyDTO, null>('/companies');
}

export function useEditCompany() {
    return domain.usePut<UpsertCompanyDTO & { id: string }, null>(`/companies/:companyId`, {
        transformRequest: (request: UpsertCompanyDTO & { id: string }) => ({
            urlParams: { companyId: request.id },
            data: request,
        }),
    });
}
