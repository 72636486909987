//  import { Link } from '@reach/router';
import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useUser } from '../../queries/Accounts';
import { useLogout } from '../../d-man';

export default function ProfileDropdown() {
    const [isOpen, setIsOpen] = useState(false);

    const user = useUser();
    const logout = useLogout();

    const profileName = () => {
        const parts = user?.name.split('.');
        if (parts && parts.length >= 2) {
            return `${parts[0][0].toUpperCase()}${parts[1][0].toUpperCase()}`;
        }

        if (parts && parts.length >= 1) {
            return parts[0][0].toUpperCase();
        }
        return 'U';
    };

    return (
        <>
            <div className="flex flex-row">
                <button
                    type="button"
                    className="rounded-full border border-gray-900 w-10 h-10 flex justify-center items-center bg-orange-500"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="text-white cursor-pointer">{profileName()}</div>
                </button>
            </div>
            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {(ref) => (
                    <div
                        ref={ref}
                        className="origin-top-right absolute right-0 mt-12 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                    >
                        <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            {/*  <div
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                role="menuitem"
                            >
                                <Link to="/profile">Profile</Link>
                            </div>  */}
                            <button
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                role="menuitem"
                                onClick={() => logout()}
                                type="button"
                            >
                                Log Out
                            </button>
                        </div>
                    </div>
                )}
            </Transition>
        </>
    );
}
