import { BlobServiceClient } from '@azure/storage-blob';
import { useParams, RouteComponentProps } from '@reach/router';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as uuid from 'uuid';
import Button from '../../components/Button';
import FileUpload from '../../components/FileUpload';
import Input from '../../components/Input';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import Table, { TableData, TableHeader, TableRow } from '../../components/Table';
import { Tab, TabsContainer } from '../../components/Tabs';
import TextArea from '../../components/TextArea';
import { domain } from '../../d-man';
import {
    CallOff,
    CallOffItem,
    useCreateCallOffDelivery,
    useGetCallOff,
    useSendCallOff,
} from '../../queries/Calloff';
import { Delivery, DeliveryDTO, DeliveryItem } from '../../queries/Delivery';
import { useGetPermissions } from '../../queries/Permissions';
import { ProjectAddress } from '../../queries/Projects';
import { RequisitionItem } from '../../queries/Requisitions';
import { handleWebDownload, validateEmail } from '../../utils/Utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CallOffDetail(props: RouteComponentProps) {
    // CONSTANTS - TODO: Move!
    const DECIMAL_PLACES = 3;

    const params = useParams();
    const getId = () => params.id;

    const [email, setEmail] = useState('');
    const [openSendCallOffModal, setOpenSendCallOffModal] = useState(false);
    const [openDownloadCallOffModal, setOpenDownloadCallOffModal] = useState(false);
    const [deliveryErrorMessage, setDeliveryErrorMessage] = useState('');
    const [sendCallOffErrorMessage, setSendCallOffErrorMessage] = useState('');
    const [openCreateDeliveryModal, setOpenCreateDeliveryModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [deliveryAddressId, setDeliveryAddressId] = useState('');

    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [deliveryNote, setDeliveryNote] = useState('');
    const [deliveryFiles, setDeliveryFiles] = useState([] as File[]);
    const [deliveryItems, setDeliveryItems] = useState([] as DeliveryItem[]);
    const [returnNote, setReturnNote] = useState(undefined as string | undefined);
    const [returnFiles, setReturnFiles] = useState([] as File[]);

    const callOffDeliveryCreate = useCreateCallOffDelivery(getId());

    const callOff = useGetCallOff(getId());
    const sendCallOff = useSendCallOff(getId());

    const getPermissions = useGetPermissions('ORDER', callOff.data?.orderId);

    useEffect(() => {
        if (callOff.data?.order) {
            const { lineItems } = callOff.data?.order;
            if (lineItems) {
                if (deliveryItems.length === 0) {
                    setDeliveryItems(
                        lineItems.map(
                            (i: RequisitionItem) =>
                                ({
                                    lineItemId: i.id,
                                    quantity: 0,
                                    returnedQuantity: 0,
                                } as DeliveryItem)
                        )
                    );
                }
            }
        }
    }, [callOff.data, deliveryItems]);

    useEffect(() => {
        if (
            callOff.data?.order?.requisition?.project?.addresses &&
            callOff.data?.order?.requisition?.project?.addresses?.length
        ) {
            setDeliveryAddressId(callOff.data?.order?.requisition?.project?.addresses[0].id || '');
        }
    }, [callOff.data]);

    const [tabs] = useState([
        { label: 'Materials', id: 'Materials' },
        { label: 'Deliveries', id: 'Deliveries' },
    ] as Tab[]);
    const [selectedTab, setSelectedTab] = useState('Materials');

    const toDeliveryDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/deliveries/${id}`);
        }
    };

    const toOrderDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/orders/${id}`);
        }
    };

    const handleCreateDeliveryModalOpen = () => {
        setOpenCreateDeliveryModal(true);
    };

    const handleCreateDeliveryModalClose = () => {
        setOpenCreateDeliveryModal(false);
    };

    const round = (value: number, decimals: number) => {
        return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);
    };

    const uploadFiles = async (files: File[]) => {
        const blobs: { id: string; name: string }[] = [];
        if (files) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            const id = uuid.v4();
            // eslint-disable-next-line no-restricted-syntax
            for (const file of files) {
                const blockBlobClient = containerClient.getBlockBlobClient(id);
                // eslint-disable-next-line no-await-in-loop
                const uploadBlobResponse = await blockBlobClient.uploadData(file);
                if (uploadBlobResponse.requestId) {
                    blobs.push({
                        id,
                        name: file.name,
                    });
                }
            }
        }
        return blobs;
    };

    const updateUploadedFiles = (files: File[]) => {
        setDeliveryFiles(files);
    };

    const updateUploadedFile = (files: File[]) => {
        setReturnFiles(files);
    };

    const handleDownload = async (endpoint: string) => {
        const [, data, response] = await domain
            .get(endpoint, {
                requestConfig: {
                    responseType: 'blob', // important
                },
            })
            .execute();
        if (!data) return;
        handleWebDownload(data, response);
        callOff.reset();
    };

    const formatDeliveryAddresses = () => {
        if (callOff.data && callOff.data.order?.requisition?.project?.addresses) {
            const filteredAddresses = callOff.data.order?.requisition?.project?.addresses.map(
                (address: ProjectAddress, i) =>
                    ({
                        value: address.id,
                        label: `Address ${i + 1}`,
                    } as { value: string; label: string })
            );
            return filteredAddresses;
        }
        return [];
    };

    const getDeliveryAddressDetails = () => {
        if (
            callOff.data &&
            callOff.data.order?.requisition?.project?.addresses &&
            deliveryAddressId
        ) {
            const filteredAddress = callOff.data.order?.requisition?.project?.addresses.find(
                (i) => i.id === deliveryAddressId
            );
            const addressLines = [];
            if (filteredAddress?.address1) {
                addressLines.push(filteredAddress?.address1);
            }
            if (filteredAddress?.address2) {
                addressLines.push(filteredAddress?.address2);
            }
            if (filteredAddress?.address3) {
                addressLines.push(filteredAddress?.address3);
            }
            if (filteredAddress?.postCode) {
                addressLines.push(filteredAddress?.postCode);
            }
            return addressLines.join('\n') || '';
        }
        return '';
    };

    const postDownloadCallOff = async () => {
        setIsLoading(true);
        await handleDownload(
            `/calloffs/${callOff.data?.id}/download?deliveryAddressId=${deliveryAddressId}`
        );

        setIsLoading(false);
        setOpenDownloadCallOffModal(false);
    };

    const postSendCallOff = async () => {
        if (!email) {
            setSendCallOffErrorMessage('Please capture all the fields above');
        } else if (!validateEmail(email)) {
            setSendCallOffErrorMessage('Please provide a valid email address');
        } else {
            setIsLoading(true);
            await sendCallOff.execute({ email, deliveryAddressId });
            // I cant get this error to work!
            if (sendCallOff.error) {
                // Display create order error here
                setSendCallOffErrorMessage(sendCallOff.error);
            } else {
                setOpenSendCallOffModal(false);
            }
            callOff.reset();
            setIsLoading(false);
        }
    };

    const postCreateDelivery = async () => {
        if (
            !deliveryDate ||
            !deliveryNote ||
            (deliveryItems.every((i) => i.returnedQuantity <= 0) &&
                deliveryItems.every((i) => i.quantity <= 0))
        ) {
            setDeliveryErrorMessage('Please capture all the fields below');
        } else if (
            deliveryItems.some((i) => i.returnedQuantity) &&
            (returnFiles.length <= 0 || !returnNote)
        ) {
            setDeliveryErrorMessage('Please capture all the fields below');
        } else if (deliveryFiles.length <= 0) {
            setDeliveryErrorMessage('You must upload at least 1 file');
        } else if (callOff.data?.order) {
            // upload
            const blobs = await uploadFiles(deliveryFiles);
            const returnBlobs = await uploadFiles(returnFiles);

            if (blobs.length !== deliveryFiles.length) {
                setDeliveryErrorMessage('Not all files were uploaded successfully');
                return;
            }

            if (returnBlobs.length !== returnFiles.length) {
                setDeliveryErrorMessage('Not all return files were uploaded successfully');
                return;
            }
            const data: DeliveryDTO = {
                deliveredAt: deliveryDate,
                note: deliveryNote,
                items: deliveryItems.filter((i) => i.quantity > 0 || i.returnedQuantity > 0),
                blobs,
                returnNote,
                returnBlobs,
            };

            await callOffDeliveryCreate.execute(data);
            if (!callOffDeliveryCreate.error) {
                handleCreateDeliveryModalClose();
            }

            callOff.reset();
            callOff.execute(getId());
        }
    };

    useEffect(() => {
        if (callOffDeliveryCreate.error) {
            setDeliveryErrorMessage(callOffDeliveryCreate.error);
        }
    }, [callOffDeliveryCreate.error]);

    const getTotalDelivered = (reqItemParam: RequisitionItem, callOffParam?: CallOff) => {
        if (
            callOffParam &&
            callOffParam.callOffDeliveries &&
            callOffParam.callOffDeliveries.length > 0
        ) {
            const { quantity } = callOffParam.callOffDeliveries
                .map((i) =>
                    i.items
                        .filter((j) => j.lineItemId === reqItemParam.id)
                        .reduce((a, b) => ({ lineItemId: '', quantity: a.quantity + b.quantity }), {
                            lineItemId: '',
                            quantity: 0,
                        })
                )
                .reduce((a, b) => ({ lineItemId: '', quantity: a.quantity + b.quantity }), {
                    lineItemId: '',
                    quantity: 0,
                });
            return round(quantity, DECIMAL_PLACES);
        }
        return 0;
    };

    const renderCallOff = () => {
        if (!callOff || callOff.loading || !callOff.data?.order) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="w-8 h-8 animate-spin text-orange-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">Fetching Call-Off from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (callOff.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="text-red-500 w-8 h-8 animate-pulse"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">
                                Failed to fetch Call-Off from the server ({callOff.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (callOff.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="text-red-500 w-8 h-8 animate-pulse"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">
                                Failed to fetch Order from the server ({callOff.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (callOff.data && callOff.data?.order) {
            const { description, createdAt, orderId, number } = callOff.data;
            return (
                <div>
                    <div className="bg-white border rounded-md h-full m-4">
                        <div className="text-l p-6">Call-Off Information</div>
                        <hr />
                        <div className="p-6">
                            <div className="mb-5">
                                <Button onClick={() => toOrderDetail(orderId)}>Go To Order</Button>
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Call-Off No."
                                    value={`${number?.toString().padStart(6, '0')}`}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Description"
                                    value={description}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Created"
                                    value={
                                        createdAt ? format(new Date(createdAt), 'yyyy-MM-dd') : ''
                                    }
                                    type="text"
                                    disabled
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="flex flex-col space-y-4 p-6">
                            {callOff.data &&
                                getPermissions.data?.items.find(
                                    (p) => p.code === 'Order_Download_CallOff'
                                )?.hasAccess && (
                                    <div>
                                        <Button onClick={() => setOpenDownloadCallOffModal(true)}>
                                            Download Call-Off
                                        </Button>
                                    </div>
                                )}
                            {callOff.data &&
                                callOff.data &&
                                getPermissions.data?.items.find(
                                    (p) => p.code === 'Order_Send_CallOff'
                                )?.hasAccess && (
                                    <div>
                                        <Button onClick={() => setOpenSendCallOffModal(true)}>
                                            Send Call-Off
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            {!getPermissions.data && <Loader message="Loading call-off..." />}
            {getPermissions.data && callOff.data && (
                <div className="flex">
                    <Modal
                        title="Download Call-Off"
                        onClose={() => setOpenDownloadCallOffModal(false)}
                        isOpen={openDownloadCallOffModal}
                    >
                        <div className="flex justify-between">
                            <div>
                                <div className="mb-5">
                                    <Select
                                        label="Delivery Address"
                                        placeholder="Address 1"
                                        options={formatDeliveryAddresses()}
                                        onChange={(event) =>
                                            setDeliveryAddressId(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="mb-5">
                                    <TextArea
                                        label=""
                                        value={getDeliveryAddressDetails()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div />
                        <div className="flex mb-5 text-red-500">{sendCallOffErrorMessage}</div>
                        <div className="flex flex-row-reverse">
                            <div className="pl-2" />
                            <div>
                                <Button onClick={postDownloadCallOff} disabled={isLoading}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title="Send Call-Off"
                        subtitle="Email Call-Off to email address"
                        onClose={() => setOpenSendCallOffModal(false)}
                        isOpen={openSendCallOffModal}
                    >
                        <div className="flex justify-between">
                            <div>
                                <div className="mb-5">
                                    <Input
                                        label="Email Address"
                                        value={email}
                                        type="email"
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </div>
                                <div className="mb-5">
                                    <Select
                                        label="Delivery Address"
                                        placeholder="Address 1"
                                        options={formatDeliveryAddresses()}
                                        onChange={(event) =>
                                            setDeliveryAddressId(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="mb-5">
                                    <TextArea
                                        label=""
                                        value={getDeliveryAddressDetails()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div />
                        <div className="flex mb-5 text-red-500">{sendCallOffErrorMessage}</div>
                        <div className="flex flex-row-reverse">
                            <div className="pl-2" />
                            <div>
                                <Button onClick={postSendCallOff} disabled={isLoading}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    {callOff.data?.order && (
                        <Modal
                            title="New Delivery"
                            // subtitle={`${order.data.number.toString().padStart(6, '0')}`}
                            onClose={handleCreateDeliveryModalClose}
                            isOpen={openCreateDeliveryModal}
                            onAction={postCreateDelivery}
                            loading={callOffDeliveryCreate.loading}
                        >
                            <div>
                                {deliveryErrorMessage && (
                                    <div className="flex mb-5 text-red-500 text-xl font-semibold">
                                        {deliveryErrorMessage}
                                    </div>
                                )}
                                <div className="flex justify-between">
                                    <div>
                                        <div className="w-full h-full mb-5">
                                            <div className="text-sm pb-1">Material Information</div>
                                            <table className="table-auto bg-white w-full">
                                                <thead>
                                                    <TableRow>
                                                        <TableHeader>Description</TableHeader>
                                                        <TableHeader>Cost Code</TableHeader>
                                                        <TableHeader>Creditor</TableHeader>
                                                        <TableHeader>
                                                            Quantity Remaining
                                                        </TableHeader>
                                                        <TableHeader>Delivered</TableHeader>
                                                        <TableHeader>Returned</TableHeader>
                                                    </TableRow>
                                                </thead>
                                                <tbody>
                                                    {callOff.data &&
                                                        callOff.data?.order &&
                                                        callOff.data?.order.lineItems &&
                                                        deliveryItems.length > 0 &&
                                                        callOff.data.items.map(
                                                            (callOffItem: CallOffItem) => {
                                                                if (
                                                                    callOff.data?.order &&
                                                                    callOff.data?.order.lineItems
                                                                ) {
                                                                    const reqItem = callOff.data?.order.lineItems.filter(
                                                                        (i) =>
                                                                            i.id ===
                                                                            callOffItem.lineItemId
                                                                    )[0];
                                                                    return (
                                                                        <TableRow hover>
                                                                            <TableData
                                                                                id={reqItem.id}
                                                                            >
                                                                                {
                                                                                    reqItem.description
                                                                                }
                                                                            </TableData>
                                                                            <TableData>
                                                                                {reqItem.costCode &&
                                                                                    reqItem.costCode
                                                                                        .name}
                                                                            </TableData>
                                                                            <TableData>
                                                                                {reqItem.creditor
                                                                                    ?.name || ''}
                                                                            </TableData>
                                                                            <TableData>
                                                                                {callOffItem.quantity &&
                                                                                    round(
                                                                                        callOffItem.quantity -
                                                                                            getTotalDelivered(
                                                                                                reqItem,
                                                                                                callOff.data
                                                                                            ),
                                                                                        DECIMAL_PLACES
                                                                                    )}
                                                                            </TableData>
                                                                            <TableData>
                                                                                <Input
                                                                                    value={deliveryItems
                                                                                        .filter(
                                                                                            (i) =>
                                                                                                i.lineItemId ===
                                                                                                reqItem.id
                                                                                        )[0]
                                                                                        .quantity?.toString()}
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        const oldDeliveryItem = deliveryItems.find(
                                                                                            (i) =>
                                                                                                i.lineItemId ===
                                                                                                reqItem.id
                                                                                        );
                                                                                        const newDeliveryItems = deliveryItems.filter(
                                                                                            (i) =>
                                                                                                i.lineItemId !==
                                                                                                reqItem.id
                                                                                        );
                                                                                        newDeliveryItems.push(
                                                                                            {
                                                                                                lineItemId:
                                                                                                    reqItem.id,
                                                                                                quantity: parseFloat(
                                                                                                    event
                                                                                                        .target
                                                                                                        .value ||
                                                                                                        '0'
                                                                                                ),
                                                                                                returnedQuantity:
                                                                                                    oldDeliveryItem?.returnedQuantity,
                                                                                            } as DeliveryItem
                                                                                        );
                                                                                        setDeliveryItems(
                                                                                            newDeliveryItems
                                                                                        );
                                                                                    }}
                                                                                    type="number"
                                                                                />
                                                                            </TableData>
                                                                            <TableData>
                                                                                <Input
                                                                                    value={deliveryItems
                                                                                        .filter(
                                                                                            (i) =>
                                                                                                i.lineItemId ===
                                                                                                reqItem.id
                                                                                        )[0]
                                                                                        .returnedQuantity?.toString()}
                                                                                    onChange={(
                                                                                        event
                                                                                    ) => {
                                                                                        const oldDeliveryItem = deliveryItems.find(
                                                                                            (i) =>
                                                                                                i.lineItemId ===
                                                                                                reqItem.id
                                                                                        );
                                                                                        const newDeliveryItems = deliveryItems.filter(
                                                                                            (i) =>
                                                                                                i.lineItemId !==
                                                                                                reqItem.id
                                                                                        );
                                                                                        newDeliveryItems.push(
                                                                                            {
                                                                                                lineItemId:
                                                                                                    reqItem.id,
                                                                                                returnedQuantity: parseFloat(
                                                                                                    event
                                                                                                        .target
                                                                                                        .value ||
                                                                                                        '0'
                                                                                                ),
                                                                                                quantity:
                                                                                                    oldDeliveryItem?.quantity,
                                                                                            } as DeliveryItem
                                                                                        );
                                                                                        setDeliveryItems(
                                                                                            newDeliveryItems
                                                                                        );
                                                                                    }}
                                                                                    type="number"
                                                                                />
                                                                            </TableData>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                                return <></>;
                                                            }
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="ml-5">
                                        <div className="mb-5">
                                            <div>Delivery Date</div>
                                            <div>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={deliveryDate}
                                                    onChange={(date: Date) => setDeliveryDate(date)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <Input
                                                label="Delivery Note"
                                                placeholder="Add Note Here"
                                                value={deliveryNote}
                                                type="text"
                                                onChange={(event) =>
                                                    setDeliveryNote(event.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <div className="text-sm pb-1">Upload Documents</div>
                                            <FileUpload
                                                updateFilesCb={updateUploadedFiles}
                                                // accept=".jpg,.png,.jpeg"
                                                multiple
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <div className="text-sm pb-1">Return (Optional)</div>
                                            <hr />
                                            <br />
                                            <Input
                                                label="Return Note"
                                                placeholder="Add Note Here"
                                                value={returnNote}
                                                type="text"
                                                onChange={(event) =>
                                                    setReturnNote(event.target.value)
                                                }
                                            />
                                            <FileUpload
                                                multiple={false}
                                                updateFilesCb={updateUploadedFile}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <div>{renderCallOff()}</div>
                    <div>
                        <div className="bg-white border w-full rounded-md h-full m-4">
                            <div className="p-5">
                                <TabsContainer
                                    selectedTab={selectedTab}
                                    options={tabs}
                                    onClick={setSelectedTab}
                                >
                                    {selectedTab === 'Materials' && (
                                        <div className="w-full h-full m-4">
                                            <table className="table-auto bg-white w-full">
                                                <thead>
                                                    <TableRow>
                                                        <TableHeader>Description</TableHeader>
                                                        <TableHeader>
                                                            Call-Off Quantity Remaining
                                                        </TableHeader>
                                                        <TableHeader>Cost Code</TableHeader>
                                                        <TableHeader>Creditor</TableHeader>
                                                    </TableRow>
                                                </thead>
                                                <tbody>
                                                    {callOff.data &&
                                                        callOff.data?.order &&
                                                        callOff.data.items &&
                                                        callOff.data.items.map(
                                                            (callOffItem: CallOffItem) => {
                                                                if (
                                                                    callOff.data?.order &&
                                                                    callOff.data?.order.lineItems
                                                                ) {
                                                                    const reqItem = callOff.data?.order.lineItems.filter(
                                                                        (i) =>
                                                                            i.id ===
                                                                            callOffItem.lineItemId
                                                                    )[0];
                                                                    return (
                                                                        <TableRow hover>
                                                                            <TableData
                                                                                id={reqItem.id}
                                                                            >
                                                                                {
                                                                                    reqItem.description
                                                                                }
                                                                            </TableData>
                                                                            <TableData>
                                                                                {round(
                                                                                    callOffItem.quantity -
                                                                                        getTotalDelivered(
                                                                                            reqItem,
                                                                                            callOff.data
                                                                                        ),
                                                                                    DECIMAL_PLACES
                                                                                )}
                                                                            </TableData>
                                                                            <TableData>
                                                                                {
                                                                                    reqItem.costCode
                                                                                        ?.name
                                                                                }
                                                                            </TableData>
                                                                            <TableData>
                                                                                {reqItem.creditor
                                                                                    ?.name || ''}
                                                                            </TableData>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                                return <></>;
                                                            }
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {selectedTab === 'Deliveries' && (
                                        <div>
                                            <div className="flex flex-row-reverse m-5">
                                                <Button
                                                    onClick={() => handleCreateDeliveryModalOpen()}
                                                >
                                                    Add Delivery
                                                </Button>
                                            </div>
                                            <div className="text-l">Delivery History</div>
                                            {callOff.data && callOff.data.callOffDeliveries && (
                                                <Table<Delivery>
                                                    data={callOff.data.callOffDeliveries}
                                                    onClick={(i) => toDeliveryDetail(i.id)}
                                                    columns={[
                                                        {
                                                            key: 'note',
                                                            title: 'Delivery Note',
                                                            render: (delivery) =>
                                                                `${delivery.note}`,
                                                        },
                                                        {
                                                            key: 'deliveredAt',
                                                            title: 'Delivered At',
                                                            render: (delivery) =>
                                                                format(
                                                                    new Date(
                                                                        delivery.deliveredAt || ''
                                                                    ),
                                                                    'yyyy-MM-dd'
                                                                ),
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </div>
                                    )}
                                </TabsContainer>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
