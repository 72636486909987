import React from 'react';

export default function IconButton({
    children,
    onClick,
    className,
}: {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}) {
    return (
        <button
            onClick={onClick}
            className={`focus:outline-none focus:border-none hover:bg-orange-500 hover:bg-opacity-90 p-2 rounded-full inline-flex items-center ${className} text-white`}
            type="button"
        >
            {children}
        </button>
    );
}
