import React from 'react';
import clsx from 'clsx';

export function TableRow({
    children,
    hover,
    onClick,
    status,
}: {
    children: React.ReactNode;
    hover?: boolean;
    onClick?: () => void;
    status?: string | undefined;
}) {
    const shouldHover = hover ? 'hover:bg-gray-200 cursor-pointer' : '';
    const statusClass = status || '';

    const className = clsx(shouldHover, statusClass);

    return (
        <tr className={className} onClick={onClick}>
            {children}
        </tr>
    );
}
