import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import PageOverlay from '../../components/PageOverlay';
import Panel from '../../components/Panel';
import CompanyLogo from '../../components/CompanyLogo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useConfirmUser } from '../../queries/Accounts';

export default function Verification(props: RouteComponentProps) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    // TODO: add REGEX for password validation
    /**
        RequiredLength = 8,
        RequiredUniqueChars = 4,
        RequireDigit = true,
        RequireLowercase = true,
        RequireNonAlphanumeric = true,
        RequireUppercase = true
     */
    const [password, setPassword] = useState('');
    const [userId, setUserId] = useState('');
    const [code, setCode] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    /**
     * Loading state is removed from here (not necessary).  You can just use the "confirmUser" loading state.
     */
    const [errorMessage, setErrorMessage] = useState('');
    const [invalidRequest, setInvalidRequest] = useState(false);

    const confirmUser = useConfirmUser();

    useEffect(() => {
        if (!props.location?.search) {
            setInvalidRequest(true);
            setErrorMessage('Invalid Request');
        }
        const parts = props.location?.search.split('&');
        if (parts) {
            setUserId(parts[0].split('=')[1]);
            setCode(decodeURIComponent(parts[1].split('=')[1]));
            if (parts[2]?.split('=')[1]) {
                setFirstName(decodeURIComponent(parts[2]?.split('=')[1]));
            }
            if (parts[3]?.split('=')[1]) {
                setLastName(decodeURIComponent(parts[3]?.split('=')[1]) || '');
            }
        }
        // eslint-disable-next-line react/destructuring-assignment
    }, [props.location?.search]);

    useEffect(() => {
        if (confirmUser.error) {
            setErrorMessage(confirmUser.error || 'Failed to verify your account');
        }
    }, [confirmUser.error]);

    const passwordMatch = () => password && confirmPassword && password === confirmPassword;

    const postUserDto = () => {
        setErrorMessage('');
        if (!passwordMatch()) {
            setErrorMessage('Please make sure the passwords match');
        } else {
            confirmUser.execute({
                userId,
                code,
                user: {
                    firstName,
                    lastName,
                    password,
                },
            });
        }
    };

    // TODO: Move the SVG to its own file for re-use
    if (invalidRequest) {
        return (
            <PageOverlay>
                <Panel>
                    <CompanyLogo />
                    <div className="flex justify-center">
                        <svg
                            className="h-20 w-20 m-10 text-red-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>
                    <div className="flex justify-center">{errorMessage}</div>
                </Panel>
            </PageOverlay>
        );
    }

    // TODO: Move the SVG to its own file for re-use
    if (confirmUser.success) {
        return (
            <PageOverlay>
                <Panel>
                    <CompanyLogo />
                    <div className="flex justify-center">
                        <svg
                            className="h-20 w-20 m-10 text-green-300"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>
                    <div className="flex justify-center">
                        <Button link="/login">Log In</Button>
                    </div>
                </Panel>
            </PageOverlay>
        );
    }

    return (
        <PageOverlay>
            <Panel>
                <CompanyLogo />
                <div className="flex justify-center mb-5">
                    <Input
                        label="First Name"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                </div>
                <div className="flex justify-center mb-5">
                    <Input
                        label="Last Name"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                </div>
                <div className="flex justify-center mb-5 mt-10">
                    <Input
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </div>
                <div className="flex justify-center mb-5">
                    <Input
                        label="Confirm Password"
                        type="password"
                        value={confirmPassword}
                        onChange={(event) => setConfirmPassword(event.target.value)}
                    />
                </div>
                <div className="flex justify-center mb-5 text-red-500">{errorMessage}</div>
                <div className="flex justify-center">
                    <Button onClick={postUserDto} disabled={confirmUser.loading}>
                        {!confirmUser.loading ? (
                            'Log In'
                        ) : (
                            <div className="flex justify-center">Loading</div>
                        )}
                    </Button>
                </div>
            </Panel>
        </PageOverlay>
    );
}
