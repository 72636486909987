import React, { useEffect, useRef, useState } from 'react';
import { useParams, RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import { TrashCan } from '../../components/Icons';
import {
    useGetBusinessUnit,
    useEditBusinessUnit,
    BusinessUnitDTO,
    // EditableBusinessUnit,
} from '../../queries/BusinessUnits';
import { TabsContainer } from '../../components/Tabs';
import {
    Role,
    useCreateUserRole,
    useDeleteUserRoles,
    useGetRoles,
    useGetUserRolesByRoleDTO,
    UserRole,
} from '../../queries/Roles';
import TypeAhead from '../../components/TypeAhead';
import Button from '../../components/Button';
import { useGetUsers, User } from '../../queries/Users';
import Table from '../../components/Table';
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function BusinessUnitDetail(props: RouteComponentProps) {
    const params = useParams();
    const businessUnitId = params.id;

    const newUserRole = useCreateUserRole();
    const deleteUserRoles = useDeleteUserRoles();
    const businessUnit = useGetBusinessUnit(businessUnitId);
    const editbusinessUnit = useEditBusinessUnit();
    const roles = useGetRoles();
    const users = useGetUsers();
    const usersRef = useRef(users);

    useEffect(() => {
        usersRef.current.reset();
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const { data } = businessUnit;

    const [tabs, setTabs] = useState<{ label: string; id: string }[]>([]);
    const [selectedTab, setSelectedTab] = useState('');
    const [defaultCurrency, setDefaultCurrency] = useState(undefined as string | undefined);
    const [name, setName] = useState(undefined as string | undefined);
    const [prefix, setPrefix] = useState(undefined as string | undefined);
    const [prefixRequisition, setPrefixRequisition] = useState(undefined as string | undefined);
    const [defaultVat, setDefaultVat] = useState(undefined as number | undefined);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const userRoles = useGetUserRolesByRoleDTO({
        businessUnitId,
        roleName: selectedTab,
    });
    const userRolesRef = useRef(userRoles);

    useEffect(() => {
        if (roles.data && tabs.length === 0) {
            const appRoles = roles.data
                .filter((f: Role) => f.scope === 'BUSINESSUNIT') // business unit scoped
                .sort((a: Role, b: Role) => a.displayOrder - b.displayOrder)
                .map((roleDto: Role) => {
                    return { label: roleDto.name, id: roleDto.name };
                });
            setTabs(appRoles);
            if (appRoles.length > 0) {
                setSelectedTab(appRoles[0].label);
            }
        }
    }, [tabs, roles.data]);

    useEffect(() => {
        setName(businessUnit.data?.name);
        setPrefix(businessUnit.data?.prefix);
        setPrefixRequisition(businessUnit.data?.prefixRequisition);
        setDefaultVat(businessUnit.data?.defaultVat?.amount);
        setDefaultCurrency(businessUnit.data?.defaultCurrency?.symbol);
    }, [businessUnit.data]);

    useEffect(() => {
        userRolesRef.current.reset();
    }, [selectedTab, userRolesRef]);

    const [selectedUserId, setSelectedUserId] = useState('');
    const selectedUser = users.data?.find((u: User) => u.id === selectedUserId);
    const [errorMessage, setErrorMessage] = useState('');

    const formatUsers = () => {
        if (users && users.data) {
            return users.data.map((formattedUser: User) => ({
                id: formattedUser.id,
                name: `${formattedUser.userName}${
                    formattedUser.firstName && formattedUser.lastName
                        ? ` (${formattedUser.firstName} ${formattedUser.lastName})`
                        : ''
                }`,
            }));
        }
        return [];
    };

    const handleSelectedItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = (event.target as unknown) as { id: string };
        setSelectedUserId(id);
    };

    const post = async () => {
        setErrorMessage('');
        if (!selectedUser) {
            setErrorMessage('Please capture a User Name');
        } else {
            if (!selectedUser) return;
            await newUserRole.execute({
                userName: selectedUser.userName,
                businessUnitId,
                roleName: selectedTab,
            });
            userRoles.reset();
            users.reset();
        }
    };

    const put = async () => {
        setIsLoading(true);
        setErrorMessage('');
        const businessDTO = {
            companyId: businessUnit.data?.company?.id,
            name,
            prefix,
            prefixRequisition,
            defaultVat,
            defaultCurrency,
            logoBlobId: undefined,
            id: businessUnit.data?.id,
        } as BusinessUnitDTO & { id: string };
        await editbusinessUnit.execute(businessDTO);
        businessUnit.reset();

        setIsLoading(false);
        setEditing(false);
    };

    const deleteUserRole = async (userRole: UserRole) => {
        await deleteUserRoles.execute(userRole);
        userRoles.reset();
    };

    const clearFields = () => {
        setErrorMessage('');
        setSelectedUserId('');
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const cancelEdit = () => {
        setEditing(false);
        businessUnit.reset();
    };

    const renderProject = () => {
        if (!businessUnit || businessUnit.loading) {
            return <Loader />;
        }
        if (businessUnit.error) {
            return (
                <Error
                    message={
                        <>Failed to fetch Business Unit from the server ({businessUnit.error})</>
                    }
                />
            );
        }
        if (data) {
            const { id } = data;
            return (
                <>
                    <div className="text-sm p-3 font-bold">Business Unit Information</div>
                    <hr />
                    <div className="p-3">
                        <div className="mb-5" hidden>
                            <Input label="id" value={id || ''} disabled />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Name"
                                value={name || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Prefix Order"
                                value={prefix || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setPrefix(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Prefix Requisition"
                                value={prefixRequisition || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setPrefixRequisition(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Default Currency"
                                value={defaultCurrency || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setDefaultCurrency(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Default VAT"
                                value={defaultVat?.toString() || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setDefaultVat(parseInt(e.target.value, 10))}
                            />
                        </div>
                        <div className="flex flex-row-reverse">
                            {editing && (
                                <div className="pl-2">
                                    <Button onClick={put}>
                                        {!isLoading ? 'SUBMIT' : <div>Loading</div>}
                                    </Button>
                                </div>
                            )}
                            {!editing && (
                                <div>
                                    <Button onClick={handleEdit}>Edit</Button>
                                </div>
                            )}
                            {editing && (
                                <div>
                                    <Button onClick={cancelEdit}>Cancel</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <div className="flex">
            <div className="bg-gray-400 border w-1/4 rounded-md h-full m-4">{renderProject()}</div>
            <div className="bg-gray-400 border w-3/4 rounded-md h-full m-4">
                <div className="text-sm p-3 font-bold">Business Unit Roles</div>
                <hr />
                <div className="p-3">
                    <TabsContainer
                        selectedTab={selectedTab}
                        options={tabs}
                        onClick={setSelectedTab}
                    >
                        <div className="mt-5 w-full">
                            <div className="">
                                <TypeAhead
                                    placeholder="Search"
                                    caseSensitive={false}
                                    label="Users"
                                    options={formatUsers()}
                                    onClick={(event) => handleSelectedItem(event)}
                                />
                            </div>
                            <div className="mb-5">
                                <div className="flex mb-5 text-red-500 text-xs">{errorMessage}</div>
                                <div className="flex flex-row-reverse">
                                    <div className="pl-2">
                                        <Button onClick={clearFields}>Clear</Button>
                                    </div>
                                    <div>
                                        <Button onClick={post}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                            <Table
                                loading={userRoles.loading}
                                error={
                                    userRoles.error &&
                                    `Failed to fetch UserRole list from the server (${userRoles.error})`
                                }
                                data={userRoles.data}
                                columns={[
                                    { key: 'userName', title: 'User Name' },
                                    { key: 'roleName', title: 'Role Name' },
                                    {
                                        key: 'businessUnitId',
                                        title: 'Business Unit Id',
                                    },
                                    {
                                        key: 'delete',
                                        title: '',
                                        onClick: (userRole) => deleteUserRole(userRole),
                                        render: (userRole) => (
                                            <TrashCan
                                                className="cursor-pointer flex"
                                                id={userRole.userName}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </TabsContainer>
                </div>
            </div>
        </div>
    );
}
