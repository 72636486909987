import React, { useEffect, useRef, useState } from 'react';
import { useParams, RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import { TrashCan } from '../../components/Icons';
import { TabsContainer } from '../../components/Tabs';
import {
    Role,
    useCreateUserRole,
    useDeleteUserRoles,
    useGetRoles,
    useGetUserRolesByRoleDTO,
    UserRole,
} from '../../queries/Roles';
import TypeAhead from '../../components/TypeAhead';
import Button from '../../components/Button';
import { useGetUsers, User } from '../../queries/Users';
import Table from '../../components/Table';
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import { UpsertCreditorDTO, useEditCreditor, useGetCreditor } from '../../queries/Creditors';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';
import Select from '../../components/Select';
import { Company, useGetCompanies } from '../../queries/Companies';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CreditorDetail(props: RouteComponentProps) {
    const params = useParams();
    const creditorId = params.id;

    const newUserRole = useCreateUserRole();
    const deleteUserRoles = useDeleteUserRoles();
    const creditor = useGetCreditor(creditorId);
    const roles = useGetRoles();
    const users = useGetUsers();
    const usersRef = useRef(users);
    const getCompaniesService = useGetCompanies();

    useEffect(() => {
        usersRef.current.reset();
    }, []);

    const editCreditor = useEditCreditor();

    const [tabs, setTabs] = useState<{ label: string; id: string }[]>([]);
    const [selectedTab, setSelectedTab] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false);

    const [name, setName] = useState(undefined as string | undefined);
    const [cell, setCell] = useState(undefined as string | undefined);
    const [code, setCode] = useState(undefined as string | undefined);
    const [contactPerson, setContactPerson] = useState(undefined as string | undefined);
    const [address1, setAddress1] = useState(undefined as string | undefined);
    const [address2, setAddress2] = useState(undefined as string | undefined);
    const [address3, setAddress3] = useState(undefined as string | undefined);
    const [vatNo, setVatNo] = useState(undefined as string | undefined);
    const [discount, setDiscount] = useState(undefined as number | undefined);
    const [paymentMethod, setPaymentMethod] = useState(undefined as string | undefined);
    const [postCode, setPostCode] = useState(undefined as string | undefined);
    const [tel, setTel] = useState(undefined as string | undefined);
    const [fax, setFax] = useState(undefined as string | undefined);
    const [email, setEmail] = useState(undefined as string | undefined);
    const [url, setUrl] = useState(undefined as string | undefined);
    const [agingCategory, setAgingCategory] = useState(undefined as string | undefined);
    const [beeLevel, setBeeLevel] = useState(undefined as string | undefined);
    const [beeStatus, setBeeStatus] = useState(undefined as string | undefined);
    const [beeType, setBeeType] = useState(undefined as string | undefined);
    const [province, setProvince] = useState(undefined as string | undefined);
    const [companyId, setCompanyId] = useState(undefined as string | undefined);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Creditor_Admin')
            ?.hasAccess &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Creditor_Read_Only')
            ?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const userRoles = useGetUserRolesByRoleDTO({
        creditorId,
        roleName: selectedTab,
    });
    const userRolesRef = useRef(userRoles);

    useEffect(() => {
        setName(creditor.data?.name);
        setCell(creditor.data?.cell);
        setCode(creditor.data?.code);
        setContactPerson(creditor.data?.contactPerson);
        setAddress1(creditor.data?.address1);
        setAddress2(creditor.data?.address2);
        setAddress3(creditor.data?.address3);
        setVatNo(creditor.data?.vatNo);
        setDiscount(creditor.data?.discount);
        setPaymentMethod(creditor.data?.paymentMethod);
        setPostCode(creditor.data?.postCode);
        setTel(creditor.data?.tel);
        setFax(creditor.data?.fax);
        setEmail(creditor.data?.email);
        setUrl(creditor.data?.url);
        setAgingCategory(creditor.data?.agingCategory);
        setBeeLevel(creditor.data?.beeLevel);
        setBeeStatus(creditor.data?.beeStatus);
        setBeeType(creditor.data?.beeType);
        setProvince(creditor.data?.province);
        setCompanyId(creditor.data?.company?.id);
    }, [creditor.data]);

    useEffect(() => {
        if (roles.data && tabs.length === 0) {
            const appRoles = roles.data
                .filter((f: Role) => f.scope === 'CREDITOR') // creditor scoped
                .sort((a: Role, b: Role) => a.displayOrder - b.displayOrder)
                .map((roleDto: Role) => {
                    return { label: roleDto.name, id: roleDto.name };
                });
            setTabs(appRoles);
            if (appRoles.length > 0) {
                setSelectedTab(appRoles[0].label);
            }
        }
    }, [tabs, roles.data]);

    useEffect(() => {
        userRolesRef.current.reset();
    }, [selectedTab, userRolesRef]);

    const [selectedUserId, setSelectedUserId] = useState('');
    const selectedUser = users.data?.find((u: User) => u.id === selectedUserId);
    const [errorMessage, setErrorMessage] = useState('');

    const formatUsers = () => {
        if (users && users.data) {
            return users.data.map((user: User) => ({
                id: user.id,
                name: `${user.userName}${
                    user.firstName && user.lastName ? ` (${user.firstName} ${user.lastName})` : ''
                }`,
            }));
        }
        return [];
    };

    const handleSelectedItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = (event.target as unknown) as { id: string };
        setSelectedUserId(id);
    };

    const post = async () => {
        setErrorMessage('');
        if (!selectedUser) {
            setErrorMessage('Please capture a User Name');
        } else {
            if (!selectedUser) return;
            await newUserRole.execute({
                userName: selectedUser.userName,
                creditorId,
                roleName: selectedTab,
            });
            userRoles.reset();
            users.reset();
        }
    };

    const deleteUserRole = async (userRole: UserRole) => {
        await deleteUserRoles.execute(userRole);
        userRoles.reset();
    };

    const clearFields = () => {
        setErrorMessage('');
        setSelectedUserId('');
    };

    const put = async () => {
        setIsLoading(true);
        setErrorMessage('');
        const creditorDTO = {
            name,
            code,
            contactPerson,
            cell,
            tel,
            fax,
            agingCategory,
            paymentMethod,
            beeLevel,
            beeStatus,
            beeType,
            address1,
            address2,
            address3,
            discount,
            vatNo,
            email,
            url,
            province,
            postCode,
            id: creditor.data?.id,
            companyId,
        } as UpsertCreditorDTO & { id: string };
        await editCreditor.execute(creditorDTO);
        creditor.reset();
        setIsLoading(false);
        setEditing(false);
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const cancelEdit = () => {
        setEditing(false);
        creditor.reset();
    };

    const formatCompanies = (search = false) => {
        if (getCompaniesService && getCompaniesService.data && getCompaniesService.data.items) {
            const selectAll = [{ value: '', label: 'All' }];
            const filteredCompanies = getCompaniesService.data.items.map(
                (companyItem: Company) =>
                    ({
                        value: companyItem.id,
                        label: companyItem.displayName,
                    } as { value: string; label: string })
            );
            return search ? selectAll.concat(filteredCompanies) : filteredCompanies;
        }
        return [];
    };

    const renderProject = () => {
        if (!creditor || creditor.loading) {
            return <Loader />;
        }
        if (creditor.error) {
            return (
                <Error message={<>Failed to fetch Creditor from the server ({creditor.error})</>} />
            );
        }
        if (creditor.data) {
            const { id } = creditor.data;
            return (
                <>
                    <div className="text-sm p-3 font-bold">Creditor Information</div>
                    <hr />
                    <div className="flex justify-between p-3">
                        <div>
                            <div className="mb-5" hidden>
                                <Input label="id" value={id || ''} disabled />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Name"
                                    value={name || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Code"
                                    value={code || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address 1"
                                    value={address1 || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address 2"
                                    value={address2 || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address 3"
                                    value={address3 || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAddress3(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Post Code"
                                    value={postCode || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setPostCode(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Tel"
                                    value={tel || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setTel(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Fax"
                                    value={fax || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setFax(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Email"
                                    value={email || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="URL"
                                    value={url || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Select
                                    label="Company"
                                    disabled={!editing}
                                    options={formatCompanies()}
                                    onChange={(event) => setCompanyId(event.target.value)}
                                    dark
                                    selected={companyId}
                                />
                            </div>
                        </div>
                        <div className="ml-5">
                            <div className="mb-5">
                                <Input
                                    label="Cell"
                                    value={cell || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setCell(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Contact Person"
                                    value={contactPerson || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setContactPerson(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="VAT Number"
                                    value={vatNo || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setVatNo(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Discount"
                                    value={discount || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setDiscount(parseInt(e.target.value, 10))}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Payment Method"
                                    value={paymentMethod || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setPaymentMethod(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Aging Category"
                                    value={agingCategory || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAgingCategory(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="BEE Status"
                                    value={beeStatus || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setBeeStatus(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="BEE Type"
                                    value={beeType || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setBeeType(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="BEE Level"
                                    value={beeLevel || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setBeeLevel(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Province"
                                    value={province || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setProvince(e.target.value)}
                                />
                            </div>

                            {!getPermissions.data?.items.find(
                                (p) => p.code === 'Project_Allow_Creditor_Read_Only'
                            )?.hasAccess && (
                                <div className="flex flex-row-reverse">
                                    {editing && (
                                        <div className="pl-2">
                                            <Button onClick={put} disabled={isLoading}>
                                                {!isLoading ? 'SUBMIT' : <div>Loading</div>}
                                            </Button>
                                        </div>
                                    )}
                                    {!editing && (
                                        <div>
                                            <Button onClick={handleEdit}>Edit</Button>
                                        </div>
                                    )}
                                    {editing && (
                                        <div>
                                            <Button onClick={cancelEdit}>Cancel</Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <div className="flex">
            <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">{renderProject()}</div>
            {!getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Creditor_Read_Only')
                ?.hasAccess && (
                <div className="w-3/4 bg-gray-400 border rounded-md h-full m-4">
                    <div className="text-sm p-3 font-bold">Creditor Roles</div>
                    <hr />
                    <div className="p-3">
                        <TabsContainer
                            selectedTab={selectedTab}
                            options={tabs}
                            onClick={setSelectedTab}
                        >
                            <div className="mt-5 w-full">
                                <div className="">
                                    <TypeAhead
                                        placeholder="Search"
                                        caseSensitive={false}
                                        label="Users"
                                        options={formatUsers()}
                                        onClick={(event) => handleSelectedItem(event)}
                                    />
                                </div>
                                <div className="mb-5">
                                    <div className="flex mb-5 text-red-500 text-xs">
                                        {errorMessage}
                                    </div>
                                    <div className="flex flex-row-reverse">
                                        <div className="pl-2">
                                            <Button onClick={clearFields}>Clear</Button>
                                        </div>
                                        <div>
                                            <Button onClick={post}>Submit</Button>
                                        </div>
                                    </div>
                                </div>
                                <Table
                                    loading={userRoles.loading}
                                    error={
                                        userRoles.error &&
                                        `Failed to fetch UserRole list from the server (${userRoles.error})`
                                    }
                                    data={userRoles.data}
                                    columns={[
                                        { key: 'userName', title: 'User Name' },
                                        { key: 'roleName', title: 'Role Name' },
                                        {
                                            key: 'creditorId',
                                            title: 'Creditor Id',
                                        },
                                        {
                                            key: 'delete',
                                            title: '',
                                            onClick: (userRole) => deleteUserRole(userRole),
                                            render: (userRole) => (
                                                <TrashCan
                                                    className="cursor-pointer flex"
                                                    id={userRole.userName}
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </TabsContainer>
                    </div>
                </div>
            )}
        </div>
    );
}
