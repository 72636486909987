import { domain } from '../d-man';
import { Trade } from './Trades';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type CostCode = {
    id: string;
    code: string;
    name: string;
    trade: Trade;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getQueryString = (params: any) => {
    return Object.keys(params)
        .map((b) => (params[b] ? `${b}=${params[b]}` : ''))
        .join('&');
};

export type CreateCostCodeDTO = schemas['CreateCostCodeDTO'];

export function useCreateCostCode() {
    return domain.usePost<CreateCostCodeDTO, null>('/costcodes');
}

export function useGetCostCodes(skip?: number, take?: number, tradeId?: string) {
    return domain.useGet<ObjectList<CostCode>>(
        `/costcodes?${getQueryString({ skip, take, tradeId })}`
    );
}
