import React from 'react';
import { Link } from '@reach/router';
import clsx from 'clsx';

export default function Button({
    children,
    disabled,
    link,
    onClick,
    type,
    secondary,
    loading,
}: {
    children: React.ReactNode;
    // large?: boolean;
    type?: 'button' | 'submit';
    secondary?: boolean;
    link?: string;
    disabled?: boolean;
    loading?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    [props: string]: unknown;
}) {
    if (link) {
        return (
            <Link
                to={link}
                className="bg-orange-500 text-white border border-gray-500 rounded-md w-40 h-10"
            >
                {children}
            </Link>
        );
    }

    return (
        <button
            className={clsx('rounded-md p-2 border ', {
                'bg-orange-500': !secondary,
                'text-white': !secondary,
                'border-gray-500': !secondary,
                'opacity-100': !disabled,
                'opacity-25': disabled,
            })}
            onClick={onClick}
            disabled={disabled}
            // eslint-disable-next-line react/button-has-type
            type={type || 'button'}
        >
            {loading ? 'loading' : children}
        </button>
    );
}
