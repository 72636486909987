import React, { useEffect, useState } from 'react';
import { useParams, RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Error from '../../components/Error';
import Loader from '../../components/Loader';
import { UpsertCompanyDTO, useEditCompany, useGetCompany } from '../../queries/Companies';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CompaniesDetail(props: RouteComponentProps) {
    const params = useParams();
    const companyId = params.id;

    const company = useGetCompany(companyId);
    const editCompany = useEditCompany();
    const [isLoading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(undefined as string | undefined | null);
    const [displayName, setDisplayName] = useState(undefined as string | undefined | null);
    const [vatNo, setVatNo] = useState(undefined as string | undefined | null);
    const [registrationNo, setRegistrationNo] = useState(undefined as string | undefined | null);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        if (company.data) {
            setName(company.data?.name);
            setDisplayName(company.data?.displayName);
            setVatNo(company.data?.vatNo);
            setRegistrationNo(company.data?.registrationNo);
        }
    }, [company.data]);

    const [, setErrorMessage] = useState('');

    const back = () => {
        if (props.navigate) {
            props.navigate(`/companies`);
        }
    };

    const put = async () => {
        setIsLoading(true);
        setErrorMessage('');
        const companyDTO = {
            name,
            displayName,
            vatNo,
            registrationNo,
            id: companyId,
        } as UpsertCompanyDTO & { id: string };
        await editCompany.execute(companyDTO);
        company.reset();
        setIsLoading(false);
        setEditing(false);
        back();
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const cancelEdit = () => {
        setEditing(false);
        company.reset();
    };

    const renderProject = () => {
        if (!company || company.loading) {
            return <Loader />;
        }
        if (company.error) {
            return (
                <Error message={<>Failed to fetch Company from the server ({company.error})</>} />
            );
        }
        if (company.data) {
            const { id } = company.data;
            return (
                <>
                    <div className="text-sm p-3 font-bold">Company Information</div>
                    <hr />
                    <div className="p-3">
                        <div className="mb-5" hidden>
                            <Input label="id" value={id || ''} disabled />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Name"
                                value={name || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Display Name"
                                value={displayName || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setDisplayName(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Vat Number"
                                value={vatNo || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setVatNo(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Registration Number"
                                value={registrationNo || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setRegistrationNo(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-row-reverse">
                            {editing && (
                                <div className="pl-2">
                                    <Button onClick={put}>
                                        {!isLoading ? 'SUBMIT' : <div>Loading</div>}
                                    </Button>
                                </div>
                            )}
                            {!editing && (
                                <div>
                                    <Button onClick={handleEdit}>Edit</Button>
                                </div>
                            )}
                            {editing && (
                                <div>
                                    <Button onClick={cancelEdit}>Cancel</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <div className="flex">
            <div className="bg-gray-400 border w-1/4 rounded-md h-full m-4">{renderProject()}</div>
        </div>
    );
}
