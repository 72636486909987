import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { RouteComponentProps } from '@reach/router';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { Order, useGetOrders } from '../../queries/Orders';
import Table from '../../components/Table';
import Pager from '../../components/Pager';
import Input from '../../components/Input';
import TypeAhead from '../../components/TypeAhead';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Creditor, useGetCreditors } from '../../queries/Creditors';
import { useCurrentProjectId, useCurrentBusinessUnitId } from '../../queries/Accounts';
import Select from '../../components/Select';
import {
    handleWebDownload,
    formatCurrencyAmount,
    fomatCurrencySymbol,
    readQueryParams,
    handleClearFilterFromURL,
} from '../../utils/Utils';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import TextArea from '../../components/TextArea';
import { domain } from '../../d-man';
import { Trade, useGetTrades } from '../../queries/Trades';
import { useGetUsers } from '../../queries/Users';
import { useGetPermissions } from '../../queries/Permissions';
import { useGetBusinessUnits } from '../../queries/BusinessUnits';
import { ProjectAddress } from '../../queries/Projects';
import Loader from '../../components/Loader';

const STATUS = {
    Cancelled: 'bg-red-200',
    Approved: 'bg-blue-200',
    Export: 'bg-green-200',
    Sent_To_Supplier: 'bg-purple-200',
    Draft: 'bg-yellow-100',
    Exported: 'bg-green-200',
};

export default function Orders(props: RouteComponentProps) {
    const PAGE_SIZE = 30;
    const DEBOUNCE_DELAY = 1000;

    // TODO: should fix this
    const MAX_USERS = 10000;
    const users = useGetUsers(0, MAX_USERS);
    const usersRef = useRef(users);

    const params = window.location.search;

    useEffect(() => {
        usersRef.current.reset();
    }, []);

    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState(undefined as number | undefined);
    const [trade, setTrade] = useState(undefined as string | undefined);
    const [orderNumber, setOrderNumber] = useState(undefined as number | undefined);
    const [orderStatus, setOrderStatus] = useState(
        readQueryParams(params, 'status') || ('all' as string | undefined)
    );
    const [orderDateFrom, setOrderDateFrom] = useState(undefined as Date | undefined);
    const [orderDateTo, setOrderDateTo] = useState(undefined as Date | undefined);
    const [searchOrderStatus, setSearchOrderStatus] = useState(
        readQueryParams(params, 'status') || (undefined as string | undefined)
    );
    const [searchTrade, setSearchTrade] = useState(undefined as string | undefined);
    const [searchOrderDateFrom, setSearchOrderDateFrom] = useState(undefined as Date | undefined);
    const [searchOrderDateTo, setSearchOrderDateTo] = useState(undefined as Date | undefined);
    const [searchOrderType, setSearchOrderType] = useState(undefined as number | undefined);
    const [orderType, setOrderType] = useState(undefined as number | undefined);
    const [stallOrders, setStallOrders] = useState(true);
    const [stallPermissions, setStallPermissions] = useState(true);
    const [companyId, setCompanyId] = useState(undefined as string | undefined);
    const [isExporting, setIsExporting] = useState(false);
    const [openDownloadOrderModal, setOpenDownloadOrderModal] = useState(false);
    const [deliveryAddressId, setDeliveryAddressId] = useState('');
    // const [sendOrderErrorMessage, setSendOrderErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedOrder, setSelectedOrder] = useState(undefined as Order | undefined);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [creditorId, setCreditorId] = useState(undefined as string | undefined);

    const projectId = useCurrentProjectId();
    const getTrades = useGetTrades();

    const getPermissions = useGetPermissions('PROJECT', projectId.data, stallPermissions);

    const businessUnitId = useCurrentBusinessUnitId();
    const businessUnits = useGetBusinessUnits();

    const shouldGetApprovedOrders = () => {
        if (orderStatus === 'approved' || orderStatus === 'exported') {
            return true;
        }
        if (orderStatus === 'draft') {
            return false;
        }
        if (
            !getPermissions.data?.items.find((p) => p.code === 'Project_Show_Unapproved_Orders')
                ?.hasAccess
        ) {
            return true;
        }
        return undefined;
    };

    const shouldGetExportedOrders = () => {
        if (orderStatus === 'exported') {
            return true;
        }
        if (orderStatus === 'approved' || orderStatus === 'draft') {
            return false;
        }
        if (
            !getPermissions.data?.items.find((p) => p.code === 'Project_Show_Exported_Orders')
                ?.hasAccess
        ) {
            return false;
        }
        return undefined;
    };

    const shouldShowCancelled = () => {
        if (orderStatus === 'cancelled') {
            return true;
        }
        if (orderStatus === 'all') {
            return undefined;
        }
        return false;
    };

    const orders = useGetOrders(
        skip,
        PAGE_SIZE,
        orderNumber,
        projectId.data,
        undefined,
        creditorId,
        shouldShowCancelled(),
        shouldGetApprovedOrders(),
        shouldGetExportedOrders(),
        stallOrders,
        orderDateFrom,
        orderDateTo,
        trade,
        orderType
    );

    const creditors = useGetCreditors(undefined, undefined, undefined, undefined, companyId);

    const creditorsRef = useRef(creditors);
    const ordersRef = useRef(orders);

    useEffect(() => {
        if (projectId.data && getPermissions.data) {
            if (stallOrders) {
                setStallOrders(false);
            } else {
                setSkip(0);
                ordersRef.current.reset();
            }
        }
        if (projectId.data) {
            setStallPermissions(false);
        }
        // eslint-disable-next-line
    }, [projectId.data, getPermissions.data]);

    useEffect(() => {
        if (businessUnitId) {
            const currentCompany = businessUnits.data?.items.find(
                (u) => u.id === businessUnitId.data
            );
            setCompanyId(currentCompany?.company?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessUnitId]);

    useEffect(() => {
        if (companyId) {
            creditors
                .execute({
                    companyId,
                })
                .then(() => creditors.reset());
            creditorsRef.current.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    useEffect(() => {
        if (
            selectedOrder?.requisition?.project?.addresses &&
            selectedOrder?.requisition?.project?.addresses?.length
        ) {
            setDeliveryAddressId(selectedOrder?.requisition?.project?.addresses[0].id || '');
        }
    }, [selectedOrder]);

    const handleDownload = async (endpoint: string) => {
        setIsExporting(true);
        const [, data, response] = await domain
            .get(endpoint, {
                requestConfig: {
                    responseType: 'blob', // important
                },
            })
            .execute();
        if (!data) return;
        handleWebDownload(data, response);
        // orders.reset();
        setIsExporting(false);
    };

    const isAvailableForExport = (order: Order) => {
        return (
            (order.exportedAt == null && order.exportedBy == null) ||
            (getPermissions.data?.items.find(
                (p) => p.code === 'Project_Enable_Export_On_Orders_Override'
            )?.hasAccess &&
                order.approvedAt !== null &&
                order.approvedBy !== null)
        );
    };

    const getStatusClasses = (row: Order) => {
        if (row.cancelledAt || row.cancelledBy) {
            return STATUS.Cancelled;
        }
        if (row.exportedAt || row.exportedBy) {
            return STATUS.Exported;
        }
        if (row.sentAt || row.sentBy) {
            return STATUS.Sent_To_Supplier;
        }
        if (row.approvedAt || row.approvedBy) {
            return STATUS.Approved;
        }
        return STATUS.Draft;
    };

    const getStatus = (order: Order) => {
        if (order.cancelledAt !== null || order.cancelledBy !== null) {
            return 'Cancelled';
        }
        if (order.exportedAt !== null || order.exportedBy !== null) {
            return 'Exported';
        }
        if (order.approvedAt !== null || order.approvedBy !== null) {
            if (
                getPermissions.data?.items.find((p) => p.code === 'Project_Enable_Export_On_Orders')
                    ?.hasAccess
            ) {
                return (
                    <Button
                        disabled={!isAvailableForExport(order)}
                        onClick={() => handleDownload(`/orders/${order?.id}/export`)}
                        loading={isExporting}
                    >
                        Export
                    </Button>
                );
            }
            return 'Approved';
        }
        return 'Draft';
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDownloadButonClick = (order: Order) => {
        setSelectedOrder(order);
        setOpenDownloadOrderModal(true);
    };

    const getDownloadStatus = (order: Order) => {
        if (order.cancelledAt === null && order.cancelledBy === null) {
            if (
                getPermissions.data?.items.find((p) => p.code === 'Project_Order_Download')
                    ?.hasAccess
            ) {
                return <Button onClick={() => handleDownloadButonClick(order)}>Download</Button>;
            }
        }
        return '';
    };

    const formatTrades = () => {
        if (getTrades && getTrades.data && getTrades.data.items) {
            const selectAll = [{ value: '', label: 'All' }];
            const filteredTrades = getTrades.data.items.map(
                (tradeItem: Trade) =>
                    ({
                        value: tradeItem.id,
                        label: `${tradeItem.code} - ${tradeItem.description}`,
                    } as { value: string; label: string })
            );
            return selectAll.concat(filteredTrades);
        }
        return [];
    };

    const formatOrderTypes = () => {
        const types: { value: string; label: string }[] = [
            { value: 'all', label: 'All' },
            { value: '0', label: 'Single' },
            { value: '1', label: 'Bulk' },
        ];
        return types;
    };

    const formatOrderStatuses = () => {
        let statuses: { value: string; label: string }[] = [
            { value: 'all', label: 'All' },
            { value: 'draft', label: 'Draft' },
            { value: 'approved', label: 'Approved' },
            { value: 'exported', label: 'Exported' },
            { value: 'cancelled', label: 'Cancelled' },
        ];

        // only approved for control clerk
        if (
            !getPermissions.data?.items.find((p) => p.code === 'Project_Show_Unapproved_Orders')
                ?.hasAccess
        ) {
            statuses = [{ value: 'approved', label: 'Approved' }];
        }
        return statuses;
    };

    const formatCreditors = () => {
        if (creditors && creditors.data && creditors.data.items) {
            return creditors.data.items.map(
                (creditor: Creditor) =>
                    ({
                        id: creditor.id,
                        name: creditor.name,
                    } as { id: string; name: string })
            );
        }
        return [];
    };

    const postDownloadOrder = async () => {
        if (!selectedOrder) return;
        setIsLoading(true);
        await handleDownload(
            `/orders/${selectedOrder.id}/download?deliveryAddressId=${deliveryAddressId}`
        );
        setIsLoading(false);
        setOpenDownloadOrderModal(false);
    };

    const formatDeliveryAddresses = () => {
        if (selectedOrder && selectedOrder.requisition?.project?.addresses) {
            const filteredAddresses = selectedOrder.requisition?.project?.addresses.map(
                (address: ProjectAddress, i) =>
                    ({
                        value: address.id,
                        label: `Address ${i + 1}`,
                    } as { value: string; label: string })
            );
            return filteredAddresses;
        }
        return [];
    };

    const getDeliveryAddressDetails = () => {
        if (selectedOrder && selectedOrder.requisition?.project?.addresses && deliveryAddressId) {
            const filteredAddress = selectedOrder.requisition?.project?.addresses.find(
                (i) => i.id === deliveryAddressId
            );
            const addressLines = [];
            if (filteredAddress?.address1) {
                addressLines.push(filteredAddress?.address1);
            }
            if (filteredAddress?.address2) {
                addressLines.push(filteredAddress?.address2);
            }
            if (filteredAddress?.address3) {
                addressLines.push(filteredAddress?.address3);
            }
            if (filteredAddress?.postCode) {
                addressLines.push(filteredAddress?.postCode);
            }
            return addressLines.join('\n') || '';
        }
        return '';
    };

    const debounced = useRef(
        debounce(
            DEBOUNCE_DELAY,
            (
                newSearch?: number,
                newOrderStatus?: string,
                newOrderDateFrom?: Date,
                newOrderDateTo?: Date,
                newTrade?: string,
                newOrderType?: number,
                newCreditor?: string
            ) => {
                if (Number.isNaN(newSearch)) {
                    setOrderNumber(undefined);
                } else {
                    setOrderNumber(newSearch);
                }
                setOrderStatus(newOrderStatus);
                setOrderDateFrom(newOrderDateFrom);
                setOrderDateTo(newOrderDateTo);
                setOrderType(newOrderType);
                setCreditorId(newCreditor);
                if (newTrade && newTrade !== '') {
                    setTrade(newTrade);
                } else {
                    setTrade(undefined);
                }
                orders.reset();
            }
        )
    );

    useEffect(() => {
        handleClearFilterFromURL(params, 'status');
        if (
            search !== undefined ||
            searchOrderStatus !== undefined ||
            searchOrderDateFrom !== undefined ||
            searchOrderDateTo !== undefined ||
            searchTrade !== undefined ||
            searchOrderType !== undefined ||
            creditorId !== undefined
        ) {
            debounced.current(
                search,
                searchOrderStatus,
                searchOrderDateFrom,
                searchOrderDateTo,
                searchTrade,
                searchOrderType,
                creditorId
            );
        } else {
            ordersRef.current.reset();
            creditorsRef.current.reset();
        }
    }, [
        search,
        searchOrderStatus,
        searchOrderDateFrom,
        searchOrderDateTo,
        searchTrade,
        searchOrderType,
        debounced,
        ordersRef,
        creditorsRef,
        creditorId,
        params,
    ]);

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        orders.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        orders.reset();
    };

    const toDetail = (id?: string) => {
        if (
            props.navigate &&
            getPermissions.data?.items.find((p) => p.code === 'Project_Show_Orders_Details_Screen')
                ?.hasAccess
        ) {
            window.open(`/orders/${id}`);
        }
    };

    return (
        <>
            {!getPermissions.data && <Loader message="Loading orders..." />}
            {getPermissions.data && (
                <>
                    <Modal
                        title="Download Order"
                        onClose={() => setOpenDownloadOrderModal(false)}
                        isOpen={openDownloadOrderModal}
                    >
                        <div className="flex justify-between">
                            <div>
                                <div className="mb-5">
                                    <Select
                                        label="Delivery Address"
                                        placeholder="Address 1"
                                        options={formatDeliveryAddresses()}
                                        onChange={(event) =>
                                            setDeliveryAddressId(event.target.value)
                                        }
                                    />
                                </div>
                                <div className="mb-5">
                                    <TextArea
                                        label=""
                                        value={getDeliveryAddressDetails()}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div />
                        {/* <div className="flex mb-5 text-red-500">{sendOrderErrorMessage}</div> */}
                        <div className="flex flex-row-reverse">
                            <div className="pl-2" />
                            <div>
                                <Button onClick={postDownloadOrder} disabled={isLoading}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <div className="flex">
                        <div className={`w-full ${orders.data ? 'h-full' : 'h-screen'} m-4`}>
                            <div className="flex justify-between">
                                <div className="flex mb-5 gap-4 flex-wrap">
                                    <div className="self-center">
                                        <Input
                                            value={search}
                                            label="Order Number"
                                            placeholder="Search Order Number"
                                            type="number"
                                            onChange={(event) =>
                                                setSearch(parseInt(event.target.value, 10))
                                            }
                                            dark
                                        />
                                    </div>
                                    <div className="self-center">
                                        <Select
                                            label="Order Status"
                                            disabled={orders.loading}
                                            placeholder="Search Order Status"
                                            options={formatOrderStatuses()}
                                            onChange={(event) =>
                                                setSearchOrderStatus(event.target.value)
                                            }
                                            selected={searchOrderStatus}
                                        />
                                    </div>
                                    <div className="self-center">
                                        <Select
                                            label="Trade"
                                            disabled={orders.loading}
                                            placeholder="Search Trade"
                                            options={formatTrades()}
                                            onChange={(event) => setSearchTrade(event.target.value)}
                                        />
                                    </div>
                                    <div className="self-center">
                                        <Select
                                            label="Order Type"
                                            disabled={orders.loading}
                                            placeholder="Search Order Type"
                                            options={formatOrderTypes()}
                                            onChange={(event) =>
                                                event.target.value === 'all'
                                                    ? setSearchOrderType(undefined)
                                                    : setSearchOrderType(
                                                          parseInt(event.target.value, 2)
                                                      )
                                            }
                                        />
                                    </div>
                                    <div className="self-center">
                                        <TypeAhead
                                            placeholder="Search Creditors"
                                            caseSensitive={false}
                                            label="Creditor"
                                            options={formatCreditors()}
                                            onClick={(event) =>
                                                setCreditorId(
                                                    ((event.target as unknown) as { id: string }).id
                                                )
                                            }
                                            onCleared={() => setCreditorId(undefined)}
                                            dark
                                        />
                                    </div>
                                    <div>
                                        <div className="text-base pb-1 text-white">Date From</div>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            disabled={orders.loading}
                                            selected={searchOrderDateFrom}
                                            onChange={(date: Date) => setSearchOrderDateFrom(date)}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-base pb-1 text-white">Date To</div>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            disabled={orders.loading}
                                            selected={searchOrderDateTo}
                                            onChange={(date: Date) => setSearchOrderDateTo(date)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Legend */}
                            <div className="flex justify-end w-full">
                                <div className="flex justify-end w-1/3">
                                    <div
                                        className={`${STATUS.Exported} w-1/6 p-1 text-sm text-center`}
                                    >
                                        Exported
                                    </div>
                                    <div
                                        className={`${STATUS.Sent_To_Supplier} w-1/6 p-1 text-sm text-center`}
                                    >
                                        Sent
                                    </div>
                                    <div
                                        className={`${STATUS.Approved} w-1/6 p-1 text-sm text-center`}
                                    >
                                        Approved
                                    </div>
                                    <div
                                        className={`${STATUS.Draft} w-1/6 p-1 text-sm text-center`}
                                    >
                                        Draft
                                    </div>
                                    <div
                                        className={`${STATUS.Cancelled} w-1/6 p-1 text-sm text-center`}
                                    >
                                        Cancelled
                                    </div>
                                </div>
                            </div>
                            <Table<Order>
                                loading={orders.loading && 'Fetching Orders from server'}
                                error={
                                    orders.error &&
                                    `Failed to fetch Order list from the server (${orders.error})`
                                }
                                data={projectId.data ? orders.data?.items : []}
                                columns={[
                                    {
                                        key: 'project',
                                        title: 'Project',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) => {
                                            return `${order.requisition?.project?.jobCode} - ${order.requisition?.project?.name}`;
                                        },
                                    },
                                    {
                                        key: 'reqNo',
                                        title: 'Req. No',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) => {
                                            const prefix =
                                                order.requisition?.project?.businessUnit
                                                    ?.prefixRequisition || '';
                                            return `${prefix}${order.requisition?.number
                                                .toString()
                                                .padStart(6, '0')}`;
                                        },
                                    },
                                    {
                                        key: 'number',
                                        title: 'Order No',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) => {
                                            const { number, requisition } = order || {};
                                            const prefix =
                                                requisition?.project?.businessUnit?.prefix || '';
                                            return `${prefix}${number
                                                ?.toString()
                                                .padStart(6, '0')}`;
                                        },
                                    },
                                    {
                                        key: 'createdAt',
                                        title: 'Created',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) =>
                                            order.createdAt &&
                                            new Date(order.createdAt).toLocaleDateString('en-ZA'),
                                    },
                                    {
                                        key: 'creditor',
                                        title: 'Creditor',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) => {
                                            return order.creditor?.name;
                                        },
                                    },
                                    { key: 'description', title: 'Description' },
                                    {
                                        key: 'trade',
                                        title: 'Trade',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) => {
                                            return order.trade?.description;
                                        },
                                    },
                                    {
                                        key: 'quantityTotal',
                                        title: 'Order Total',
                                        onClick: (order) => toDetail(order.id),
                                        render: (order) => {
                                            return (
                                                <div className="flex justify-between">
                                                    <span>
                                                        {fomatCurrencySymbol(order.currency || '')}
                                                    </span>
                                                    <span>
                                                        {formatCurrencyAmount(
                                                            order.totalAmount || 0
                                                        )}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        key: 'createdBy',
                                        title: 'Created By',
                                        onClick: (order) => toDetail(order.id),
                                        render: (requisition) => {
                                            const chosenUser =
                                                requisition.createdBy &&
                                                users.data?.find(
                                                    (userItem) =>
                                                        userItem.id?.toUpperCase() ===
                                                        requisition.createdBy?.toUpperCase()
                                                );
                                            if (chosenUser) {
                                                return `${chosenUser.firstName} ${chosenUser.lastName}`;
                                            }
                                            return '';
                                        },
                                    },
                                    {
                                        key: 'approvedBy',
                                        title: 'Approved By',
                                        onClick: (order) => toDetail(order.id),
                                        render: (requisition) => {
                                            const chosenUser =
                                                requisition.approvedBy &&
                                                users.data?.find(
                                                    (userItem) =>
                                                        userItem.id?.toUpperCase() ===
                                                        requisition.approvedBy?.toUpperCase()
                                                );
                                            if (chosenUser) {
                                                return `${chosenUser.firstName} ${chosenUser.lastName}`;
                                            }
                                            return '';
                                        },
                                    },
                                    {
                                        key: 'approvedAt',
                                        title: 'Approved At',
                                        render: (order) =>
                                            order.approvedAt
                                                ? new Date(order.approvedAt || '').toLocaleString(
                                                      'en-ZA'
                                                  )
                                                : '',
                                    },
                                    {
                                        key: 'status',
                                        title: 'Status',
                                        onClick: (order) => toDetail(order.id),
                                        render: getStatus,
                                    },

                                    {
                                        key: 'sentAt',
                                        title: 'Sent',
                                        render: (order) => (
                                            <input
                                                type="checkbox"
                                                disabled
                                                checked={order.sentAt !== null}
                                            />
                                        ),
                                    },
                                    {
                                        key: 'download',
                                        title: 'Download',
                                        render: getDownloadStatus,
                                    },
                                ]}
                                getStatus={(order) => getStatusClasses(order)}
                            />
                            {orders.data && orders.data.totalCount > PAGE_SIZE && (
                                <Pager
                                    onNextPage={handleNextPage}
                                    onPreviousPage={handlePreviousPage}
                                    skip={skip}
                                    pageSize={PAGE_SIZE}
                                    objectList={orders.data}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
