/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

export function TableData({
    id,
    children,
    onClick,
}: {
    id?: string;
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
    const clickEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick !== undefined) {
            onClick(event);
        }
    };

    return (
        <td id={id} className="border p-1 text-xs" onClick={(e) => clickEvent(e)}>
            {children}
        </td>
    );
}
