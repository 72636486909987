import React from 'react';
import { Tab } from './Tab';

export function TabsContainer({
    children,
    options,
    selectedTab,
    onClick,
}: {
    children?: React.ReactNode;
    selectedTab: string;
    options: Tab[];
    onClick?: (tab: string) => void;
}) {
    const selected =
        'pt-2 pb-2 pl-4 pr-4 border-t border-l border-r text-center border-gray-400 rounded-t-md  bg-gray-200';
    const deselected =
        'pt-2 pb-2 pl-4 pr-4 text-center border-t border-l border-r rounded-t-md border-white hover:border-gray-200';

    return (
        <div>
            <ul className="flex cursor-pointer border-b border-white">
                {options.map((o, i) => {
                    return (
                        <li key={i} className={o.label === selectedTab ? selected : deselected}>
                            <button
                                className=""
                                id={o.label}
                                type="button"
                                onClick={onClick && (() => onClick(o.id))}
                            >
                                {o.label}
                            </button>
                        </li>
                    );
                })}
            </ul>
            <div>{children}</div>
        </div>
    );
}
