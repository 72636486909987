import React from 'react';
import clsx from 'clsx';

export default function TextBoxInput({
    label,
    disabled,
    value,
    name,
    height,
    onChange,
    error,
    ...props
}: {
    label?: React.ReactNode;
    disabled?: boolean;
    value: string | undefined | number | null;
    name?: string;
    height?: string;
    error?: string | undefined | boolean;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) {
    return (
        <>
            <label className={clsx('relative w-full font-normal mb-0 ', props)}>
                {label && <div className="mb-1 whitespace-nowrap">{label}</div>}
                <div
                    className={clsx(
                        `relative border border-gray rounded-sm ${height || 'h-32'}
                        `,
                        {
                            'focus-within:ring-1 focus-within:ring-black': !error,
                            'ring-2 focus-within:ring-red': error,
                            'bg-white': !disabled,
                            'bg-gray-200': disabled,
                        }
                    )}
                >
                    <textarea
                        className={clsx(
                            `w-full h-full resize-none focus:outline-none bg-transparent p-1`,
                            { 'cursor-not-allowed': disabled }
                        )}
                        name={name}
                        onChange={onChange}
                        value={value || ''}
                        maxLength={200}
                        disabled={disabled}
                    />
                </div>
                {error && <p className="text-red mt-1">{error}</p>}
            </label>
        </>
    );
}
