import { domain } from '../d-man';
import { DeliveryDTO } from './Delivery';
import { components } from './api/specs';
import { UpsertCallOffWithoutOrderIdDTO } from './Calloff';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type OrderDTO = schemas['CreateOrderWithoutUserIdDTO'];
export type Order = schemas['Order'];

export function useCreateOrder() {
    return domain.usePost<OrderDTO, Order>('/orders');
}

export function useCreateOrderDelivery(id: string) {
    return domain.usePost<DeliveryDTO, null>(`/orders/${id}/delivery`);
}

export function useCreateOrderCallOff(id: string) {
    return domain.usePost<UpsertCallOffWithoutOrderIdDTO, null>(`/orders/${id}/calloff`);
}

export function useGetOrder(id: string) {
    return domain.useGet<Order>(`/orders/${id}`);
}

export function useGetOrders(
    skip?: number,
    take?: number,
    number?: number,
    projectId?: string,
    businessUnitId?: string,
    creditorId?: string,
    cancelled?: boolean,
    approved?: boolean,
    exported?: boolean,
    lazy?: boolean,
    dateFrom?: Date,
    dateTo?: Date,
    tradeId?: string,
    orderType?: number
) {
    return domain.useGet<ObjectList<Order>>('/orders', {
        requestConfig: {
            queryParams: {
                skip,
                take,
                number,
                projectId,
                businessUnitId,
                creditorId,
                cancelled,
                approved,
                exported,
                dateFrom,
                dateTo,
                tradeId,
                orderType,
            },
        },
        lazy,
    });
}

export function useApproveOrder(orderId: string) {
    return domain.usePost<null, null>(`/orders/${orderId}/approve`);
}

export function useCancelOrder(orderId: string) {
    return domain.usePost<null, null>(`/orders/${orderId}/cancel`);
}

export function useSendOrder(orderId: string) {
    return domain.usePost<{ email: string; deliveryAddressId: string }, null>(
        `/orders/${orderId}/send`,
        {
            transformRequest: (request: { email: string; deliveryAddressId: string }) => ({
                queryParams: { email: request.email, deliveryAddressId: request.deliveryAddressId },
            }),
        }
    );
}
