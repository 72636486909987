import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Nav from '../components/Nav';
import { Route } from '../components/Nav/Nav';
import { useCurrentProjectId, useUser } from '../queries/Accounts';
import { useGetPermissions } from '../queries/Permissions';

const USER_ADMIN = 'level6';
const CREDITOR_ADMIN = 'creditor_admin';
const CREDITOR_VIEWER = 'creditor_viewer';

export default function MainLayout({
    children,
}: // ...props
RouteComponentProps & { children: React.ReactNode }) {
    const user = useUser();
    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);

    /**
     * Determines the nav items based on the users role claim
     */
    const routes = () => {
        let paths: Route[] = [];
        if (user?.role && user?.role !== '' && user?.role.length > 0) {
            paths = paths.concat([
                {
                    path: '/',
                    name: 'Dashboard',
                    parent: false,
                    children: [],
                },
                /*  {
                    path: '/subbies',
                    name: 'Subbies',
                    parent: true,
                    children: [],
                },
                {
                    path: '/overheads',
                    name: 'Overheads',
                    parent: true,
                    children: [],
                },  */
            ]);
        }

        if (
            !getPermissions.data?.items.find((p) => p.code === 'Project_Show_Requisitions_Screen')
                ?.hasAccess
        ) {
            paths.push({
                path: '/creditors',
                name: 'Creditors',
                parent: true,
                children: [{ path: '/orders', name: 'Orders' }],
            });
        } else {
            paths.push({
                path: '/creditors',
                name: 'Creditors',
                parent: true,
                children: [
                    { path: '/requisitions', name: 'Requisitions' },
                    { path: '/orders', name: 'Orders' },
                ],
            });
        }

        if (
            getPermissions.data?.items.find((p) => p.code === 'Project_Show_Creditor_Recon')
                ?.hasAccess
        ) {
            paths.push({
                path: '/recons',
                name: 'Recons',
                parent: true,
                children: [{ path: '/deliveries', name: 'Deliveries' }],
            });
        }

        if (user?.role === USER_ADMIN || user?.role?.includes(USER_ADMIN)) {
            paths.push({
                path: '/admin',
                name: 'System Admin',
                parent: true,
                children: [
                    { path: '/companies', name: 'Companies' },
                    { path: '/business-units', name: 'Business Units' },
                    { path: '/projects', name: 'Projects' },
                    { path: '/creditors', name: 'Creditors' },
                    { path: '/units', name: 'Units' },
                    { path: '/trades', name: 'Trades / CostCodes' },
                    { path: '/materials', name: 'Materials' },
                    { path: '/users', name: 'Users' },
                ],
            });
        } else if (
            user?.role === CREDITOR_ADMIN ||
            user?.role?.includes(CREDITOR_ADMIN) ||
            user?.role === CREDITOR_VIEWER ||
            user?.role?.includes(CREDITOR_VIEWER)
        ) {
            paths.push({
                path: '/admin',
                name: 'System Admin',
                parent: true,
                children: [{ path: '/creditors', name: 'Creditors' }],
            });
        }

        return paths;
    };

    return (
        <div>
            <Nav routes={routes()}>{children}</Nav>
        </div>
    );
}
