/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { MdOutlineRequestQuote } from 'react-icons/md';
import { TbTruckDelivery } from 'react-icons/tb';
import { format, subDays, addWeeks } from 'date-fns';
import axios from 'axios';
import Cookies from 'js-cookie';
import DashboardCard from './DashboardCard';
import { ReactComponent as Requisitions } from './icons/requisitions.svg';
// import { ReactComponent as Box } from './icons/inbox.svg';
import { ReactComponent as QuestionMark } from './icons/question-mark.svg';
// import { ReactComponent as Truck } from './icons/truck.svg';
import { Delivery, useGetDeliveries } from '../../queries/Delivery';
import { useGetOrders } from '../../queries/Orders';
import { useGetRequisitions } from '../../queries/Requisitions';
import { useCurrentBusinessUnitId, useCurrentProjectId, useUser } from '../../queries/Accounts';
import { Permission, useGetPermissions } from '../../queries/Permissions';
import Loader from '../../components/Loader';
import { ObjectList } from '../../queries/ObjectList';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Dashboard(props: RouteComponentProps) {
    const user = useUser();
    const projectId = useCurrentProjectId();
    const businessUnitId = useCurrentBusinessUnitId();

    const getPermissions = useGetPermissions('PROJECT', projectId.data);

    const [permissions, setPermissions] = useState(undefined as ObjectList<Permission> | undefined);
    const [lazy, setLazy] = useState(true);
    const [loading, setLoading] = useState(true);
    const [payByDeliveries, setPayByDeliveries] = useState<ObjectList<Delivery>>();

    const getDayBefore = () => {
        const today = new Date();
        const yesterday = subDays(today, 1);
        return format(yesterday, 'yyyy-MM-dd');
    };

    const getNextWeekDate = () => {
        const today = new Date();
        const nextWeek = addWeeks(today, 1);
        return format(nextWeek, 'yyyy-MM-dd');
    };

    const requisitions = useGetRequisitions(
        0,
        1,
        projectId.data,
        // eslint-disable-next-line no-nested-ternary
        false,
        false,
        undefined,
        undefined,
        false,
        lazy
    ); // only show approved if control clerk

    useEffect(() => {
        const getDeliveriesToBePaid = async () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${Cookies.get('access_token')}` || '',
                },
                params: {
                    skip: 0,
                    take: 1,
                    isDisputed: false,
                    projectId: projectId.data,
                    businessUnitId: businessUnitId.data,
                    exported: false,
                    paidDateFrom: new Date(getDayBefore()),
                    paidDateTo: new Date(getNextWeekDate()),
                },
            };
            const paidDeliveryResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}deliveries`,
                config
            );
            setPayByDeliveries(paidDeliveryResponse.data);
        };
        getDeliveriesToBePaid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions, projectId.data, lazy]);

    const requisitionsRef = useRef(requisitions);

    const orders = useGetOrders(
        0,
        1,
        undefined,
        projectId.data,
        businessUnitId.data,
        undefined,
        false,
        false,
        // permissions &&
        //     permissions?.items.find((p) => p.code === 'Project_Show_Unapproved_Orders')?.hasAccess
        //     ? undefined
        //     : false,
        permissions &&
            permissions?.items.find((p) => p.code === 'Project_Show_Exported_Orders')?.hasAccess
            ? undefined
            : false,
        lazy
    );

    const ordersRef = useRef(orders);

    const deliveries = useGetDeliveries(
        0,
        1,
        undefined,
        undefined,
        undefined,
        projectId.data,
        businessUnitId.data,
        undefined,
        permissions &&
            permissions?.items.find((p) => p.code === 'Project_Show_Exported_Deliveries')?.hasAccess
            ? undefined
            : false,
        lazy,
        new Date(getDayBefore())
    );

    const deliveriesRef = useRef(deliveries);

    const disputes = useGetDeliveries(
        0,
        1,
        undefined,
        undefined,
        undefined,
        projectId.data,
        businessUnitId.data,
        true,
        permissions &&
            permissions?.items.find((p) => p.code === 'Project_Show_Exported_Deliveries')?.hasAccess
            ? undefined
            : false,
        lazy
    );
    const disputesRef = useRef(disputes);

    const [paidDeliveriesCount, setPaidDeliveriesCount] = useState(0);
    const [orderCount, setOrderCount] = useState(0);
    const [deliveryCount, setDeliveryCount] = useState(0);
    const [disputeCount, setDisputeCount] = useState(0);
    const [reqCount, setReqCount] = useState(0);

    useEffect(() => {
        if (projectId.data && businessUnitId.data && user && getPermissions.data) {
            setPermissions(getPermissions.data);
            setLazy(false);
        }
    }, [projectId.data, businessUnitId.data, user, getPermissions.data]);

    useEffect(() => {
        if (permissions && !lazy) {
            requisitionsRef.current.reset();
            ordersRef.current.reset();
            deliveriesRef.current.reset();
            disputesRef.current.reset();
            setLoading(false);
        }
    }, [permissions, lazy]);

    useEffect(() => {
        setPaidDeliveriesCount(0);
        setOrderCount(0);
        setOrderCount(0);
        setDisputeCount(0);
        setReqCount(0);
    }, []);

    useEffect(() => {
        if (projectId.data && businessUnitId.data && user && getPermissions.data) {
            setReqCount(requisitions.data?.totalCount || 0);
        }
    }, [projectId.data, businessUnitId.data, user, requisitions.data, getPermissions.data]);

    useEffect(() => {
        if (projectId.data && businessUnitId.data && user && getPermissions.data) {
            setOrderCount(orders.data?.totalCount || 0);
        }
    }, [projectId.data, businessUnitId.data, user, orders.data, getPermissions.data]);

    useEffect(() => {
        if (projectId.data && businessUnitId.data && user && getPermissions.data) {
            setDeliveryCount(deliveries.data?.totalCount || 0);
            setPaidDeliveriesCount(payByDeliveries?.totalCount || 0);
        }
    }, [
        projectId.data,
        businessUnitId.data,
        user,
        deliveries.data,
        getPermissions.data,
        payByDeliveries?.totalCount,
    ]);

    useEffect(() => {
        if (projectId.data && businessUnitId.data && user && getPermissions.data) {
            setDisputeCount(disputes.data?.totalCount || 0);
        }
    }, [projectId.data, businessUnitId.data, user, disputes.data, getPermissions.data]);

    let cards = [];

    if (
        permissions?.items.find((p) => p.code === 'Project_Show_Requisitions_Dashboard')?.hasAccess
    ) {
        cards.push({
            title: 'Requisitions',
            count: reqCount,
            icon: Requisitions,
            url: reqCount ? '/requisitions?status=draft' : '/requisitions',
            visible: !requisitions.loading,
        });
    }

    if (permissions?.items.find((p) => p.code === 'Project_Show_Deliveries_Dashboard')?.hasAccess) {
        cards.push({
            title: 'Recon Deliveries',
            count: deliveryCount,
            icon: TbTruckDelivery,
            url: deliveryCount ? `/deliveries?dateFrom=${getDayBefore()}` : '/deliveries',
            visible: !deliveries.loading,
        });
        cards.push({
            title: 'Disputes',
            count: disputeCount,
            icon: QuestionMark,
            url: disputeCount ? '/deliveries?isDisputed=true' : '/deliveries',
            visible: !disputes.loading,
        });
        cards.push({
            title: 'Unpaid Deliveries',
            count: paidDeliveriesCount,
            icon: QuestionMark,
            url: paidDeliveriesCount
                ? `/deliveries?datePaidFrom=${getDayBefore()}&datePaidTo=${getNextWeekDate()}&isExported=false`
                : '/deliveries',
            visible: !deliveries.loading,
        });
    }

    cards = cards.concat([
        {
            title: 'Orders',
            count: orderCount,
            icon: MdOutlineRequestQuote,
            url: orderCount ? '/orders?status=draft' : '/orders',
            visible: !orders.loading,
        },
    ]);

    return (
        <div>
            <div className="flex p-6 space-x-6">
                {(loading || !permissions) && <Loader message="Loading dashboard..." />}
                {user &&
                    !loading &&
                    projectId.data &&
                    businessUnitId.data &&
                    permissions && // need permissions as well
                    cards.map((card, i) => {
                        const { title, count, icon, url, visible } = card;
                        return (
                            <DashboardCard
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                title={title}
                                count={count}
                                Icon={icon}
                                url={url}
                                visible={visible}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
