import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useUser } from './queries/Accounts';
import { useLogout } from './d-man';

export default function PrivateRoute({
    children,
    Layout,
    ...props
}: RouteComponentProps & {
    children: React.ReactNode;
    Layout?: (
        props: RouteComponentProps & {
            children: React.ReactNode;
        }
    ) => JSX.Element;
}) {
    const user = useUser();
    const logout = useLogout();

    useEffect(() => {
        const expired = (() => {
            if (!user) return false;
            const { exp } = user;
            if (!exp) return false;
            return new Date().getTime() / 1000 >= exp;
        })();

        if ((!user || expired || !user.role) && props.navigate) {
            logout();
            props.navigate(
                `/login?redirect=${props?.location?.pathname as string}${
                    (props?.location?.search as string) || ''
                }`
            );
        }
    }, [logout, props, user]);

    if (!user) {
        // loading
        return null;
    }

    if (Layout) {
        return <Layout {...props}>{children}</Layout>;
    }

    return <>{children}</>;
}
