import { RouteComponentProps, useParams } from '@reach/router';
import { BlobServiceClient } from '@azure/storage-blob';
import React, { useState } from 'react';
import * as uuid from 'uuid';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import FileUpload from '../../components/FileUpload';
import {
    Delivery,
    DeliveryItem,
    useApproveDelivery,
    useCreateDisputeDelivery,
    useGetDelivery,
    UpsertDeliveryFileDTO,
    useUploadDeliveryFile,
} from '../../queries/Delivery';
import { forceDownload, handleWebDownload } from '../../utils/Utils';
import { domain } from '../../d-man';
import { Tab, TabsContainer } from '../../components/Tabs';
import { DisputeMessage, useResolveDeliveryDispute } from '../../queries/Disputes';
import { useGetPermissions } from '../../queries/Permissions';
import Loader from '../../components/Loader';
import { TrashCan } from '../../components/Icons';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function DeliveryDetail(props: RouteComponentProps) {
    const { navigate } = props;
    const params = useParams();
    const getId = () => params.id;

    const [openDeliveryFilesModal, setOpenDeliveryFilesModal] = useState(false);
    const [openReturnModal, setOpenReturnModal] = useState(false);
    const [openCreateDisputeModal, setOpenCreateDisputeModal] = useState(false);

    const delivery = useGetDelivery(getId());
    const deliveryApprove = useApproveDelivery(getId());
    const deliveryDisputeCreate = useCreateDisputeDelivery(getId());
    const deliveryDisputeResolve = useResolveDeliveryDispute();
    const [tabs] = useState([
        { label: 'Materials', id: 'Materials' },
        { label: 'Disputes', id: 'Disputes' },
    ] as Tab[]);
    const [selectedTab, setSelectedTab] = useState('Materials');

    const [disputeErrorMessage, setDisputeErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const [deliveryDisputeDateFrom, setDeliveryDisputeDateFrom] = useState(
        undefined as Date | undefined
    );
    const [deliveryDisputeDateTo, setDeliveryDisputeDateTo] = useState(
        undefined as Date | undefined
    );

    const [deliveryFiles, setDeliveryFiles] = useState([] as File[]);
    const [openUploadFilesModal, setOpenUploadFilesModal] = useState(false);
    const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const getPermissions = useGetPermissions('DELIVERY', getId());

    const uploadFile = useUploadDeliveryFile(getId());

    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Delivery_Show_Delivery_Details_Screen')
            ?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const handleReturnModalOpen = () => {
        setOpenReturnModal(true);
    };

    const handleReturnModalClose = () => {
        setOpenReturnModal(false);
    };

    const handleDeliveryFilesModalOpen = () => {
        setOpenDeliveryFilesModal(true);
    };

    const handleDeliveryFilesModalClose = () => {
        setOpenDeliveryFilesModal(false);
    };

    const handleCreateDisputeModalOpen = () => {
        setOpenCreateDisputeModal(true);
    };

    const handleCreateDisputeModalClose = () => {
        setOpenCreateDisputeModal(false);
    };

    const handleUploadFilesModalOpen = () => {
        setOpenUploadFilesModal(true);
    };

    const handleUploadFilesModalClose = () => {
        setOpenUploadFilesModal(false);
    };

    const updateUploadedFiles = (files: File[]) => {
        setDeliveryFiles(files);
    };

    const toOrderDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/orders/${id}`);
        }
    };

    const toCallOffDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/calloffs/${id}`);
        }
    };

    const handleExportDownload = async (endpoint: string) => {
        setIsLoading(true);
        const [, data, response] = await domain
            .get(endpoint, {
                requestConfig: {
                    responseType: 'blob', // important
                },
            })
            .execute();
        if (!data) return;
        handleWebDownload(data, response);
        setIsLoading(false);
        delivery.reset();
    };

    const getStatus = (deliveryStatus: Delivery) => {
        if (deliveryStatus.exportedAt !== null || deliveryStatus.exportedBy !== null) {
            return 'Exported';
        }
        if (deliveryStatus.approvedAt !== null || deliveryStatus.approvedBy !== null) {
            return 'Approved';
        }
        return 'Draft';
    };

    const postCreateDispute = async () => {
        if (!message) {
            setDisputeErrorMessage('Please capture all the fields above');
        } else {
            setIsLoading(true);
            let deliveryDisputeId: string | undefined;
            if (
                delivery &&
                delivery.data &&
                delivery.data.disputes &&
                delivery.data.disputes.some((i) => !i.resolvedAt && !i.resolvedBy)
            ) {
                deliveryDisputeId = delivery.data.disputes.filter(
                    (i) => !i.resolvedAt && !i.resolvedBy
                )[0].id;
            }

            await deliveryDisputeCreate.execute({ message, deliveryDisputeId });
            if (deliveryDisputeCreate.error) {
                // Display create order error here
                setDisputeErrorMessage(deliveryDisputeCreate.error);
            } else {
                handleCreateDisputeModalClose();
            }
            setIsLoading(false);
            setMessage('');
            delivery.reset();
        }
    };

    const handleDownload = async (file: {
        id?: string | undefined;
        name?: string | undefined;
        requisitionId?: string | null | undefined;
        deliveryId?: string | null | undefined;
        returnId?: string | null | undefined;
        blobId?: string | null | undefined;
        uploadedAt?: string | null | undefined;
    }) => {
        if (file && file.blobId) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            const blockBlobClient = containerClient.getBlockBlobClient(file.blobId);
            // eslint-disable-next-line no-await-in-loop
            const uploadBlobResponse = await blockBlobClient.download();
            if (uploadBlobResponse.requestId) {
                const blobby = (await uploadBlobResponse.blobBody) || new Blob();
                const url = window.URL.createObjectURL(new Blob([blobby]));

                forceDownload(url, file.name || '');
            }
        }
    };

    const uploadFiles = async () => {
        const blobs: { id: string; name: string }[] = [];
        if (deliveryFiles) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            // eslint-disable-next-line no-restricted-syntax
            for (const file of deliveryFiles) {
                const id = uuid.v4();
                const blockBlobClient = containerClient.getBlockBlobClient(id);
                // eslint-disable-next-line no-await-in-loop
                const uploadBlobResponse = await blockBlobClient.uploadData(file);
                if (uploadBlobResponse.requestId) {
                    blobs.push({
                        id,
                        name: file.name,
                    });
                }
            }
        }
        return blobs;
    };

    const postUploadFile = async () => {
        setUploadFileErrorMessage('');
        setIsLoading(true);
        if (deliveryFiles.length <= 0) {
            setUploadFileErrorMessage('You must upload at least 1 file');
            setIsLoading(false);
        } else {
            const blobs = await uploadFiles();
            if (blobs.length !== deliveryFiles.length) {
                setUploadFileErrorMessage('Not all files were uploaded successfully');
                setIsLoading(false);
                return;
            }
            const data: UpsertDeliveryFileDTO = {
                blobs,
            };

            await uploadFile.execute(data);
            // I cant get this error to work!
            if (uploadFile.error) {
                // Display create order error here
            } else {
                handleUploadFilesModalClose();
                setIsLoading(false);
            }
            delivery.reset();
        }
    };

    const deleteFile = async (id: string) => {
        const remove = domain.delete(`/files/${id}`);
        await remove.execute();
        delivery.reset();
    };

    const getLineItemNumber = (row: DeliveryItem) => {
        if (!row?.id) return '';
        const lineItemIndex = delivery?.data?.items?.findIndex((item) => {
            if (!item.lineItem || !row.lineItem) return '';
            return item?.lineItem.id === row?.lineItem.id;
        });

        return !lineItemIndex || lineItemIndex === -1 ? 1 : lineItemIndex + 1;
    };

    const renderDelivery = () => {
        if (!delivery || delivery.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="w-8 h-8 animate-spin text-orange-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">Fetching Delivery from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (delivery.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="text-red-500 w-8 h-8 animate-pulse"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">
                                Failed to fetch Delivery from the server ({delivery.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (delivery.data) {
            const { note, createdAt, orderId, callOffId, payByDate } = delivery.data;
            return (
                <>
                    <div className="text-l p-6 font-bold">Delivery Information</div>
                    <hr />
                    <div className="flex p-6 justify-between">
                        <div className="flex gap-4">
                            <div className="mb-5">
                                <Input label="Delivery Note" value={note} type="text" disabled />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Created"
                                    value={
                                        createdAt ? format(new Date(createdAt), 'yyyy-MM-dd') : ''
                                    }
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Status"
                                    value={getStatus(delivery.data)}
                                    type="text"
                                    disabled
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Pay by"
                                    value={
                                        payByDate ? format(new Date(payByDate), 'yyyy-MM-dd') : ''
                                    }
                                    type="text"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="flex">
                            {orderId && (
                                <div className="mb-5 mt-2">
                                    <Button onClick={() => toOrderDetail(orderId)}>
                                        Go To Order
                                    </Button>
                                </div>
                            )}
                            {callOffId && (
                                <div className="mb-5 mt-2">
                                    <Button onClick={() => toCallOffDetail(callOffId)}>
                                        Go To CallOff
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className="flex gap-4 px-6 pb-6">
                        {delivery.data?.files && delivery.data?.files.length > 0 && (
                            <div className="mt-5">
                                <Button onClick={handleDeliveryFilesModalOpen}>
                                    Delivery Files
                                </Button>
                            </div>
                        )}
                        {delivery.data?.returnFiles && delivery.data?.returnFiles.length > 0 && (
                            <div className="mt-5">
                                <Button onClick={handleReturnModalOpen}>Returns</Button>
                            </div>
                        )}
                        {getPermissions.data?.items.find((p) => p.code === 'Delivery_Approval')
                            ?.hasAccess &&
                            delivery.data.approvedAt === null &&
                            delivery.data.approvedBy === null && (
                                <div className="mt-5">
                                    <Button
                                        onClick={() => {
                                            deliveryApprove.execute().then(delivery.reset);
                                        }}
                                    >
                                        Approve
                                    </Button>
                                </div>
                            )}
                        {getPermissions.data?.items.find((p) => p.code === 'Delivery_Upload_Files')
                            ?.hasAccess &&
                            delivery.data && (
                                <div className="mt-5">
                                    <Button onClick={handleUploadFilesModalOpen}>
                                        Upload Files
                                    </Button>
                                </div>
                            )}
                        {((delivery.data.exportedAt == null && delivery.data.exportedBy == null) ||
                            getPermissions.data?.items.find(
                                (p) => p.code === 'Delivery_Export_Override'
                            )?.hasAccess) &&
                            delivery.data.approvedAt !== null &&
                            delivery.data.approvedBy !== null && (
                                <div className="mt-5">
                                    <Button
                                        onClick={() =>
                                            handleExportDownload(
                                                `/deliveries/${delivery.data?.id}/export`
                                            )
                                        }
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Loading' : 'Export'}
                                    </Button>
                                </div>
                            )}
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <>
            {!getPermissions.data && <Loader message="Loading delivery..." />}
            {getPermissions.data && delivery.data && (
                <div className="">
                    <Modal
                        title="Delivery Files"
                        subtitle="Download delivery files"
                        onClose={handleDeliveryFilesModalClose}
                        isOpen={openDeliveryFilesModal}
                    >
                        <div className="flex justify-between">
                            <Table<{
                                id?: string | undefined;
                                name?: string | undefined;
                                requisitionId?: string | null | undefined;
                                deliveryId?: string | null | undefined;
                                returnId?: string | null | undefined;
                                blobId?: string | null | undefined;
                                uploadedAt?: string | null | undefined;
                            }>
                                loading={delivery.loading && 'Fetching Files from server'}
                                error={
                                    delivery.error &&
                                    `Failed to fetch File list from the server (${delivery.error})`
                                }
                                data={delivery.data?.files?.filter((file) => !file.isDeleted) || []}
                                // onClick={(file) => handleDownload(file)}
                                columns={[
                                    {
                                        key: 'name',
                                        title: 'File Name',
                                        onClick: (file) => handleDownload(file),
                                    },
                                    {
                                        key: 'uploadedAt',
                                        title: 'Created At',
                                        render: (file) =>
                                            format(new Date(file.uploadedAt || ''), 'yyyy-MM-dd'),
                                        onClick: (file) => handleDownload(file),
                                    },
                                    {
                                        key: 'delete',
                                        title: '',
                                        render: (file) => (
                                            <TrashCan
                                                className="cursor-pointer pl-2"
                                                id={file.id}
                                                onClick={() => deleteFile(file.id || '')}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <div />
                    </Modal>
                    <Modal title="Return" onClose={handleReturnModalClose} isOpen={openReturnModal}>
                        <div className="mb-5">
                            <Input
                                label="Note"
                                value={delivery.data?.returnNote}
                                type="text"
                                disabled
                            />
                        </div>
                        <div className="flex justify-between">
                            <Table<{
                                id?: string | undefined;
                                name?: string | undefined;
                                requisitionId?: string | null | undefined;
                                deliveryId?: string | null | undefined;
                                returnId?: string | null | undefined;
                                blobId?: string | null | undefined;
                                uploadedAt?: string | null | undefined;
                            }>
                                loading={delivery.loading && 'Fetching Files from server'}
                                error={
                                    delivery.error &&
                                    `Failed to fetch File list from the server (${delivery.error})`
                                }
                                data={delivery.data?.returnFiles || []}
                                onClick={(file) => handleDownload(file)}
                                columns={[
                                    {
                                        key: 'name',
                                        title: 'File Name',
                                    },
                                    {
                                        key: 'uploadedAt',
                                        title: 'Created At',
                                        render: (file) =>
                                            format(new Date(file.uploadedAt || ''), 'yyyy-MM-dd'),
                                    },
                                ]}
                            />
                        </div>
                        <div />
                    </Modal>
                    {delivery.data && (
                        <Modal
                            title="Add Dispute"
                            // subtitle={`${order.data.number.toString().padStart(6, '0')}`}
                            onClose={handleCreateDisputeModalClose}
                            isOpen={openCreateDisputeModal}
                            onAction={postCreateDispute}
                            actionText={isLoading ? 'Loading' : ''}
                        >
                            <div className="ml-5">
                                <div>
                                    <Input
                                        label="Dispute Message"
                                        placeholder="Add Message Here"
                                        value={message}
                                        type="text"
                                        onChange={(event) => setMessage(event.target.value)}
                                    />
                                </div>
                                <div className="flex mb-5 text-red-500">{disputeErrorMessage}</div>
                            </div>
                        </Modal>
                    )}
                    <Modal
                        title="Upload Files"
                        onClose={handleUploadFilesModalClose}
                        isOpen={openUploadFilesModal}
                        onAction={postUploadFile}
                        actionText={isLoading ? 'Loading...' : ''}
                    >
                        <div className="flex justify-between">
                            <div className="ml-5">
                                <div className="mb-5">
                                    <FileUpload updateFilesCb={updateUploadedFiles} multiple />
                                </div>
                            </div>
                        </div>
                        <div className="flex mb-5 text-red-500">{uploadFileErrorMessage}</div>
                    </Modal>

                    <div className="bg-gray-400 border rounded-md h-full m-4">
                        <div className="p-5">
                            <TabsContainer
                                selectedTab={selectedTab}
                                options={tabs}
                                onClick={setSelectedTab}
                            >
                                {selectedTab === 'Materials' && (
                                    <div className="w-full h-full mt-4">
                                        <Table<DeliveryItem>
                                            loading={
                                                delivery.loading &&
                                                'Fetching Deliveries from server'
                                            }
                                            error={
                                                delivery.error &&
                                                `Failed to fetch Delivery list from the server (${delivery.error})`
                                            }
                                            data={delivery.data?.items}
                                            columns={[
                                                {
                                                    key: '',
                                                    title: 'No',
                                                    render: (deliveryItem) => {
                                                        return getLineItemNumber(deliveryItem);
                                                    },
                                                },
                                                {
                                                    key: 'description',
                                                    title: 'Description',
                                                    render: (deliveryItem) => {
                                                        const { description } =
                                                            deliveryItem.lineItem || {};
                                                        return description;
                                                    },
                                                },
                                                {
                                                    key: 'quantity',
                                                    title: 'Delivered',
                                                },
                                                {
                                                    key: 'returnedQuantity',
                                                    title: 'Returned',
                                                },
                                                {
                                                    key: 'costCode',
                                                    title: 'Cost Code',
                                                    render: (deliveryItem) => {
                                                        const { costCode } =
                                                            deliveryItem.lineItem || {};
                                                        return costCode?.name;
                                                    },
                                                },
                                            ]}
                                        />
                                    </div>
                                )}
                                {selectedTab === 'Disputes' && (
                                    <div>
                                        <div className="flex justify-between">
                                            <div className="self-center">
                                                <div className="text-sm">Date From</div>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={deliveryDisputeDateFrom}
                                                    onChange={(date: Date) =>
                                                        setDeliveryDisputeDateFrom(date)
                                                    }
                                                />
                                            </div>
                                            <div className="self-center">
                                                <div className="text-sm">Date To</div>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={deliveryDisputeDateTo}
                                                    onChange={(date: Date) =>
                                                        setDeliveryDisputeDateTo(date)
                                                    }
                                                />
                                            </div>
                                            <div className="flex flex-row-reverse mt-5">
                                                {delivery.data &&
                                                    delivery.data.disputes &&
                                                    delivery.data.disputes.some(
                                                        (i) => !i.resolvedAt && !i.resolvedBy
                                                    ) && (
                                                        <Button
                                                            onClick={() =>
                                                                deliveryDisputeResolve
                                                                    .execute({
                                                                        deliveryDisputeId:
                                                                            delivery.data?.disputes?.filter(
                                                                                (i) =>
                                                                                    !i.resolvedAt &&
                                                                                    !i.resolvedBy
                                                                            )[0].id || '',
                                                                    })
                                                                    .then(delivery.reset)
                                                            }
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading ? 'Loading' : 'Resolve'}
                                                        </Button>
                                                    )}
                                                {getPermissions.data?.items.find(
                                                    (p) => p.code === 'Delivery_Dispute'
                                                )?.hasAccess && (
                                                    <Button
                                                        onClick={() =>
                                                            handleCreateDisputeModalOpen()
                                                        }
                                                    >
                                                        Add Dispute
                                                    </Button>
                                                )}
                                            </div>
                                        </div>

                                        {delivery.data && delivery.data.disputes && (
                                            <>
                                                <div className="text-l mt-5">Disputes</div>
                                                <Table<DisputeMessage>
                                                    data={
                                                        delivery.data.disputes
                                                            .filter(
                                                                (d) =>
                                                                    (!deliveryDisputeDateFrom ||
                                                                        new Date(
                                                                            Date.parse(
                                                                                d.createdAt || '0'
                                                                            )
                                                                        ) >=
                                                                            deliveryDisputeDateFrom) &&
                                                                    (!deliveryDisputeDateTo ||
                                                                        new Date(
                                                                            Date.parse(
                                                                                d.createdAt || '0'
                                                                            )
                                                                        ) <= deliveryDisputeDateTo)
                                                            )
                                                            .map((l) => l.disputeMessages)
                                                            .reduce(
                                                                (a, b) => a?.concat(b || []),
                                                                []
                                                            ) || []
                                                    }
                                                    columns={[
                                                        {
                                                            key: 'createdAt',
                                                            title: 'Created At',
                                                            render: (disputeMessage) =>
                                                                format(
                                                                    new Date(
                                                                        disputeMessage.createdAt ||
                                                                            ''
                                                                    ),
                                                                    'yyyy-MM-dd hh:mm:ss'
                                                                ),
                                                        },
                                                        {
                                                            key: 'message',
                                                            title: 'Message',
                                                        },
                                                        {
                                                            key: 'active',
                                                            title: 'Resolved',
                                                            render: (disputeMessage) =>
                                                                delivery?.data?.disputes
                                                                    ?.filter(
                                                                        (i) =>
                                                                            disputeMessage.deliveryDisputeId ===
                                                                            i.id
                                                                    )
                                                                    ?.every(
                                                                        (i) =>
                                                                            i.resolvedAt &&
                                                                            i.resolvedBy
                                                                    )
                                                                    ? 'True'
                                                                    : 'False',
                                                        },
                                                    ]}
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                            </TabsContainer>
                        </div>
                    </div>
                    <div className="bg-gray-400 border rounded-md h-full m-4">
                        {renderDelivery()}
                    </div>
                </div>
            )}
        </>
    );
}
