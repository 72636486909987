import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type Material = {
    id: string;
    category: string;
    description: string;
    tradeId: string;
    tradeCode: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getQueryString = (params: any) => {
    return Object.keys(params)
        .map((b) => (params[b] ? `${b}=${params[b]}` : ''))
        .join('&');
};

export type UpsertMaterialDTO = schemas['UpsertMaterialDTO'];

export function useCreateMaterial() {
    return domain.usePost<UpsertMaterialDTO, null>('/materials');
}

export function useDeleteMaterial() {
    return domain.useDelete('/materials/:id', {
        transformRequest: (request: string) => ({
            urlParams: { id: request },
        }),
    });
}

export function useGetMaterials(
    skip?: number,
    take?: number,
    tradeId?: string,
    tradeCode?: string
) {
    return domain.useGet<ObjectList<Material>>(
        `/materials?${getQueryString({ skip, take, tradeId, tradeCode })}`
    );
}
