import React from 'react';

export default function PageOverlay({ children }: { children: React.ReactNode }) {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700">
            <div className="absolute inset-0 h-full w-full " />
            {children}
            <div className="text-xs flex justify-center absolute inset-x-0 bottom-0 mb-6 text-white">
                © - Raubex PTY
            </div>
        </div>
    );
}
