import { domain } from '../d-man';
import { components } from './api/specs';

type schemas = components['schemas'];

export type UserRoleDTOWithName = schemas['UserRoleDTOWithoutName'];

export type UserRole = {
    userName: string;
    projectId?: string;
    businessUnitId?: string;
    creditorId?: string;
    roleName: string;
};

export type Role = {
    id: string;
    name: string;
    scope: string;
    displayOrder: number;
};

export function useGetRoles() {
    return domain.useGet<Role[]>('/roles');
}

export function useCreateUserRole() {
    return domain.usePost<UserRole, null>('/users/:userName/role', {
        transformRequest: (request: UserRole) => ({
            urlParams: { userName: request.userName },
            data: request,
        }),
    });
}

export function useGetUserRoles() {
    return domain.useGet<UserRole[]>('/userroles');
}

export function useGetUserRolesByRoleDTO(userRole: UserRoleDTOWithName) {
    return domain.useGet<UserRole[]>('/userroles', {
        requestConfig: {
            queryParams: userRole,
        },
    });
}

export function useDeleteUserRoles() {
    return domain.useDelete<null, UserRoleDTOWithName & { userName: string }>(
        '/users/:userName/role',
        {
            transformRequest: (request: UserRoleDTOWithName & { userName: string }) => ({
                urlParams: { userName: request.userName },
                data: request,
            }),
        }
    );
}
