import React from 'react';

export default function CompanyLogo() {
    return (
        <div className="flex justify-center mb-10 mt-10">
            <div className="w-36 h-36">
                <img src="./0.png" alt="" />
                <p className="text-center text-xs">Build Information Control</p>
            </div>
        </div>
    );
}
