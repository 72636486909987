import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { TableData, TableHeader, TableRow } from '../../components/Table';
import { TrashCan, Error, Loader } from '../../components/Icons';
import { Company, useCreateCompany, useGetCompanies } from '../../queries/Companies';
import { domain } from '../../d-man';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Companies(props: RouteComponentProps) {
    const companies = useGetCompanies();

    const companiesRef = useRef(companies);
    const newCompany = useCreateCompany();

    const [isLoading, setIsLoading] = useState(false);

    const [companyErrorMessage, setErrorMessage] = useState('');
    const [name, setCompanyName] = useState('');
    const [displayName, setDisplayName] = useState(undefined as string | undefined | null);
    const [vatNo, setVatNo] = useState(undefined as string | undefined | null);
    const [registrationNo, setRegistrationNo] = useState(undefined as string | undefined | null);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        companiesRef.current.reset();
    }, []);

    useEffect(() => {
        if (newCompany.error) {
            setErrorMessage(newCompany.error || 'Failed to create new company');
        }
    }, [newCompany.error]);

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');
        if (!name) {
            setErrorMessage('Please capture a Company Name');
        } else {
            await newCompany.execute({
                name,
                displayName,
                vatNo,
                registrationNo,
            });
            companies.reset();
        }
        setIsLoading(false);
    };

    const clearFields = () => {
        setErrorMessage('');
        setCompanyName('');
    };

    const deleteCompany = async (id: string | undefined) => {
        const remove = domain.delete(`/companies/${id}`);
        await remove.execute();
        companies.reset();
    };

    const toDetail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.navigate) {
            const { id } = (event.target as unknown) as { id: string };
            props.navigate(`/companies/${id}`);
        }
    };

    const renderCompanies = () => {
        if (companies.loading) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Companies from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (companies.error) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Company list from the server ({companies.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (companies.data) {
            return companies.data.items.map((company: Company) => (
                <TableRow hover>
                    <TableData id={company.id} onClick={(event) => toDetail(event)}>
                        {company.name}
                    </TableData>
                    <TableData id={company.id} onClick={(event) => toDetail(event)}>
                        {company.displayName}
                    </TableData>
                    <TableData id={company.id} onClick={(event) => toDetail(event)}>
                        {company.vatNo}
                    </TableData>
                    <TableData id={company.id} onClick={(event) => toDetail(event)}>
                        {company.registrationNo}
                    </TableData>
                    <TableData>
                        <TrashCan
                            className="cursor-pointer flex items-center"
                            id={company.id}
                            onClick={() => deleteCompany(company.id)}
                        />
                    </TableData>
                </TableRow>
            ));
        }
        return null;
    };

    return (
        <div className="flex">
            <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">
                <div className="text-sm p-3 font-bold">Add New Company</div>
                <hr />
                <div className="p-3">
                    <div className="mb-5">
                        <Input
                            label="Name"
                            value={name}
                            type="text"
                            onChange={(event) => setCompanyName(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Display Name"
                            value={displayName || ''}
                            type="text"
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Vat Number"
                            value={vatNo || ''}
                            type="text"
                            onChange={(e) => setVatNo(e.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Registration Number"
                            value={registrationNo || ''}
                            type="text"
                            onChange={(e) => setRegistrationNo(e.target.value)}
                        />
                    </div>
                </div>
                <hr />
                <div className="p-3">
                    <div className="flex mb-5 text-red-500">{companyErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2">
                            <Button onClick={clearFields}>Clear</Button>
                        </div>
                        <div>
                            <Button onClick={post}>
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-3/4 h-full m-4">
                <table className="w-full table-auto bg-white">
                    <thead>
                        <TableRow>
                            <TableHeader>Company Name</TableHeader>
                            <TableHeader>Display Name</TableHeader>
                            <TableHeader>VAT No</TableHeader>
                            <TableHeader>Registration No</TableHeader>
                            <TableHeader />
                        </TableRow>
                    </thead>
                    <tbody>{renderCompanies()}</tbody>
                </table>
            </div>
        </div>
    );
}
