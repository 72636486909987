// import * as uuid from 'uuid';
import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type Requisition = schemas['Requisition'];
export type RequisitionItem = schemas['LineItem'];
export type EditableRequisitionItem = RequisitionItem & { editing?: boolean };
export type RequisitionItemDTO = schemas['UpsertLineItemWithoutReqIdDTO'];
export type CreateRequisitionDTO = schemas['CreateRequisitionWithoutUserIdDTO'];
export type UpdateRequisitionDTO = schemas['UpdateRequisitionWithoutUserIdDTO'];
export type SendRFQDTO = schemas['SendRFQDTO'];
export type UpsertDocumentDTO = schemas['UpsertDocumentDTO'];

export type AddNoteDTO = {
    note: string;
    type: string;
    requisitionId: string;
};

export function useGetRequisitions(
    skip?: number,
    take?: number,
    projectId?: string,
    isApproved?: boolean,
    isReviewRequested?: boolean,
    number?: number,
    isCompleted?: boolean,
    cancelled?: boolean,
    lazy?: boolean,
    dateFrom?: Date,
    dateTo?: Date,
    tradeId?: string,
    payBy?: Date
) {
    return domain.useGet<ObjectList<Requisition>>('/requisitions', {
        requestConfig: {
            queryParams: {
                skip,
                take,
                projectId,
                isApproved,
                isReviewRequested,
                number,
                isCompleted,
                cancelled,
                dateFrom,
                dateTo,
                tradeId,
                payBy,
            },
        },
        lazy,
    });
}

export function useGetRequisition(id: string) {
    return domain.useGet<Requisition>(`/requisitions/${id}`);
}

export function useCreateRequisition() {
    return domain.usePost<CreateRequisitionDTO, Requisition>('/requisitions', {
        storeLocation: {
            domain: 'default',
        },
    });
}

export function useUpdateRequisition(id: string) {
    return domain.usePut<UpdateRequisitionDTO, Requisition>(`/requisitions/${id}`, {
        storeLocation: {
            domain: 'default',
            // action: `requisition/${uuid.v4()}`,
        },
    });
}

export function useCreateRequisitionItem(id: string) {
    return domain.usePost<RequisitionItemDTO, RequisitionItem>(`/requisitions/${id}/lineitem`);
}

export function useDeleteRequisitionItem() {
    return domain.useDelete<{ id: string }>(`/requisitions/lineitem/:lineItemId`, {
        transformRequest: (request: { id: string }) => ({
            urlParams: { lineItemId: request.id },
            data: request,
        }),
    });
}

export function useEditRequisitionItem() {
    return domain.usePut<RequisitionItemDTO & { id: string }, RequisitionItem>(
        `/requisitions/lineitem/:lineItemId`,
        {
            transformRequest: (request: RequisitionItemDTO & { id: string }) => ({
                urlParams: { lineItemId: request.id },
                data: request,
            }),
        }
    );
}

export function useApproveRequisition(requisitionId: string) {
    return domain.usePost<string, null>(`/requisitions/${requisitionId}/approve`, {
        transformRequest: (request) => ({
            queryParams: { allocation: request },
        }),
    });
}

export function useToggleReviewRequisition(requisitionId: string, reviewStatus: boolean) {
    return domain.usePost<null, null>(`/requisitions/${requisitionId}/review/${reviewStatus}`);
}

export function useSendRFQ() {
    return domain.usePost<SendRFQDTO, null>(`/requisitions/send`, {
        transformRequest: (request) => ({
            queryParams: { email: request.email, cc: request.cc },
        }),
    });
}

export function useUploadDocument(requisitionId: string) {
    return domain.usePost<UpsertDocumentDTO, null>(`/requisitions/${requisitionId}/document`);
}

export function useCancelRequisition(requisitionId: string) {
    return domain.usePost<null, null>(`/requisitions/${requisitionId}/cancel`);
}

export function useAddNote() {
    return domain.usePost<AddNoteDTO, null>(`/requisitions/note`);
}

export function useRemoveNote() {
    return domain.useDelete('/requisitions/note/:id', {
        transformRequest: (request: string) => ({
            urlParams: { id: request },
        }),
    });
}
