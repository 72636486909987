import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { useLocalContext } from './BusinessUnits';
import { domain, useLocal } from '../d-man';
import { components, paths } from './api/specs';

type schemas = components['schemas'];

export type SignInUser = {
    aud: string;
    exp: number;
    iss: string;
    name: string;
    role: string;
    userid: string;
};

export type AuthResult = {
    accessToken: string;
    refreshToken?: RefreshToken;
};

export type NewUser = paths['/accounts/register']['post']['parameters']['query'];
export type EmailUser = paths['/accounts/email']['post']['parameters']['query'];

type RefreshToken = {
    expireAt: string;
    tokenString: string;
    userName: string;
};

/**
 * User that is stored in localStorage + in memory.
 */
export function useLocalUser() {
    const AUTH_USER_KEY = 'auth_user';

    function getCachedUser(): SignInUser | undefined {
        try {
            return JSON.parse(localStorage.getItem(AUTH_USER_KEY) || '');
        } catch (err) {
            return undefined;
        }
    }

    const localUser = useLocal<SignInUser | undefined>(AUTH_USER_KEY, getCachedUser());

    function dispatch(user: SignInUser) {
        localUser.dispatch(user);
        localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
    }

    function clear() {
        localStorage.removeItem(AUTH_USER_KEY);
        localUser.dispatch(undefined);
    }

    return { ...localUser, dispatch, clear };
}

/**
 * Auths a user
 */
export function useAuthUser() {
    const localUser = useLocalUser();
    return domain.usePost<
        {
            username: string;
            password: string;
        },
        { user: SignInUser; authResult: AuthResult }
    >('/accounts/login', {
        transformRequest: (request: { username: string; password: string }) => ({
            queryParams: {
                username: request.username,
                password: request.password,
            },
        }),
        injectResponse: [{}],
        transformResponseData: (res: unknown) => {
            const authResult = res as AuthResult;
            const user = jwtDecode<SignInUser>(authResult.accessToken);
            Cookies.set('access_token', authResult.accessToken);
            localUser.dispatch(user);
            return {
                user,
                authResult,
            };
        },
    });
}

/**
 * Fetches the local user.  This user may originate from either d-man memory, or from the
 * cached/stored state.
 * @returns {User | undefined}
 */
export function useUser(): SignInUser | undefined {
    const { data: authUser } = useAuthUser();
    const { data: localUser } = useLocalUser();
    /* eslint-disable react-hooks/exhaustive-deps */
    return useMemo(() => authUser?.user || localUser, [
        JSON.stringify(authUser?.user),
        JSON.stringify(localUser),
    ]);
    /* eslint-enable react-hooks/exhaustive-deps */
}

export function useCurrentProjectId() {
    const localContexts = useLocalContext();
    const localUser = useLocalUser();

    const userContext = localContexts.data?.find((c) => c.userId === localUser.data?.userid);

    return useLocal<string | undefined>(
        'currentProjectId',
        userContext?.project || undefined,
        true
    );
}

export function useCurrentBusinessUnitId() {
    const localContexts = useLocalContext();
    const localUser = useLocalUser();
    // use context for user
    const userContext = localContexts.data?.find((c) => c.userId === localUser.data?.userid);

    return useLocal<string | undefined>(
        'currentBusinessUnitId',
        userContext?.bussinessUnit || undefined,
        true
    );
}

/**
 * Whenever you use a hook, you must also put it in either another hook (a function that starts with -use) or in
 * a React component.
 */
export function useConfirmUser() {
    /** TODO: add correct response type */
    return domain.usePost<{ userId: string; code: string; user: schemas['AmendUserDTO'] }, null>(
        'accounts/confirmemail',
        {
            transformRequest: (request: {
                userId: string;
                code: string;
                user: schemas['AmendUserDTO'];
            }) => ({
                queryParams: { userId: request.userId, code: request.code },
                data: request.user,
            }),
        }
    );
}

/**
 * Creates a new BIC User
 */
export function useNewUser() {
    return domain.usePost<NewUser, null>('/accounts/register', {
        transformRequest: (request: unknown) => ({
            queryParams: request,
        }),
    });
}

/**
 * Emails a new BIC User
 */
export function useEmailNewUser() {
    return domain.usePost<EmailUser, null>('/accounts/email', {
        transformRequest: (request: unknown) => ({
            queryParams: request,
        }),
    });
}

export function useGetRegistrationLink() {
    return domain.usePost<EmailUser, string>('/accounts/link', {
        transformRequest: (request: unknown) => ({
            queryParams: request,
        }),
    });
}
