import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { TrashCan, Error, Loader } from '../../components/Icons';
import { TableRow, TableData, TableHeader } from '../../components/Table';
import { domain } from '../../d-man';
import { useCreateUnit, useGetUnits } from '../../queries/Units';
import Pager from '../../components/Pager';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Units(props: RouteComponentProps) {
    const PAGE_SIZE = 30;
    const [skip, setSkip] = useState(0);
    const units = useGetUnits(skip, PAGE_SIZE);

    const newUnit = useCreateUnit();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [description, setDescription] = useState('');

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        if (newUnit.error) {
            setErrorMessage(newUnit.error || 'Failed to create new Unit');
        }
    }, [newUnit.error]);

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');
        if (!description) {
            setErrorMessage('Please capture all the fields above');
        } else {
            const data = {
                description,
            };
            await newUnit.execute(data);
            units.reset();
        }
        setIsLoading(false);
    };

    const clearFields = () => {
        setErrorMessage('');
        setDescription('');
    };

    /**
     * Does an HTTP Delete to the server for the specified id
     * @param id
     */
    const deleteUnit = async (id?: string) => {
        const remove = domain.delete(`/units/${id}`);
        await remove.execute();
        units.reset();
    };

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        units.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        units.reset();
    };

    const renderUnits = () => {
        if (units.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={5}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Units from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (units.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={4}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Units list from the server ({units.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (units.data) {
            return units.data.items.map((unit: { id: string; description: string }) => (
                <TableRow hover>
                    <TableData>{unit.description}</TableData>
                    <TableData>
                        <TrashCan
                            className="cursor-pointer flex"
                            id={unit.id}
                            onClick={() => deleteUnit(unit.id)}
                        />
                    </TableData>
                </TableRow>
            ));
        }
        return (
            <tr>
                <td className="border p-2" colSpan={4}>
                    <div className="flex flex-col items-center">
                        <div className="pt-3 pb-3">
                            <svg
                                className="w-8 h-8 text-orange-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <div className="text-sm">There are no Units, please create one</div>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="flex">
            <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">
                <div className="text-sm p-3 font-bold">New Unit</div>
                <hr />
                <div className="p-3">
                    <div className="mb-5">
                        <Input
                            label="Description"
                            value={description}
                            type="text"
                            onChange={(event) => setDescription(event.target.value)}
                        />
                    </div>
                </div>
                <hr />
                <div className="p-3">
                    <div className="flex mb-5 text-red-500">{errorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2">
                            <Button onClick={clearFields}>Clear</Button>
                        </div>
                        <div>
                            <Button onClick={post}>
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-3/4 h-full m-4">
                <table className="table-auto bg-white w-full mb-5">
                    <thead>
                        <tr>
                            <TableHeader>Description</TableHeader>
                            <TableHeader />
                        </tr>
                    </thead>
                    <tbody>{renderUnits()}</tbody>
                </table>
                {units.data && units.data.totalCount > PAGE_SIZE && (
                    <Pager
                        onNextPage={handleNextPage}
                        onPreviousPage={handlePreviousPage}
                        skip={skip}
                        pageSize={PAGE_SIZE}
                        objectList={units.data}
                    />
                )}
            </div>
        </div>
    );
}
