import React from 'react';

export default function Checkbox({
    value,
    label,
    onChange,
    disabled,
    name,
}: {
    value: boolean | undefined;
    label?: string | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    name?: string | undefined;
}) {
    return (
        <div className="flex justify-between w-full">
            {label && <div className="text-sm">{label}</div>}
            <input
                type="checkbox"
                name={name}
                disabled={disabled}
                checked={value}
                onChange={(e) => {
                    if (onChange) onChange(e);
                }}
                className="rounded-sm w-6"
            />
        </div>
    );
}
