import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { RouteComponentProps } from '@reach/router';
import { BlobServiceClient } from '@azure/storage-blob';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { Delivery, useGetDeliveries } from '../../queries/Delivery';
import Table from '../../components/Table';
import Pager from '../../components/Pager';
import Input from '../../components/Input';
import TypeAhead from '../../components/TypeAhead';
import { Creditor, useGetCreditors } from '../../queries/Creditors';
import { useCurrentBusinessUnitId, useCurrentProjectId } from '../../queries/Accounts';
import { forceDownload, handleClearFilterFromURL, readQueryParams } from '../../utils/Utils';
import Button from '../../components/Button';
import { useGetPermissions } from '../../queries/Permissions';
import { useGetBusinessUnits } from '../../queries/BusinessUnits';
import Loader from '../../components/Loader';

export default function Deliveries(props: RouteComponentProps) {
    const { navigate } = props;

    const params = window.location.search;

    const PAGE_SIZE = 20;
    const DEBOUNCE_DELAY = 1000;
    const [skip, setSkip] = useState(0);
    const [orderNumberSearch, setOrderNumberSearch] = useState(undefined as number | undefined);
    const [searchDeliveryDateFrom, setSearchDeliveryDateFrom] = useState(
        readQueryParams(params, 'dateFrom')
            ? new Date(readQueryParams(params, 'dateFrom') || '')
            : undefined || (undefined as Date | undefined)
    );
    const [searchDeliveryDateTo, setSearchDeliveryDateTo] = useState(undefined as Date | undefined);
    const [wildCardSearch, setWildCardSearch] = useState(undefined as string | undefined);
    const [selectedDeliveries, setSelectedDeliveries] = useState(
        [] as { deliveryId: string; checked: boolean }[]
    );

    const [wildcardFilter, setWildcardFilter] = useState(undefined as string | undefined);
    const [orderNumber, setOrderNumber] = useState(undefined as number | undefined);
    const [deliveryDateFrom, setDeliveryDateFrom] = useState(undefined as Date | undefined);
    const [deliveryDateTo, setDeliveryDateTo] = useState(undefined as Date | undefined);
    const [creditorId, setCreditorId] = useState(undefined as string | undefined);
    const [stallDeliveries, setStallDeliveries] = useState(true);
    const [stallPermissions, setStallPermissions] = useState(true);
    const [companyId, setCompanyId] = useState(undefined as string | undefined);
    const [deliveryPaidDateFrom, setDeliveryPaidDateFrom] = useState(undefined as Date | undefined);
    const [deliveryPaidDateTo, setDeliveryPaidDateTo] = useState(undefined as Date | undefined);
    const [searchDeliveryPaidDateFrom, setSearchDeliveryPaidDateFrom] = useState(
        readQueryParams(params, 'datePaidFrom')
            ? new Date(readQueryParams(params, 'datePaidFrom') || '')
            : undefined || (undefined as Date | undefined)
    );
    const [searchDeliveryPaidDateTo, setSearchDeliveryPaidDateTo] = useState(
        readQueryParams(params, 'datePaidTo')
            ? new Date(readQueryParams(params, 'datePaidTo') || '')
            : undefined || (undefined as Date | undefined)
    );
    const [isExported] = useState(
        readQueryParams(params, 'isExported') === 'false' ? false : undefined
    );

    const projectId = useCurrentProjectId();
    const businessUnitId = useCurrentBusinessUnitId();
    const businessUnits = useGetBusinessUnits();

    const getPermissions = useGetPermissions('PROJECT', projectId.data, stallPermissions);

    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Show_Delivery_Screen')
            ?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const [isDisputed, setIsDisputed] = useState(readQueryParams(params, 'isDisputed') === 'true');

    const deliveries = useGetDeliveries(
        skip,
        PAGE_SIZE,
        wildcardFilter,
        orderNumber,
        creditorId,
        projectId.data,
        businessUnitId.data,
        isDisputed,
        getPermissions.data?.items.find(
            (p) => p.code === 'Project_Enable_Export_On_Orders_Override'
        )?.hasAccess
            ? isExported
            : false,
        stallDeliveries,
        deliveryDateFrom,
        deliveryDateTo,
        deliveryPaidDateFrom,
        deliveryPaidDateTo
    );

    const deliveriesRef = useRef(deliveries);
    const creditors = useGetCreditors(undefined, undefined, undefined, undefined, companyId);
    const creditorsRef = useRef(creditors);

    useEffect(() => {
        if (projectId.data && getPermissions.data) {
            if (stallDeliveries) {
                setStallDeliveries(false);
            } else {
                deliveriesRef.current.reset();
            }
        }
        if (projectId.data) {
            setStallPermissions(false);
        }
        // eslint-disable-next-line
    }, [projectId.data, getPermissions.data]);

    useEffect(() => {
        if (businessUnitId) {
            const currentCompany = businessUnits.data?.items.find(
                (u) => u.id === businessUnitId.data
            );
            setCompanyId(currentCompany?.company?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessUnitId]);

    useEffect(() => {
        if (companyId) {
            creditors
                .execute({
                    companyId,
                })
                .then(() => creditors.reset());
            creditorsRef.current.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const toDetail = (id?: string) => {
        if (props.navigate) {
            props.navigate(`/deliveries/${id}`);
            deliveries.reset();
        }
    };

    const getStatus = (deliveryStatus: Delivery) => {
        if (deliveryStatus.exportedAt !== null || deliveryStatus.exportedBy !== null) {
            return 'Exported';
        }
        if (deliveryStatus.approvedAt !== null || deliveryStatus.approvedBy !== null) {
            return 'Approved';
        }
        return 'Draft';
    };

    const debounced = useRef(
        debounce(
            DEBOUNCE_DELAY,
            (
                newOrderNumberSearch?: number,
                newWildCardSearch?: string,
                newDeliveryDateFrom?: Date,
                newDeliveryDateTo?: Date,
                newDeliveryDatePaidFrom?: Date,
                newDeliveryDatePaidTo?: Date
            ) => {
                setOrderNumber(newOrderNumberSearch);
                setWildcardFilter(newWildCardSearch);
                setDeliveryDateFrom(newDeliveryDateFrom);
                setDeliveryDateTo(newDeliveryDateTo);
                setDeliveryPaidDateFrom(newDeliveryDatePaidFrom);
                setDeliveryPaidDateTo(newDeliveryDatePaidTo);
                deliveries.reset();
            }
        )
    );

    useEffect(() => {
        setSkip(0);
        handleClearFilterFromURL(params, 'dateFrom');
        handleClearFilterFromURL(params, 'datePaidFrom');
        handleClearFilterFromURL(params, 'datePaidTo');
        handleClearFilterFromURL(params, 'isExported');
        if (
            orderNumberSearch !== undefined ||
            searchDeliveryDateFrom !== undefined ||
            searchDeliveryDateTo !== undefined ||
            searchDeliveryPaidDateFrom !== undefined ||
            searchDeliveryPaidDateTo !== undefined ||
            wildCardSearch !== undefined
        ) {
            debounced.current(
                orderNumberSearch,
                wildCardSearch,
                searchDeliveryDateFrom,
                searchDeliveryDateTo,
                searchDeliveryPaidDateFrom,
                searchDeliveryPaidDateTo
            );
        } else {
            deliveriesRef.current.reset();
            creditorsRef.current.reset();
        }
    }, [
        orderNumberSearch,
        wildCardSearch,
        searchDeliveryDateFrom,
        searchDeliveryDateTo,
        creditorId,
        debounced,
        deliveriesRef,
        creditorsRef,
        searchDeliveryPaidDateFrom,
        searchDeliveryPaidDateTo,
        params,
    ]);

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        setSelectedDeliveries([]);
        deliveries.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        setSelectedDeliveries([]);
        deliveries.reset();
    };

    const handleCheckboxCheck = () => {
        setIsDisputed(!isDisputed);
        setSkip(0); // reset skip
        deliveries.reset();
    };

    const handleDownload = async (file: {
        id?: string | undefined;
        name?: string | undefined;
        requisitionId?: string | null | undefined;
        deliveryId?: string | null | undefined;
        returnId?: string | null | undefined;
        blobId?: string | null | undefined;
        uploadedAt?: string | null | undefined;
    }) => {
        if (file && file.blobId) {
            const sasUrl = process.env.REACT_APP_AZURE_BLOB_CONNECTIONSTRING || '';
            const blobServiceClient = new BlobServiceClient(sasUrl);
            const containerClient = blobServiceClient.getContainerClient('uploads');
            await containerClient.createIfNotExists();

            const blockBlobClient = containerClient.getBlockBlobClient(file.blobId);
            // eslint-disable-next-line no-await-in-loop
            const uploadBlobResponse = await blockBlobClient.download();
            if (uploadBlobResponse.requestId) {
                const blobby = (await uploadBlobResponse.blobBody) || new Blob();
                const url = window.URL.createObjectURL(new Blob([blobby]));
                forceDownload(url, file.name || '');
            }
        }
    };
    const handleDownloads = () => {
        selectedDeliveries.forEach((selectedDelivery) => {
            if (selectedDelivery.checked) {
                const deliveryItem = deliveries.data?.items.find(
                    (i) => i.id === selectedDelivery.deliveryId
                );
                deliveryItem?.files?.forEach((fileItem) => {
                    handleDownload(fileItem);
                });
            }
        });
    };

    const filterCheckedArray = (id: string) => {
        const currentCheckbox = selectedDeliveries.find((el) => el.deliveryId === id);
        return currentCheckbox?.checked;
    };

    const formatCreditors = () => {
        if (creditors && creditors.data && creditors.data.items) {
            return creditors.data.items.map(
                (creditor: Creditor) =>
                    ({
                        id: creditor.id,
                        name: creditor.name,
                    } as { id: string; name: string })
            );
        }
        return [];
    };
    return (
        <>
            {!getPermissions.data && <Loader message="Loading deliveries..." />}
            {getPermissions.data && (
                <div className="flex">
                    <div className="w-full h-full m-4">
                        <div className="flex space-x-6 mb-5">
                            <div className="self-center">
                                <Input
                                    value={wildCardSearch}
                                    label="Delivery Note"
                                    placeholder="Search Delivery Note"
                                    type="text"
                                    onChange={(event) => setWildCardSearch(event.target.value)}
                                    dark
                                />
                            </div>
                            <div className="flex flex-col self-center mt-1">
                                <label className="self-center text-white text-base">Disputed</label>
                                <input
                                    className="self-center"
                                    style={{
                                        transform: 'scale(1.2)',
                                        margin: '10px',
                                    }}
                                    checked={isDisputed}
                                    type="checkbox"
                                    name="Disputed"
                                    onClick={handleCheckboxCheck}
                                />
                            </div>
                            <div className="self-center">
                                <Input
                                    value={orderNumberSearch}
                                    label="Order Number"
                                    placeholder="Search Order Number"
                                    type="number"
                                    onChange={(event) =>
                                        setOrderNumberSearch(
                                            event.target.value === ''
                                                ? undefined
                                                : parseInt(event.target.value, 10)
                                        )
                                    }
                                    dark
                                />
                            </div>
                            <div className="self-center mt-0.5">
                                <TypeAhead
                                    placeholder="Search Creditors"
                                    caseSensitive={false}
                                    label="Creditor"
                                    options={formatCreditors()}
                                    onClick={(event) =>
                                        setCreditorId(
                                            ((event.target as unknown) as { id: string }).id
                                        )
                                    }
                                    onCleared={() => setCreditorId(undefined)}
                                    dark
                                />
                            </div>
                            <div>
                                <div className="text-sm pb-1 mt-2 text-white">Date From</div>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    disabled={deliveries.loading}
                                    selected={searchDeliveryDateFrom}
                                    onChange={(date: Date) => setSearchDeliveryDateFrom(date)}
                                />
                            </div>
                            <div>
                                <div className="text-sm pb-1 mt-2 text-white">Date To</div>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    disabled={deliveries.loading}
                                    selected={searchDeliveryDateTo}
                                    onChange={(date: Date) => setSearchDeliveryDateTo(date)}
                                />
                            </div>
                            <div>
                                <div className="text-sm pb-1 mt-2 text-white">Paid From</div>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    disabled={deliveries.loading}
                                    selected={searchDeliveryPaidDateFrom}
                                    onChange={(date: Date) => setSearchDeliveryPaidDateFrom(date)}
                                />
                            </div>
                            <div>
                                <div className="text-sm pb-1 mt-2 text-white">Paid To</div>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    disabled={deliveries.loading}
                                    selected={searchDeliveryPaidDateTo}
                                    onChange={(date: Date) => setSearchDeliveryPaidDateTo(date)}
                                />
                            </div>
                        </div>

                        <Table
                            loading={deliveries.loading && 'Fetching Deliveries from server'}
                            error={
                                deliveries.error &&
                                `Failed to fetch Delivery list from the server (${deliveries.error})`
                            }
                            data={deliveries.data?.items}
                            columns={[
                                {
                                    key: 'note',
                                    title: 'Delivery Note',
                                    onClick: (delivery) => toDetail(delivery.id),
                                },
                                {
                                    key: 'orderNo',
                                    title: 'Order No',
                                    onClick: (delivery) => toDetail(delivery.id),
                                    render: (delivery) => {
                                        return `${
                                            delivery.businessUnitPrefix
                                        }${delivery.orderNumber?.toString().padStart(6, '0')}`;
                                    },
                                },
                                {
                                    key: 'creditor',
                                    title: 'Creditor',
                                    render: (delivery) => {
                                        return delivery.creditorName;
                                    },
                                    onClick: (delivery) => toDetail(delivery.id),
                                },
                                {
                                    key: 'deliveredAt',
                                    title: 'Delivered At',
                                    render: (delivery) =>
                                        delivery.deliveredAt &&
                                        new Date(delivery.deliveredAt).toLocaleDateString('en-ZA'),
                                    onClick: (delivery) => toDetail(delivery.id),
                                },
                                {
                                    key: 'status',
                                    title: 'Status',
                                    render: getStatus,
                                    onClick: (delivery) => toDetail(delivery.id),
                                },
                                {
                                    key: 'payBy',
                                    title: 'Pay By',
                                    render: (delivery) =>
                                        delivery.payByDate
                                            ? format(new Date(delivery.payByDate), 'yyyy-MM-dd')
                                            : '',

                                    onClick: (delivery) => toDetail(delivery.id),
                                },
                                {
                                    key: 'select',
                                    title: 'Select',
                                    renderHeader: (deliveriesItems) => (
                                        <div className="flex space-x-3 justify-between">
                                            <div className="self-center">Select</div>
                                            <div className="self-center">
                                                <input
                                                    onChange={(e) =>
                                                        setSelectedDeliveries(
                                                            deliveriesItems.map((delivery) => ({
                                                                deliveryId: delivery.id || '',
                                                                checked: e.target.checked,
                                                            }))
                                                        )
                                                    }
                                                    type="checkbox"
                                                    checked={
                                                        selectedDeliveries.length > 0 &&
                                                        selectedDeliveries.every(
                                                            (i) => i.checked === true
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ),

                                    render: (deliveryItem) => (
                                        <div className="flex justify-end">
                                            <input
                                                onChange={(e) =>
                                                    setSelectedDeliveries(
                                                        deliveries.data?.items.map((delivery) => ({
                                                            deliveryId: delivery.id || '',
                                                            checked:
                                                                delivery.id === deliveryItem.id
                                                                    ? e.target.checked
                                                                    : selectedDeliveries.find(
                                                                          (d) =>
                                                                              d.deliveryId ===
                                                                              delivery.id
                                                                      )?.checked || false,
                                                        })) || []
                                                    )
                                                }
                                                type="checkbox"
                                                checked={filterCheckedArray(deliveryItem?.id || '')}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                        <div className="mt-6 space-y-3">
                            <div>
                                <Button
                                    disabled={
                                        !selectedDeliveries.length ||
                                        selectedDeliveries.every((i) => !i.checked)
                                    }
                                    onClick={handleDownloads}
                                >
                                    Download Selected Deliveries
                                </Button>
                            </div>
                            {deliveries.data && deliveries.data.totalCount > PAGE_SIZE && (
                                <Pager
                                    onNextPage={handleNextPage}
                                    onPreviousPage={handlePreviousPage}
                                    skip={skip}
                                    pageSize={PAGE_SIZE}
                                    objectList={deliveries.data}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
