import React, { useEffect, useState } from 'react';
import { useParams, RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { TrashCan, Error, Loader } from '../../components/Icons';
import { TableRow, TableData, TableHeader } from '../../components/Table';
import { domain } from '../../d-man';
import { CostCode, useCreateCostCode, useGetCostCodes } from '../../queries/CostCodes';
import { useGetTrade } from '../../queries/Trades';
import Pager from '../../components/Pager';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function TradeDetail(props: RouteComponentProps) {
    const params = useParams();
    const getId = () => params.id;

    const PAGE_SIZE = 30;
    const [skip, setSkip] = useState(0);
    const costCodes = useGetCostCodes(skip, PAGE_SIZE, getId());

    const newCostCode = useCreateCostCode();
    const trade = useGetTrade(getId());

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        if (newCostCode.error) {
            setErrorMessage(newCostCode.error || 'Failed to create new Cost Code');
        }
    }, [newCostCode.error]);

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');
        if (!name || !code) {
            setErrorMessage('Please capture all the fields above');
        } else {
            const data = {
                name,
                code,
                tradeId: trade.data?.id,
            };
            await newCostCode.execute(data);
            costCodes.reset();
        }
        setIsLoading(false);
    };

    const clearFields = () => {
        setErrorMessage('');
        setName('');
        setCode('');
    };

    /**
     * Does an HTTP Delete to the server for the specified id
     * @param id
     */
    const deleteCostCode = async (id?: string) => {
        const remove = domain.delete(`/costcodes/${id}`);
        await remove.execute();
        costCodes.reset();
    };

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        costCodes.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        costCodes.reset();
    };

    const renderCostCodes = () => {
        if (costCodes.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={5}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Cost Codes from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (costCodes.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={4}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Cost Codes list from the server ({costCodes.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (costCodes.data) {
            return costCodes.data.items.map((costCode: CostCode) => (
                <TableRow hover>
                    <TableData>{costCode.code}</TableData>
                    <TableData>{costCode.name}</TableData>
                    <TableData>
                        <TrashCan
                            className="cursor-pointer flex"
                            id={costCode.id}
                            onClick={() => deleteCostCode(costCode.id)}
                        />
                    </TableData>
                </TableRow>
            ));
        }
        return (
            <tr>
                <td className="border p-2" colSpan={4}>
                    <div className="flex flex-col items-center">
                        <div className="pt-3 pb-3">
                            <svg
                                className="w-8 h-8 text-orange-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <div className="text-sm">There are no Cost Codes, please create one</div>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="flex">
            <div className="w-1/4 h-full m-4">
                {trade.data && (
                    <div className="bg-gray-400 border rounded-md">
                        <div className="text-sm p-3 font-bold">Trade Information</div>
                        <hr />
                        <div className="p-3">
                            <div className="mb-5">
                                <Input label="Code" value={trade.data.code} type="text" disabled />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Description"
                                    value={trade.data.description}
                                    type="text"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                )}
                <br />
                <div className="bg-gray-400 border rounded-md">
                    <div className="text-sm p-3 font-bold">New Cost Code</div>
                    <hr />
                    <div className="p-3">
                        <div className="mb-5">
                            <Input
                                label="Code"
                                value={code}
                                type="text"
                                onChange={(event) => setCode(event.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Name"
                                value={name}
                                type="text"
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="p-3">
                        <div className="flex mb-5 text-red-500">{errorMessage}</div>
                        <div className="flex flex-row-reverse">
                            <div className="pl-2">
                                <Button onClick={clearFields}>Clear</Button>
                            </div>
                            <div>
                                <Button onClick={post}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-3/4 h-full m-4">
                <table className="table-auto bg-white w-full">
                    <thead>
                        <tr>
                            <TableHeader>Cost Code</TableHeader>
                            <TableHeader>Name</TableHeader>
                            <TableHeader />
                        </tr>
                    </thead>
                    <tbody>{renderCostCodes()}</tbody>
                </table>
                {costCodes.data && costCodes.data.totalCount > PAGE_SIZE && (
                    <Pager
                        onNextPage={handleNextPage}
                        onPreviousPage={handlePreviousPage}
                        skip={skip}
                        pageSize={PAGE_SIZE}
                        objectList={costCodes.data}
                    />
                )}
            </div>
        </div>
    );
}
