import React from 'react';

export default function Error({ message }: { message?: React.ReactNode }) {
    return (
        <div className="flex flex-col items-center">
            <div className="pt-3 pb-3">
                <svg
                    className="text-red-500 w-8 h-8 animate-pulse"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </div>
            <div className="text-sm">{message || 'An unknown error has occured'}</div>
        </div>
    );
}
