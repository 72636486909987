import { domain } from '../d-man';
import { components } from './api/specs';
import { DeliveryDTO } from './Delivery';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type CallOff = schemas['CallOff'];
export type CallOffItem = schemas['CallOffItem'];
export type UpsertCallOffWithoutOrderIdDTO = schemas['UpsertCallOffWithoutOrderIdDTO'];

export function useGetCallOffs() {
    return domain.useGet<ObjectList<CallOff>>(`/calloffs`);
}

export function useCreateCallOffDelivery(id: string) {
    return domain.usePost<DeliveryDTO, null>(`/calloffs/${id}/delivery`);
}

export function useGetCallOff(id: string) {
    return domain.useGet<CallOff>(`/calloffs/${id}`);
}

export function useSendCallOff(callOffId: string) {
    return domain.usePost<{ email: string; deliveryAddressId: string }, null>(
        `/calloffs/${callOffId}/send`,
        {
            transformRequest: (request: { email: string; deliveryAddressId: string }) => ({
                queryParams: { email: request.email, deliveryAddressId: request.deliveryAddressId },
            }),
        }
    );
}
