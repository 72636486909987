import React from 'react';
import { Router } from '@reach/router';

import { Provider } from './d-man';
import MainLayout from './layout/MainLayout';
import PrivateRoute from './PrivateRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Verification from './pages/Verification';
import UserProfile from './pages/UserProfile';
import Companies from './pages/SystemAdmin/Companies';
import BusinessUnits from './pages/SystemAdmin/BusinessUnits';
import Projects from './pages/SystemAdmin/Projects';
import Users from './pages/SystemAdmin/Users';
import Requisitions from './pages/Requisitions';
import Creditors from './pages/SystemAdmin/Creditors';
import RequisitionDetail from './pages/Requisitions/RequisitionDetail';
import ProjectDetail from './pages/SystemAdmin/ProjectDetail';
import Units from './pages/SystemAdmin/Units';
import Trades from './pages/SystemAdmin/Trades';
import TradeDetail from './pages/SystemAdmin/TradeDetail';
import BusinessUnitDetail from './pages/SystemAdmin/BusinessUnitDetail';
import Orders from './pages/Orders';
import OrderDetail from './pages/Orders/OrderDetail';
import Deliveries from './pages/Deliveries';
import CallOffDetail from './pages/CallOffs/CallOffDetail';
import DeliveryDetail from './pages/Deliveries/DeliveryDetail';
import CreditorDetail from './pages/SystemAdmin/CreditorDetail';
import Materials from './pages/SystemAdmin/Materials';
import CompaniesDetail from './pages/SystemAdmin/CompaniesDetail';

function App() {
    return (
        <Provider>
            <Router>
                <Login path="/login" />
                <Verification path="/verification" />
                <PrivateRoute path="/" Layout={MainLayout}>
                    <Dashboard path="/" />
                    <Orders path="/orders" />
                    <OrderDetail path="/orders/:id" />
                    <CallOffDetail path="/calloffs/:id" />
                    <Requisitions path="/requisitions" />
                    <RequisitionDetail path="/requisitions/:id" />
                    <Companies path="/companies" />
                    <CompaniesDetail path="/companies/:id" />
                    <BusinessUnits path="/business-units" />
                    <BusinessUnitDetail path="/business-units/:id" />
                    <Units path="/units" />
                    <Trades path="/trades" />
                    <TradeDetail path="/trades/:id" />
                    <Projects path="/projects" />
                    <ProjectDetail path="/projects/:id" />
                    <Creditors path="/creditors" />
                    <CreditorDetail path="/creditors/:id" />
                    <Materials path="/materials" />
                    <Users path="/users" />
                    <Deliveries path="/deliveries" />
                    <DeliveryDetail path="/deliveries/:id" />
                    <UserProfile path="/profile" />
                </PrivateRoute>
            </Router>
        </Provider>
    );
}

export default App;
