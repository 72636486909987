import React from 'react';
import { Link } from '@reach/router';

export default function DashboardCard({
    title,
    count,
    Icon,
    url,
    visible,
}: {
    title: string;
    count: number;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    url: string;
    visible: boolean;
}) {
    return (
        <>
            {visible && (
                <div className="w-36 border rounded-md text-right p-3 bg-gray-400">
                    <div className="text-sm font-bold">{title}</div>
                    <div className="flex justify-between">
                        <div>
                            <Icon className="w-10 h-10 text-orange-500" />
                        </div>
                        <div className="mt-1 text-2xl font-bold">{count}</div>
                    </div>
                    <Link className="text-sm  underline" to={url}>
                        View
                    </Link>
                </div>
            )}
        </>
    );
}
