import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type Project = schemas['Project'];
export type ProjectAddress = schemas['ProjectAddress'];
export type ProjectDTO = schemas['CreateProjectDTO'];

export function useGetProjects(
    skip?: number,
    take?: number,
    businessUnitId?: string,
    projectName?: string
) {
    return domain.useGet<ObjectList<Project>>('/projects', {
        requestConfig: {
            queryParams: {
                skip,
                take,
                businessUnitId,
                projectName,
            },
        },
        storeLocation: {
            domain: 'default',
            action: !businessUnitId ? 'projects' : `projects/${businessUnitId}`,
        },
    });
}

export function useGetProject(id?: string) {
    return domain.useGet<Project>(`/projects/${id}`);
}

export function useCreateProject() {
    return domain.usePost<ProjectDTO, null>('/projects');
}

export function useEditProjects() {
    return domain.usePut<ProjectDTO & { id: string }, null>(`/projects/:projectId`, {
        transformRequest: (request: ProjectDTO & { id: string }) => ({
            urlParams: { projectId: request.id },
            data: request,
        }),
    });
}
