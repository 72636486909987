import { domain } from '../d-man';
import { components } from './api/specs';
import { CreateDisputeMessageWithoutDisputeIdDTO } from './Disputes';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type DeliveryDTO = {
    note: string;
    deliveredAt: Date;
    blobs: {
        id: string;
        name: string;
    }[];
    items: DeliveryItem[];
    returnNote?: string;
    returnBlobs: {
        id: string;
        name: string;
    }[];
    returnReason?: string;
    payByDate?: Date;
};

export type Delivery = schemas['Delivery'];
export type DeliveryItem = schemas['DeliveryItem'];
export type UpsertDeliveryFileDTO = schemas['UpsertDeliveryFileDTO'];

export function useGetDeliveries(
    skip?: number,
    take?: number,
    wildcardFilter?: string,
    orderNumber?: number,
    creditorId?: string,
    projectId?: string,
    businessUnitId?: string,
    isDisputed?: boolean,
    exported?: boolean,
    lazy?: boolean,
    dateFrom?: Date,
    dateTo?: Date,
    paidDateFrom?: Date,
    paidDateTo?: Date
) {
    return domain.useGet<ObjectList<Delivery>>('/deliveries', {
        requestConfig: {
            queryParams: {
                skip,
                take,
                wildcardFilter,
                orderNumber,
                creditorId,
                isDisputed,
                projectId,
                businessUnitId,
                exported,
                dateFrom,
                dateTo,
                paidDateFrom,
                paidDateTo,
            },
        },
        storeLocation: {
            domain: 'default',
            action: !isDisputed ? 'deliveries' : `disputedDeliveries/${isDisputed}`,
        },
        lazy,
    });
}

export function useGetDelivery(id: string) {
    return domain.useGet<Delivery>(`/deliveries/${id}`);
}

export function useApproveDelivery(deliveryId: string) {
    return domain.usePost<null, null>(`/deliveries/${deliveryId}/approve`);
}

export function useCreateDisputeDelivery(deliveryId: string) {
    return domain.usePost<CreateDisputeMessageWithoutDisputeIdDTO, null>(
        `/deliveries/${deliveryId}/dispute`
    );
}

export function useGetDeliveryDisputes(isDisputed: boolean) {
    return domain.useGet<Delivery>(`/deliveries/${isDisputed}`);
}

export function useUploadDeliveryFile(deliveryId: string) {
    return domain.usePost<UpsertDeliveryFileDTO, null>(`/deliveries/${deliveryId}/upload`);
}
