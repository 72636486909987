import { domain, useLocal } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];
export type Currency = schemas['Currency'];
export type Vat = schemas['Vat'];
export type BusinessUnit = schemas['BusinessUnit'];
export type CreateBusinessUnitDTO = schemas['CreateBusinessUnitDTO'];
export type BusinessUnitDTO = schemas['CreateBusinessUnitDTO'];

export function useGetBusinessUnits(skip?: number, take?: number) {
    return domain.useGet<ObjectList<BusinessUnit>>('/businessunits', {
        requestConfig: {
            queryParams: {
                skip,
                take,
            },
        },
    });
}

export function useGetBusinessUnit(id: string) {
    return domain.useGet<BusinessUnit>(`/businessunits/${id}`);
}

export function useCreateBusinessUnit() {
    return domain.usePost<CreateBusinessUnitDTO, null>('/businessunits', {
        transformRequest: (request: unknown) => ({
            data: request,
        }),
    });
}

export function useEditBusinessUnit() {
    return domain.usePut<CreateBusinessUnitDTO & { id: string }, null>(
        `/businessunits/:businessUnitId`,
        {
            transformRequest: (request: CreateBusinessUnitDTO & { id: string }) => ({
                urlParams: { businessUnitId: request.id },
                data: request,
            }),
        }
    );
}

export type AppContext = {
    userId: string | undefined;
    bussinessUnit: string | undefined;
    project: string | undefined;
};

export function useLocalContext() {
    const APP_CONTEXT_KEY = 'raubex_context';

    function getCachedContext(): AppContext[] | [] {
        try {
            return JSON.parse(localStorage.getItem(APP_CONTEXT_KEY) || '');
        } catch (err) {
            return [];
        }
    }

    // This is the context
    const localContext = useLocal<AppContext[] | undefined>(APP_CONTEXT_KEY, getCachedContext());

    // Set the context
    function dispatch(context: AppContext) {
        const contextArray: AppContext[] = getCachedContext();

        const contextIndex = contextArray.findIndex((c: AppContext) => c.userId === context.userId);
        // Check if context exists in
        if (contextIndex !== -1) {
            contextArray[contextIndex] = context;
        } else {
            contextArray.push(context);
        }

        localContext.dispatch(contextArray);
        localStorage.setItem(APP_CONTEXT_KEY, JSON.stringify(contextArray));
    }

    return { ...localContext, dispatch };
}
