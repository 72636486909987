import React, { ReactElement } from 'react';
import { MdOutlineDashboard, MdBusiness, MdOutlineRequestQuote } from 'react-icons/md';
import { AiOutlineReconciliation, AiOutlineProject } from 'react-icons/ai';
import { TbTruckDelivery } from 'react-icons/tb';
import { FiSettings } from 'react-icons/fi';
import { BsBuildings } from 'react-icons/bs';
import { RiPencilRuler2Line } from 'react-icons/ri';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { HiOutlineUsers } from 'react-icons/hi';
import { IoIosBarcode } from 'react-icons/io';

interface iconType {
    [index: string]: ReactElement;
}

const SIZE = 30;

const ICONS: iconType = {
    Dashboard: <MdOutlineDashboard size={30} />,
    Creditors: (
        <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 22 22"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.7"
                d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
        </svg>
    ),
    Orders: <MdOutlineRequestQuote size={SIZE} />,
    Companies: <BsBuildings size={SIZE} />,
    'Business Units': <MdBusiness size={SIZE} />,
    Projects: <AiOutlineProject size={SIZE} />,
    Units: <RiPencilRuler2Line size={SIZE} />,
    'Trades / CostCodes': <IoIosBarcode size={SIZE} />,
    Materials: <HiOutlineWrenchScrewdriver size={SIZE} />,
    Users: <HiOutlineUsers size={SIZE} />,
    Deliveries: <TbTruckDelivery size={SIZE} />,
    Recons: <AiOutlineReconciliation size={SIZE} />,
    'System Admin': <FiSettings size={SIZE} />,
    Requisitions: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 22"
            fill="none"
            className="w-8 h-8"
            stroke="currentColor"
        >
            <path
                strokeWidth="1.4"
                fillRule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clipRule="evenodd"
            />
        </svg>
    ),
};

function NavIcon({ route }: { route: string }) {
    return ICONS[route] || <div className="w-8 h-8" />;
}

export default NavIcon;
