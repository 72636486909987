import { createDMan } from 'd-man';
import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useLocalUser } from './queries/Accounts';

const { domain, Provider, logout: dManLogout, useLocal } = createDMan({
    domain: {
        baseURL: process.env.REACT_APP_API_URL || '',
        getAuthToken: () => `Bearer ${Cookies.get('access_token')}` || '',
        useResponseInterceptor: {
            onError: (error) => {
                if (error?.response?.status === 401 || error?.response?.data?.status === 401) {
                    // There seems to be an issue with dManLogout() to clear localStorage/Cookies
                    // This clears out both caches manualy
                    Cookies.remove('access_token');
                    localStorage.removeItem('auth_user');

                    dManLogout(); // log out on 401
                }

                if (
                    error?.response?.data &&
                    Array.isArray(error.response.data) &&
                    error.response.data.length > 0
                ) {
                    if (error.response.data[0].description) {
                        throw error.response.data[0].description;
                    }
                }

                if (error?.response?.data?.errors && error.response.data?.errors.length > 0) {
                    throw error.response.data.errors[0].errorMessage;
                }

                if (error?.response?.data?.message) {
                    throw error.response.data.message;
                }
                throw error;
            },
        },
    },
    disableLocalStorage: true,
});

function useLogout() {
    const localUser = useLocalUser();
    return useCallback(() => {
        dManLogout();
        Cookies.remove('access_token');
        localUser.clear();
    }, [localUser]);
}

export { domain, Provider, useLogout, useLocal };
