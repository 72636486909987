import React from 'react';
import { ObjectList } from '../../queries/ObjectList';
import Button from '../Button';

export default function Pager<T>({
    objectList,
    skip,
    pageSize,
    onNextPage,
    onPreviousPage,
}: {
    objectList: ObjectList<T>;
    skip: number;
    pageSize: number;
    onNextPage?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onPreviousPage?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
    return (
        <div className="flex flex-row-reverse">
            {Math.ceil(objectList.totalCount / pageSize) -
                Math.ceil((objectList.totalCount - skip) / pageSize) <
                Math.ceil(objectList.totalCount / pageSize) - 1 && (
                <div className="pl-2">
                    <Button onClick={onNextPage}>Next</Button>
                </div>
            )}
            {Math.ceil(objectList.totalCount / pageSize) -
                Math.ceil((objectList.totalCount - skip) / pageSize) >
                0 && (
                <div className="pl-2">
                    <Button onClick={onPreviousPage}>Back</Button>
                </div>
            )}
            <div className="text-white">
                {1 +
                    (Math.ceil(objectList.totalCount / pageSize) -
                        Math.ceil((objectList.totalCount - skip) / pageSize))}
                /{Math.ceil(objectList.totalCount / pageSize)}
            </div>
        </div>
    );
}
