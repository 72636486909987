import { path } from 'ramda';
import React from 'react';
import Error from '../Error';
import Loader from '../Loader';
import { TableData } from './TableData';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

export default function Table<T>({
    data,
    loading,
    error,
    columns,
    onClick,
    getStatus,
}: {
    data?: T[];
    columns?: {
        title: string;
        key: keyof T | string;
        width?: string;
        renderHeader?: (rows: T[]) => React.ReactNode;
        render?: (row: T) => React.ReactNode;
        onClick?: (row: T) => void;
    }[];
    onClick?: (row: T) => void;
    getStatus?: (row: T) => string;
    loading?: boolean | string;
    error?: boolean | string;
}) {
    const content = () => {
        if (loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={columns?.length}>
                        <Loader message={loading !== true && loading} />
                    </td>
                </tr>
            );
        }
        if (error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={columns?.length}>
                        <Error message={error !== true && error} />
                    </td>
                </tr>
            );
        }
        if (data) {
            return data.map((row, i) => (
                <TableRow
                    hover
                    key={i}
                    onClick={(() => {
                        if (!onClick) return undefined;
                        return () => onClick(row);
                    })()}
                    status={getStatus ? getStatus(row) : undefined}
                >
                    {columns?.map((column) => (
                        <TableData
                            key={column.key as string}
                            onClick={(() => {
                                const onColumnClick = column.onClick;
                                if (!onColumnClick) return undefined;
                                return () => onColumnClick(row);
                            })()}
                        >
                            {(() => {
                                if (column.render) return column.render(row);
                                return path((column.key as string).split('.'), row) as string;
                            })()}
                        </TableData>
                    ))}
                </TableRow>
            ));
        }
        return null;
    };

    return (
        <table className="table-auto bg-white w-full">
            <thead>
                <TableRow>
                    {columns?.map((column) => (
                        <TableHeader key={column.key as string} width={column.width}>
                            {(() => {
                                if (column.renderHeader) return column.renderHeader(data || []);
                                return column.title;
                            })()}
                        </TableHeader>
                    ))}
                </TableRow>
            </thead>
            <tbody>{content()}</tbody>
        </table>
    );
}
