import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 20000000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes: number) => Math.round(bytes / KILO_BYTES_PER_BYTE);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertNestedObjectToArray = (nestedObj: any) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

export const FileUploadContainer = styled.section`
    position: relative;
    margin: 25px 0 15px;
    border: 2px dotted lightgray;
    padding: 35px 20px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 150px;
`;

export const FormField = styled.input`
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;

    &:focus {
        outline: none;
    }
`;

export const InputLabel = styled.label`
    top: -21px;
    font-size: 13px;
    color: black;
    left: 0;
    position: absolute;
`;

export const DragDropText = styled.p`
    font-weight: bold;
    letter-spacing: 2.2px;
    margin-top: 0;
    text-align: center;
`;

export const UploadFileBtn = styled.button`
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #3498db;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 1.1em 2.8em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 6px;
    color: #3498db;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 250ms ease-in-out;
    font-family: 'Open Sans', sans-serif;
    width: 45%;
    display: flex;
    align-items: center;
    padding-right: 0;
    justify-content: center;

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 100%;
        background: #3498db;
        z-index: -1;
        transition: width 250ms ease-in-out;
    }

    i {
        font-size: 22px;
        margin-right: 5px;
        border-right: 2px solid;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media only screen and (max-width: 500px) {
        width: 70%;
    }

    @media only screen and (max-width: 350px) {
        width: 100%;
    }

    &:hover {
        color: #fff;
        outline: 0;
        background: transparent;

        &:after {
            width: 110%;
        }
    }

    &:focus {
        outline: 0;
        background: transparent;
    }

    &:disabled {
        opacity: 0.4;
        filter: grayscale(100%);
        pointer-events: none;
    }
`;

export const FilePreviewContainer = styled.article`
    margin-bottom: 35px;

    span {
        font-size: 14px;
    }
`;

export const PreviewList = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }
`;

export const FileMetaData = styled.div`
    display: ${(props: { isImageFile: boolean }) => (props.isImageFile ? 'none' : 'flex')};
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    background-color: rgba(5, 5, 5, 0.55);

    aside {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
    }
`;

export const RemoveFileIcon = styled.i`
    cursor: pointer;

    &:hover {
        transform: scale(1.3);
    }
`;

export const PreviewContainer = styled.section`
    padding: 0.25rem;
    width: 20%;
    height: 120px;
    border-radius: 6px;
    box-sizing: border-box;

    &:hover {
        opacity: 0.55;

        ${FileMetaData} {
            display: flex;
        }
    }

    & > div:first-of-type {
        height: 100%;
        position: relative;
    }

    @media only screen and (max-width: 750px) {
        width: 25%;
    }

    @media only screen and (max-width: 500px) {
        width: 50%;
    }

    @media only screen and (max-width: 400px) {
        width: 100%;
        padding: 0 0 0.4em;
    }
`;

export const ImagePreview = styled.img`
    border-radius: 6px;
    width: 100%;
    height: 100%;
`;

export default function FileUpload({
    label,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    onError,
    ...otherProps
}: {
    label?: string;
    maxFileSizeInBytes?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateFilesCb?: (files: File[]) => void;
    onError?: (error: string) => void;
    multiple: boolean;
}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileInputField: any = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [files, setFiles] = useState({} as any);

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addNewFiles = (newFiles: any) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of newFiles) {
            if (file.size < maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            } else if (onError) {
                onError(
                    `The file exceeds the maximum upload size of ${maxFileSizeInBytes / 1000.0}kb`
                );
            }
        }
        return { ...files };
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const callUpdateFilesCb = (filesToUpdate: any) => {
        const filesAsArray = convertNestedObjectToArray(filesToUpdate);
        if (updateFilesCb) {
            updateFilesCb(filesAsArray);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleNewFileUpload = (e: any) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            const updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (fileName: string) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    };

    return (
        <>
            <div className="flex justify-center">
                <UploadFileBtn style={{ zIndex: 0 }} type="button" onClick={handleUploadBtnClick}>
                    <i className="fas fa-file-upload" />
                    <span> Upload {otherProps.multiple ? 'files' : 'a file'}</span>
                </UploadFileBtn>
            </div>

            <FileUploadContainer>
                <InputLabel>{label}</InputLabel>
                <DragDropText>
                    Drag and drop your files in this space <br /> (or use the button above)
                </DragDropText>

                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                />
            </FileUploadContainer>
            {Object.keys(files).length > 0 && (
                <FilePreviewContainer>
                    <span>To Upload</span>
                    <PreviewList>
                        {Object.keys(files).map((fileName, index) => {
                            const file = files[fileName];
                            const isImageFile = file.type.split('/')[0] === 'image';
                            return (
                                <PreviewContainer key={fileName}>
                                    <div>
                                        {isImageFile && (
                                            <ImagePreview
                                                src={URL.createObjectURL(file)}
                                                alt={`file preview ${index}`}
                                            />
                                        )}
                                        <FileMetaData isImageFile={isImageFile}>
                                            <span>{file.name}</span>
                                            <aside>
                                                <span>{convertBytesToKB(file.size)} kb</span>
                                                <RemoveFileIcon
                                                    className="fas fa-trash-alt"
                                                    onClick={() => removeFile(fileName)}
                                                />
                                            </aside>
                                        </FileMetaData>
                                    </div>
                                </PreviewContainer>
                            );
                        })}
                    </PreviewList>
                </FilePreviewContainer>
            )}
        </>
    );
}
