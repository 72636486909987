import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { TrashCan, Error, Loader } from '../../components/Icons';
import { TableRow, TableData, TableHeader } from '../../components/Table';
import { domain } from '../../d-man';
import Pager from '../../components/Pager';
import { useGetMaterials } from '../../queries/Materials';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Materials(props: RouteComponentProps) {
    const PAGE_SIZE = 30;
    const [skip, setSkip] = useState(0);
    const materials = useGetMaterials(skip, PAGE_SIZE);
    const materialsRef = useRef(materials);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        materialsRef.current.reset();
    }, [materialsRef]);

    /**
     * Does an HTTP Delete to the server for the specified id
     * @param id
     */
    const deleteMaterial = async (id?: string) => {
        const remove = domain.delete(`/materials/${id}`);
        await remove.execute();
        materials.reset();
    };

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        materials.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        materials.reset();
    };

    const renderUnits = () => {
        if (materials.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={5}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Materials from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (materials.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={4}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Materials list from the server ({materials.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (materials.data) {
            return materials.data.items.map((unit: { id: string; description: string }) => (
                <TableRow hover>
                    <TableData>{unit.description}</TableData>
                    <TableData>
                        <TrashCan
                            className="cursor-pointer flex"
                            id={unit.id}
                            onClick={() => deleteMaterial(unit.id)}
                        />
                    </TableData>
                </TableRow>
            ));
        }
        return (
            <tr>
                <td className="border p-2" colSpan={4}>
                    <div className="flex flex-col items-center">
                        <div className="pt-3 pb-3">
                            <svg
                                className="w-8 h-8 text-orange-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <div className="text-sm">There are no Materials, please create one</div>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="flex">
            <div className="w-full h-full m-4">
                <table className="table-auto bg-white w-full mb-5">
                    <thead>
                        <tr>
                            <TableHeader>Description</TableHeader>
                            <TableHeader />
                        </tr>
                    </thead>
                    <tbody>{renderUnits()}</tbody>
                </table>
                {materials.data && materials.data.totalCount > PAGE_SIZE && (
                    <Pager
                        onNextPage={handleNextPage}
                        onPreviousPage={handlePreviousPage}
                        skip={skip}
                        pageSize={PAGE_SIZE}
                        objectList={materials.data}
                    />
                )}
            </div>
        </div>
    );
}
