import React, { useState } from 'react';
import * as R from 'ramda';

export default function Chips({
    value,
    onChange,
    label,
    disabled,
    placeholder,
    type,
    rightIcon,
    leftIcon,
    name,
    error: _error,
}: {
    value?: string[];
    onChange?: (value: string[]) => void;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    type?: 'text' | 'email' | 'number' | 'password';
    rightIcon?: React.ReactNode;
    leftIcon?: React.ReactNode;
    name?: string;
    error?: string | undefined | boolean;
}) {
    const [dirty, setDirty] = useState(false);
    const [text, setText] = useState('');

    const error = dirty && _error;

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter' && text && !value?.includes(text)) {
            if (onChange) onChange([...(value || []), text]);
            setText('');
        }
    }

    function onChipClose(v: string) {
        if (onChange) onChange(R.without([v], value || []));
    }

    return (
        <label htmlFor={name}>
            {label && <div className="text-sm pb-1">{label}</div>}
            <div className="">
                {!disabled && (
                    <input
                        name={name}
                        disabled={disabled}
                        value={text}
                        type={type}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                            setText(e.target.value);
                            setDirty(true);
                        }}
                        onBlur={() => {
                            setDirty(true);
                        }}
                        placeholder={placeholder || 'Press Enter to add'}
                        className="border rounded-sm w-full pl-0.5"
                    />
                )}
                {leftIcon && (
                    <div className="absolute left-0 top-0 bottom-0 flex items-center">
                        {leftIcon}
                    </div>
                )}
                {rightIcon && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center">
                        {rightIcon}
                    </div>
                )}
            </div>
            <div className="flex items-center flex-wrap -mx-1">
                {value?.map((v, i) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className="p-1 rounded text-xs bg-gray-200 flex items-center mx-1 mt-1"
                    >
                        {v}
                        {!disabled && (
                            <button
                                className={`font-bold h-4 w-4 ml-1 flex items-center
                                justify-center hover:bg-gray-300 rounded-full cursor-pointer`}
                                type="button"
                                onClick={() => onChipClose(v)}
                            >
                                x
                            </button>
                        )}
                    </div>
                ))}
            </div>
            {error && <div className="">{error}</div>}
        </label>
    );
}
