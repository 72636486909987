import React from 'react';
import clsx from 'clsx';

export default function Select({
    label,
    disabled,
    options,
    name,
    onChange,
    error,
    errorMessage,
    placeholder,
    dark,
    width,
    selected,
    ...props
}: {
    label?: React.ReactNode;
    disabled?: boolean;
    options: { value: string; label: string }[];
    name?: string;
    error?: boolean;
    errorMessage?: string;
    placeholder?: string;
    dark?: boolean;
    width?: string;
    selected?: string;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
    const className = clsx(
        `focus:ring-black h-full ${width || 'w-full'} py-0`,
        {
            'bg-white': !disabled,
            'bg-gray-100': disabled,
        },
        props
    );

    return (
        <>
            {label && (
                <p className={`text-base pb-1 ${dark ? 'text-black' : 'text-white'}`}>{label}</p>
            )}
            <div className={`border border-gray rounded-sm ${width || 'w-full'}`}>
                <select disabled={disabled} className={className} onChange={onChange} name={name}>
                    {placeholder && (
                        <option value="" selected={!selected} disabled hidden>
                            {placeholder}
                        </option>
                    )}
                    {options.map((data, i) => {
                        return (
                            <option key={i} value={data.value} selected={selected === data.value}>
                                {data.label}
                            </option>
                        );
                    })}
                </select>
                {error && <p className="tw-text-red">{errorMessage}</p>}
            </div>
        </>
    );
}
