import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type DisputeMessage = schemas['DisputeMessage'];
export type DeliveryDispute = schemas['DeliveryDispute'];
export type CreateDisputeMessageWithoutDisputeIdDTO = schemas['CreateDisputeMessageWithoutDisputeIdDTO'];

export function useGetDeliveryDisputes(skip?: number, take?: number) {
    return domain.useGet<ObjectList<DeliveryDispute>>('/deliverydisputes', {
        requestConfig: {
            queryParams: {
                skip,
                take,
            },
        },
    });
}

export function useResolveDeliveryDispute() {
    return domain.usePost<{ deliveryDisputeId: string }, null>(
        '/deliverydisputes/:deliveryDisputeId/resolve',
        {
            transformRequest: (request) => ({
                urlParams: { deliveryDisputeId: request.deliveryDisputeId },
            }),
        }
    );
}
