import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type Trade = {
    id: string;
    code: string;
    description: string;
};

export type TradeDTO = schemas['Trade'];

export function useCreateTrade() {
    return domain.usePost<TradeDTO, null>('/trades');
}

export function useGetTrade(id: string) {
    return domain.useGet<Trade>(`/trades/${id}`);
}

export function useGetTrades(skip?: number, take?: number) {
    return domain.useGet<ObjectList<Trade>>('/trades', {
        requestConfig: {
            queryParams: {
                skip,
                take,
            },
        },
    });
}
