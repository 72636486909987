import React, { useState } from 'react';

export default function Input({
    value,
    onChange,
    onClick,
    label,
    disabled,
    placeholder,
    type,
    rightIcon,
    leftIcon,
    name,
    error: _error,
    dark,
    width,
}: {
    value: string | undefined | number | null;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    type?: 'text' | 'email' | 'number' | 'password';
    rightIcon?: React.ReactNode;
    leftIcon?: React.ReactNode;
    name?: string;
    error?: string | undefined | boolean;
    dark?: boolean;
    width?: string;
}) {
    const [dirty, setDirty] = useState(false);

    const error = dirty && _error;

    return (
        <label htmlFor={name}>
            {label && (
                <div className={`text-base pb-1 ${dark ? 'text-white' : 'text-black'}`}>
                    {label}
                </div>
            )}
            <div className="">
                <input
                    name={name}
                    disabled={disabled}
                    value={value || ''}
                    type={type}
                    onClick={onClick}
                    onChange={(e) => {
                        if (onChange) onChange(e);
                        setDirty(true);
                    }}
                    onBlur={() => {
                        setDirty(true);
                    }}
                    placeholder={placeholder}
                    className={`border rounded-sm ${width || 'w-full'} pl-0.5 ${
                        disabled ? 'cursor-not-allowed' : ''
                    } ${disabled ? 'bg-gray-200' : 'bg-white'}`}
                />
                {leftIcon && (
                    <div className="absolute left-0 top-0 bottom-0 flex items-center">
                        {leftIcon}
                    </div>
                )}
                {rightIcon && (
                    <div className="absolute right-0 top-0 bottom-0 flex items-center">
                        {rightIcon}
                    </div>
                )}
            </div>
            {error && <div className="">{error}</div>}
        </label>
    );
}
