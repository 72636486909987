import { domain } from '../d-man';
import { ObjectList } from './ObjectList';
import { components } from './api/specs';

type schemas = components['schemas'];

export type Creditor = schemas['Creditor'];
export type CreditorDTO = schemas['Creditor'];
export type UpsertCreditorDTO = schemas['UpsertCreditorDTO'];

export function useGetCreditors(
    skip?: number,
    take?: number,
    name?: string,
    code?: string,
    companyId?: string
) {
    return domain.useGet<ObjectList<Creditor>>('/creditors', {
        requestConfig: {
            queryParams: {
                skip,
                take,
                name,
                code,
                companyId,
            },
        },
    });
}

export function useGetCreditor(id: string) {
    return domain.useGet<Creditor>(`/creditors/${id}`);
}

export function useCreateCreditor() {
    return domain.usePost<UpsertCreditorDTO, null>('/creditors');
}

export function useEditCreditor() {
    return domain.usePut<CreditorDTO & { id: string }, null>(`/creditors/:creditorId`, {
        transformRequest: (request: CreditorDTO & { id: string }) => ({
            urlParams: { creditorId: request.id },
            data: request,
        }),
    });
}
