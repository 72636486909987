import { domain } from '../d-man';
import { ObjectList } from './ObjectList';

export type Permission = {
    code: string;
    description: string;
    includeRoleNames: string[];
    excludeRoleNames: string[];
    hasAccess: boolean;
    permissionScope: string;
};

export function useGetPermissions(scope: string, scopeId?: string, lazy?: boolean) {
    return domain.useGet<ObjectList<Permission>>('/permission', {
        requestConfig: {
            queryParams: {
                scope,
                scopeId,
            },
        },
        storeLocation: {
            domain: 'default',
            action: `permission-${scope}-${scopeId}`,
        },
        lazy,
    });
}
