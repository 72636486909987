/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

export type Option = {
    id: string;
    name: string;
};

export default function TypeAhead({
    placeholder,
    label,
    options,
    caseSensitive,
    value,
    hideNoMatchMsg,
    onClick,
    onCleared,
    onChanged,
    dark,
}: {
    placeholder: string;
    label?: string | undefined;
    options: Option[];
    caseSensitive?: boolean;
    // acceptNewValues?: boolean;
    value?: string | null;
    hideNoMatchMsg?: boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onCleared?: () => void;
    onChanged?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dark?: boolean;
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [optionItems, setOptionItems] = useState([]);
    const [internalValue, setInternalValue] = useState(value);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        if (internalValue === undefined || internalValue !== value) {
            setInternalValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChanged) {
            onChanged(event);
        }
        let typedValue = event.target.value;

        setInternalValue(typedValue);

        if (typedValue.length > 0 && typedValue !== internalValue) {
            setIsOpen(true);
            setOptionItems([]);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const displayOptions: any = [];
            options.forEach((option: Option) => {
                let checkValue = option.name;
                if (!caseSensitive) {
                    checkValue = checkValue.toLowerCase();
                    typedValue = typedValue.toLowerCase();
                }
                if (checkValue.includes(typedValue)) {
                    const tempIndex = displayOptions
                        .map((optionItem: Option) => optionItem.name)
                        .indexOf(option.name);
                    if (tempIndex === -1 && displayOptions.length < 10) {
                        displayOptions.push(option);
                    }
                }
            });
            setOptionItems(displayOptions);
        } else {
            setIsOpen(false);
        }
    };

    /**
     * Primary function which triggers when the user clicks an option in the
     * TypeAhead search boc
     * @param event
     */
    const optionClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = event.target as Element;
        const { id } = target;
        const index = optionItems.map((option: Option) => option.id).indexOf(id);
        const option = optionItems[index] as Option;
        setInternalValue(option.name);
        setIsOpen(false);
        onClick(event);
    };

    /**
     * Renders a list of options for the user to choose from
     */
    const renderOptions = () => {
        if (optionItems.length === 0 && !hideNoMatchMsg) {
            return <div className="text-sm">No matches found</div>;
        }
        if (optionItems.length === 0 && hideNoMatchMsg) {
            setIsOpen(false);
        }

        return optionItems.map((optionItem: Option) => (
            <div id={optionItem.id} className="text-sm cursor-pointer" onClick={optionClicked}>
                {optionItem.name}
            </div>
        ));
    };

    const clearOptionItems = () => {
        setInternalValue('');
        if (onCleared) onCleared();
        setIsOpen(false);
    };

    const containerRef = useRef(null);
    useOutsideAlerter(containerRef);

    return (
        <div>
            <div className="pb-0.5">
                <div>
                    {label && (
                        <div className={`text-base ${dark ? 'text-white' : 'text-black'}`}>
                            {label}
                        </div>
                    )}
                    <div className="flex justify-between items-center border rounded-sm w-full mt-1">
                        <input
                            value={internalValue || ''}
                            type="text"
                            onChange={(e) => {
                                e.preventDefault();
                                handleSearch(e);
                            }}
                            placeholder={placeholder}
                            className="pl-0.5 w-full"
                        />
                        <div className="cursor-pointer bg-gray-100 hover:bg-gray-200">
                            <svg
                                onClick={() => clearOptionItems()}
                                className="w-6 h-6 text-orange-500 border-l"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {(dialogRef) => {
                    return (
                        <div ref={containerRef}>
                            <div
                                ref={dialogRef}
                                className="rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 absolute z-10"
                            >
                                <div
                                    className="p-1"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="options-menu"
                                >
                                    {renderOptions()}
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Transition>
        </div>
    );
}
