import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Panel from '../../components/Panel';
import PageOverlay from '../../components/PageOverlay';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function UserProfile(props: RouteComponentProps) {
    return (
        <PageOverlay>
            <Panel>
                <div className="flex justify-center mt-5">
                    <div className="font-bold">Profile</div>
                </div>
            </Panel>
            <div className="text-xs flex justify-center absolute inset-x-0 bottom-0 mb-6">
                © - Raubex PTY
            </div>
        </PageOverlay>
    );
}
