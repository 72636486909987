import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
// import qs from 'qs';
import { useAuthUser } from '../queries/Accounts';
import PageOverlay from '../components/PageOverlay';
import Button from '../components/Button';
import Input from '../components/Input';
import CompanyLogo from '../components/CompanyLogo';
import Panel from '../components/Panel';
import { useLogout } from '../d-man';

export default function Login(props: RouteComponentProps) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const logout = useLogout();

    const authUser = useAuthUser();

    // eslint-disable-next-line react/destructuring-assignment
    // const redirect = qs.parse(props.location?.search.slice(1) as string).redirect as string;

    useEffect(() => {
        if (authUser.error) {
            setErrorMessage('Incorrect username or password');
        }
    }, [authUser.error]);

    useEffect(() => {
        // This checks if token has expired
        const expired = (() => {
            if (!authUser.data) return false;
            const exp = authUser?.data?.user?.exp;
            if (!exp) return false;
            return new Date().getTime() / 1000 >= exp;
        })();

        if (authUser.success && props.navigate && !expired) {
            props.navigate('/');
        }
    }, [authUser.data, authUser.success, props]);

    const postUserCredentials = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        logout();
        setErrorMessage('');
        await authUser.execute({
            username,
            password,
        });
    };

    return (
        <PageOverlay>
            <Panel>
                <form onSubmit={postUserCredentials}>
                    <CompanyLogo />
                    <div className="mb-10">
                        <div className="flex justify-center mb-5">
                            <Input
                                label="Username"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </div>
                        <div className="flex justify-center">
                            <Input
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex justify-center mb-5 text-red-500">{errorMessage}</div>
                    </div>
                    <div className="flex justify-center">
                        <Button
                            type="submit"
                            disabled={authUser.loading || (!username && !password)}
                        >
                            {!authUser.loading ? 'Log In' : <div>Loading</div>}
                        </Button>
                    </div>
                </form>
            </Panel>
        </PageOverlay>
    );
}
