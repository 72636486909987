import { domain } from '../d-man';
import { components } from './api/specs';
import { ObjectList } from './ObjectList';

type schemas = components['schemas'];

export type Unit = {
    id: string;
    description: string;
};

export type UnitDTO = schemas['Unit'];

export function useCreateUnit() {
    return domain.usePost<UnitDTO, null>('/units');
}

export function useGetUnits(skip?: number, take?: number) {
    return domain.useGet<ObjectList<Unit>>('/units', {
        requestConfig: {
            queryParams: {
                skip,
                take,
            },
        },
    });
}
