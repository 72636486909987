import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { debounce } from 'throttle-debounce';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';
import { TableData, TableHeader, TableRow } from '../../components/Table';
import { TrashCan, Loader, Error } from '../../components/Icons';
import { Project, ProjectAddress, useCreateProject, useGetProjects } from '../../queries/Projects';
import { domain } from '../../d-man';
import Chips from '../../components/Chips';
import { Company } from '../../queries/Companies';
import { useGetBusinessUnits } from '../../queries/BusinessUnits';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';
import Modal from '../../components/Modal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Projects(props: RouteComponentProps) {
    const DEBOUNCE_DELAY = 1000;

    const businessUnits = useGetBusinessUnits();

    const newProject = useCreateProject();
    const [openAddressModal, setOpenAddressModal] = useState(false);

    const [projectNameSearch, setProjectNameSearch] = useState(undefined as string | undefined);
    const projects = useGetProjects(undefined, undefined, undefined, projectNameSearch);
    const projectsRef = useRef(projects);

    const [isLoading, setIsLoading] = useState(false);
    const [projectErrorMessage, setErrorMessage] = useState('');
    const [name, setName] = useState('');
    const [jobCode, setJobCode] = useState('');
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [vats, setVats] = useState<string[]>([]);
    const [businessUnitId, setbusinessUnitId] = useState('');
    const [addresses, setAddresses] = useState(undefined as ProjectAddress[] | undefined);
    const [tel, setTel] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [cell, setCell] = useState('');
    const [isOverhead, setIsOverhead] = useState(false);

    // address
    const [addressIndex, setAddressIndex] = useState(-1);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [postCode, setPostCode] = useState('');

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        if (newProject.error) {
            setErrorMessage(newProject.error || 'Failed to create new project');
        }
    }, [newProject.error]);

    useEffect(() => {
        projectsRef.current.reset();
    }, [projectsRef]);

    const debounced = useRef(
        debounce(DEBOUNCE_DELAY, (newProjectNameSearch?: string) => {
            setProjectNameSearch(newProjectNameSearch);
            projects.reset();
        })
    );

    useEffect(() => {
        // setSkip(0);
        if (projectNameSearch !== undefined) {
            debounced.current(projectNameSearch);
        } else {
            projectsRef.current.reset();
        }
    }, [projectNameSearch, debounced, projectsRef]);

    const addAddress = (i: number) => {
        setOpenAddressModal(true);
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setPostCode('');
        setAddressIndex(-1);

        if (i > -1 && addresses && addresses?.length > i) {
            const address = addresses[i];
            setAddressIndex(i);
            setAddress1(address.address1 || '');
            setAddress2(address.address2 || '');
            setAddress3(address.address3 || '');
            setPostCode(address.postCode || '');
        }
    };

    const upsertAddress = () => {
        const tempAddresses = addresses || [];
        if (addressIndex > -1) {
            tempAddresses[addressIndex].address1 = address1;
            tempAddresses[addressIndex].address2 = address2;
            tempAddresses[addressIndex].address3 = address3;
            tempAddresses[addressIndex].postCode = postCode;
            setAddresses(tempAddresses);
        } else {
            const newAddress: ProjectAddress = {
                address1,
                address2,
                address3,
                postCode,
            };
            const newAddresses = tempAddresses.concat(newAddress);
            setAddresses(newAddresses);
        }

        setOpenAddressModal(false);
    };

    const deleteAddress = (index: number) => {
        const tempAddresses = addresses || [];
        setAddresses(tempAddresses.filter((a, i) => i !== index));
        setOpenAddressModal(false);
    };

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');
        if (!name || vats.length === 0 || currencies.length === 0) {
            setErrorMessage('Please capture all the fields above');
        } else {
            await newProject.execute({
                name,
                jobCode,
                businessUnitId,
                currencies,
                vats: vats.map((i) => parseFloat(i)),
                addresses,
                cell,
                contactPerson,
                email,
                fax,
                tel,
                isOverhead,
            });
            projects.reset();
        }
        setIsLoading(false);
    };

    const toDetail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.navigate) {
            const { id } = (event.target as unknown) as { id: string };
            props.navigate(`/projects/${id}`);
        }
    };

    const clearFields = () => {
        setErrorMessage('');
        setName('');
        setJobCode('');
        setCurrencies([]);
        setVats([]);
    };

    const deleteProject = async (id?: string) => {
        const remove = domain.delete(`/projects/${id}`);
        await remove.execute();
        projects.reset();
    };

    /**
     * Formats the companies returned from the server for the
     * Typeahead component to render
     */
    const formattedCustomers = () => {
        if (businessUnits && businessUnits.data && businessUnits.data.items.length > 0) {
            return businessUnits.data.items.map((company: Company) => ({
                value: company.id as string,
                label: company.name as string,
            }));
        }
        return [];
    };

    /**
     * Triggers when the user click a search option
     * @param event
     */
    const handleSelectedItem = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = (event.target as unknown) as { value: string };
        setbusinessUnitId(value);
    };

    const renderProjects = () => {
        if (projects.loading) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Projects from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (projects.error) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Project list from the server ({projects.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (projects.data) {
            return projects.data.items.map((project: Project) => (
                <TableRow key={project.id} hover>
                    <TableData id={project.id} onClick={(event) => toDetail(event)}>
                        {project.jobCode} - {project.name}
                    </TableData>
                    <TableData id={project.id} onClick={(event) => toDetail(event)}>
                        {project.businessUnit?.name}
                    </TableData>
                    <TableData id={project.id} onClick={(event) => toDetail(event)}>
                        {project.contactPerson}
                    </TableData>
                    <TableData id={project.id} onClick={(event) => toDetail(event)}>
                        {project.cell}
                    </TableData>
                    <TableData id={project.id} onClick={(event) => toDetail(event)}>
                        <Checkbox value={project?.isOverhead} disabled />
                    </TableData>
                    <TableData>
                        <TrashCan
                            className="cursor-pointer"
                            id={project.id}
                            onClick={() => deleteProject(project.id)}
                        />
                    </TableData>
                </TableRow>
            ));
        }
        return null;
    };

    return (
        <div className="flex">
            <Modal
                title="Address"
                onClose={() => setOpenAddressModal(false)}
                isOpen={openAddressModal}
                onAction={upsertAddress}
            >
                <div>
                    <div className="flex justify-between">
                        <div>
                            <div className="mb-5">
                                <Input
                                    label="Address Line 1"
                                    value={address1 || ''}
                                    type="text"
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address Line 2"
                                    value={address2 || ''}
                                    type="text"
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address Line 3"
                                    value={address3 || ''}
                                    type="text"
                                    onChange={(e) => setAddress3(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Post Code"
                                    value={postCode || ''}
                                    type="text"
                                    onChange={(e) => setPostCode(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">
                <div className="text-sm p-3 font-bold">Add New Project</div>
                <hr />
                <div className="p-3">
                    <div className="mb-5">
                        <Select
                            placeholder="Search"
                            options={formattedCustomers()}
                            onChange={(event) => handleSelectedItem(event)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Name"
                            value={name}
                            type="text"
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Job Code"
                            value={jobCode}
                            type="text"
                            onChange={(event) => setJobCode(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Chips
                            label="Currencies"
                            value={currencies}
                            type="text"
                            onChange={setCurrencies}
                        />
                    </div>
                    <div className="mb-5">
                        <Chips label="Vats" value={vats} type="text" onChange={setVats} />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Tel"
                            value={tel}
                            type="text"
                            onChange={(event) => setTel(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Fax"
                            value={fax}
                            type="text"
                            onChange={(event) => setFax(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Email"
                            value={email}
                            type="text"
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Cell"
                            value={cell}
                            type="text"
                            onChange={(event) => setCell(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Contact Person"
                            value={contactPerson}
                            type="text"
                            onChange={(event) => setContactPerson(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <div className="flex justify-between mb-5">
                            <div className="text-sm self-center">Addresses</div>
                            <Button onClick={() => addAddress(-1)}>Add</Button>
                        </div>
                        <hr />
                        <div className="mt-5 space-y-5">
                            {addresses &&
                                addresses.map((a, i) => (
                                    <>
                                        <div className="flex justify-between mb-5">
                                            <div className="text-sm self-center font-semibold">
                                                {' '}
                                                Address {i + 1}
                                            </div>
                                            <div className="flex space-x-2">
                                                <Button onClick={() => addAddress(i)}>Edit</Button>
                                                <Button onClick={() => deleteAddress(i)}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="mb-5">
                                            <Input
                                                label="Address Line 1"
                                                value={a.address1 || ''}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <Input
                                                label="Address Line 2"
                                                value={a.address2 || ''}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <Input
                                                label="Address Line 3"
                                                value={a.address3 || ''}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-5">
                                            <Input
                                                label="Post Code"
                                                value={a.postCode || ''}
                                                type="text"
                                                disabled
                                            />
                                        </div>
                                    </>
                                ))}
                        </div>
                    </div>
                    <div className="">
                        <Checkbox
                            label="Overhead"
                            name="overheads"
                            onChange={() => setIsOverhead(!isOverhead)}
                            value={isOverhead}
                            disabled={false}
                        />
                    </div>
                </div>
                <hr />
                <div className="p-3">
                    <div className="flex mb-5 text-red-500 text-xs">{projectErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2">
                            <Button onClick={clearFields}>Clear</Button>
                        </div>
                        <div>
                            <Button onClick={post}>
                                {!isLoading ? 'Submit' : <Loader width={2} height={2} />}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-3/4 h-full m-4">
                <div className="mb-5">
                    <Input
                        value={projectNameSearch}
                        label="Search Project Name"
                        placeholder="Search Project Name"
                        type="text"
                        onChange={(event) =>
                            setProjectNameSearch(
                                event.target.value === '' ? undefined : event.target.value
                            )
                        }
                        dark
                    />
                </div>
                <table className="table-auto bg-white w-full">
                    <thead>
                        <TableRow>
                            <TableHeader>Project Name</TableHeader>
                            <TableHeader>Business Unit</TableHeader>
                            <TableHeader>Site Contact Name</TableHeader>
                            <TableHeader>Site Contact Cell</TableHeader>
                            <TableHeader>Overheads</TableHeader>
                            <TableHeader />
                        </TableRow>
                    </thead>
                    <tbody>{renderProjects()}</tbody>
                </table>
            </div>
        </div>
    );
}
