/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useParams, RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import { Error, Loader, TrashCan } from '../../components/Icons';
import { useGetProject, useEditProjects, ProjectDTO, ProjectAddress } from '../../queries/Projects';
import { Tab, TabsContainer } from '../../components/Tabs';
import {
    Role,
    useCreateUserRole,
    useDeleteUserRoles,
    useGetRoles,
    useGetUserRolesByRoleDTO,
    UserRole,
} from '../../queries/Roles';
import TypeAhead from '../../components/TypeAhead';
import Button from '../../components/Button';
import Chips from '../../components/Chips';
import { useGetUsers, User } from '../../queries/Users';
import { TableData, TableHeader, TableRow } from '../../components/Table';
import { useGetPermissions } from '../../queries/Permissions';
import Modal from '../../components/Modal';
import Checkbox from '../../components/Checkbox';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ProjectDetail(props: RouteComponentProps) {
    const params = useParams();
    const projectId = params.id;

    const newUserRole = useCreateUserRole();
    const deleteUserRoles = useDeleteUserRoles();
    const project = useGetProject(projectId);
    const roles = useGetRoles();
    const users = useGetUsers();
    const usersRef = useRef(users);

    useEffect(() => {
        usersRef.current.reset();
    }, []);

    const editProject = useEditProjects();

    const [tabs, setTabs] = useState([] as Tab[]);
    const [selectedTab, setSelectedTab] = useState('');

    const [openAddressModal, setOpenAddressModal] = useState(false);

    const userRoles = useGetUserRolesByRoleDTO({
        projectId,
        roleName: selectedTab,
    });
    const userRolesRef = useRef(userRoles);
    const [isLoading, setIsLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const { data } = project;
    const [name, setName] = useState(undefined as string | undefined);
    const [jobCode, setJobCode] = useState(undefined as string | undefined);
    const [currencies, setCurrencies] = useState(undefined as string[] | undefined);
    const [vats, setVats] = useState(undefined as string[] | undefined);
    const [addresses, setAddresses] = useState(undefined as ProjectAddress[] | undefined);
    const [tel, setTel] = useState(undefined as string | undefined);
    const [fax, setFax] = useState(undefined as string | undefined);
    const [email, setEmail] = useState(undefined as string | undefined);
    const [cell, setCell] = useState(undefined as string | undefined);
    const [contactPerson, setContactPerson] = useState(undefined as string | undefined);

    // address
    const [addressIndex, setAddressIndex] = useState(-1);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [postCode, setPostCode] = useState('');

    const [isOverhead, setIsOverhead] = useState(false);

    const projectRef = useRef(project);

    const getPermissions = useGetPermissions('PROJECT', projectId);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        setName(project.data?.name);
        setJobCode(project.data?.jobCode);
        setCurrencies(project.data?.currencies?.map((i) => i.symbol) as string[]);
        setVats(project.data?.vats?.map((i) => i.amount?.toString()) as string[]);
        setCell(project.data?.cell);
        setContactPerson(project.data?.contactPerson);
        setAddresses(project.data?.addresses);
        setTel(project.data?.tel);
        setFax(project.data?.fax);
        setEmail(project.data?.email);
        setIsOverhead(project.data?.isOverhead || false);
    }, [project.data]);

    useEffect(() => {
        projectRef.current.reset();
    }, []);

    useEffect(() => {
        projectRef.current.reset();
    }, []);

    useEffect(() => {
        if (roles.data && tabs.length === 0) {
            const appRoles = roles.data
                .filter((f: Role) => f.scope === 'PROJECT') // project scoped
                .sort((a: Role, b: Role) => a.displayOrder - b.displayOrder)
                .map((roleDto: Role) => {
                    return { label: roleDto.name, id: roleDto.name };
                });
            setTabs(appRoles);
            if (appRoles.length > 0) {
                setSelectedTab(appRoles[0].label);
            }
        }
    }, [tabs, roles.data, userRolesRef]);

    useEffect(() => {
        userRolesRef.current.reset();
    }, [selectedTab, userRolesRef]);

    const [selectedUser, setSelectedUser] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const addAddress = (i: number) => {
        setOpenAddressModal(true);
        setAddress1('');
        setAddress2('');
        setAddress3('');
        setPostCode('');
        setAddressIndex(-1);

        if (i > -1 && addresses && addresses?.length > i) {
            const address = addresses[i];
            setAddressIndex(i);
            setAddress1(address.address1 || '');
            setAddress2(address.address2 || '');
            setAddress3(address.address3 || '');
            setPostCode(address.postCode || '');
        }
    };

    const upsertAddress = () => {
        const tempAddresses = addresses || [];
        if (addressIndex > -1) {
            tempAddresses[addressIndex].address1 = address1;
            tempAddresses[addressIndex].address2 = address2;
            tempAddresses[addressIndex].address3 = address3;
            tempAddresses[addressIndex].postCode = postCode;
            setAddresses(tempAddresses);
        } else {
            const newAddress: ProjectAddress = {
                address1,
                address2,
                address3,
                postCode,
            };
            const newAddresses = tempAddresses.concat(newAddress);
            setAddresses(newAddresses);
        }

        setOpenAddressModal(false);
    };

    const deleteAddress = (index: number) => {
        const tempAddresses = addresses || [];
        setAddresses(tempAddresses.filter((a, i) => i !== index));
        setOpenAddressModal(false);
    };

    const formatUsers = () => {
        if (users && users.data) {
            return users.data.map((user: User) => ({
                id: user.id,
                name: `${user.userName}${
                    user.firstName && user.lastName ? ` (${user.firstName} ${user.lastName})` : ''
                }`,
            }));
        }
        return [];
    };

    const handleSelectedItem = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { id } = (event.target as unknown) as { id: string };
        setSelectedUser(id);
    };

    const post = async () => {
        setErrorMessage('');
        if (!selectedUser) {
            setErrorMessage('Please capture a User Name');
        } else {
            const user = users.data?.find((u: User) => u.id === selectedUser);
            if (!user) return;
            await newUserRole.execute({
                userName: user.userName,
                projectId,
                businessUnitId: project.data?.businessUnit?.id,
                roleName: selectedTab,
            });
            userRoles.reset();
            users.reset();
        }
    };

    const deleteUserRole = async (userRole: UserRole) => {
        await deleteUserRoles.execute(userRole);
        userRoles.reset();
    };

    const clearFields = () => {
        setErrorMessage('');
        setSelectedUser('');
    };

    const put = async () => {
        setIsLoading(true);
        setErrorMessage('');
        const projectDTO = {
            name,
            currencies,
            vats: vats?.map((i) => parseFloat(i)),
            jobCode,
            id: project.data?.id,
            businessUnitId: project.data?.businessUnit?.id,
            addresses,
            cell,
            contactPerson,
            email,
            fax,
            tel,
            isOverhead,
        } as ProjectDTO & { id: string };
        await editProject.execute(projectDTO);
        project.reset();
        setIsLoading(false);
        setEditing(false);
    };

    const handleEdit = () => {
        setEditing(true);
    };

    const cancelEdit = () => {
        setEditing(false);
        project.reset();
    };

    const renderUserRoles = () => {
        if (userRoles.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={5}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching UserRoles from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (userRoles.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={4}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch UserRole list from the server ({userRoles.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (userRoles.data) {
            return userRoles.data.map((userRole: UserRole) => (
                <TableRow hover>
                    <TableData>{userRole.userName}</TableData>
                    <TableData>{userRole.roleName}</TableData>
                    <TableData onClick={() => deleteUserRole(userRole)}>
                        <TrashCan className="cursor-pointer flex" id={userRole.userName} />
                    </TableData>
                </TableRow>
            ));
        }
        return (
            <tr>
                <td className="border p-2" colSpan={4}>
                    <div className="flex flex-col items-center">
                        <div className="pt-3 pb-3">
                            <svg
                                className="w-8 h-8 text-orange-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <div className="text-sm">
                            There are currently no UserRoles. Please add one.
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    const renderProject = () => {
        if (!project || project.loading) {
            return (
                <div>
                    <div className="flex justify-center">
                        <div className="w-48 h-full">
                            <Loader />
                            <div className="text-xs text-center">Fetching Project from server</div>
                        </div>
                    </div>
                </div>
            );
        }
        if (project.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Project from the server ({project.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (data) {
            const { id } = data;
            return (
                <>
                    <div className="text-sm p-3 font-bold">Project Information</div>
                    <hr />
                    <div className="p-3">
                        <div className="mb-5" hidden>
                            <Input label="id" value={id || ''} disabled />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Name"
                                value={name}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Job Code"
                                value={jobCode}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setJobCode(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Chips
                                label="Currencies"
                                value={currencies}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setCurrencies(e)}
                            />
                        </div>
                        <div className="mb-5">
                            <Chips
                                label="VAT"
                                value={vats}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setVats(e)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Tel"
                                value={tel || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setTel(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Fax"
                                value={fax || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setFax(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Email"
                                value={email || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Cell"
                                value={cell || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setCell(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <Input
                                label="Contact Person"
                                value={contactPerson || ''}
                                type="text"
                                disabled={!editing}
                                onChange={(e) => setContactPerson(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <div className="flex justify-between mb-5">
                                <div className="text-sm self-center">Addresses</div>
                                {editing && <Button onClick={() => addAddress(-1)}>Add</Button>}
                            </div>
                            <hr />
                            <div className="mt-5 space-y-5">
                                {addresses &&
                                    addresses.map((a, i) => (
                                        <>
                                            <div className="flex justify-between mb-5">
                                                <div className="text-sm self-center font-semibold">
                                                    {' '}
                                                    Address {i + 1}
                                                </div>
                                                <div className="flex space-x-2">
                                                    {editing && (
                                                        <Button onClick={() => addAddress(i)}>
                                                            Edit
                                                        </Button>
                                                    )}
                                                    {editing && (
                                                        <Button onClick={() => deleteAddress(i)}>
                                                            Delete
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-5">
                                                <Input
                                                    label="Address Line 1"
                                                    value={a.address1 || ''}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-5">
                                                <Input
                                                    label="Address Line 2"
                                                    value={a.address2 || ''}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-5">
                                                <Input
                                                    label="Address Line 3"
                                                    value={a.address3 || ''}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                            <div className="mb-5">
                                                <Input
                                                    label="Post Code"
                                                    value={a.postCode || ''}
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </>
                                    ))}
                            </div>
                        </div>
                        <div className="py-4">
                            <Checkbox
                                label="Overhead"
                                name="overheads"
                                onChange={() => setIsOverhead(!isOverhead)}
                                value={isOverhead}
                                disabled={!editing}
                            />
                        </div>
                        <div className="flex flex-row-reverse">
                            {editing && (
                                <div className="pl-2">
                                    <Button onClick={put}>
                                        {!isLoading ? 'Submit' : <div>Loading</div>}
                                    </Button>
                                </div>
                            )}
                            {!editing && (
                                <div>
                                    <Button onClick={handleEdit}>Edit</Button>
                                </div>
                            )}
                            {editing && (
                                <div>
                                    <Button onClick={cancelEdit}>Cancel</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <div className="flex">
            <Modal
                title="Address"
                onClose={() => setOpenAddressModal(false)}
                isOpen={openAddressModal}
                onAction={upsertAddress}
            >
                <div>
                    <div className="flex justify-between">
                        <div>
                            <div className="mb-5">
                                <Input
                                    label="Address Line 1"
                                    value={address1 || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAddress1(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address Line 2"
                                    value={address2 || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAddress2(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address Line 3"
                                    value={address3 || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setAddress3(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Post Code"
                                    value={postCode || ''}
                                    type="text"
                                    disabled={!editing}
                                    onChange={(e) => setPostCode(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className="bg-gray-400 border w-1/4 rounded-md h-full m-4">{renderProject()}</div>
            <div className="bg-gray-400 border w-3/4 rounded-md h-full m-4">
                <div className="text-sm p-3 font-bold">Project Roles</div>
                <hr />
                <div className="p-3">
                    <TabsContainer
                        selectedTab={selectedTab}
                        options={tabs}
                        onClick={setSelectedTab}
                    >
                        <div className="mt-5 w-full">
                            <div className="">
                                <TypeAhead
                                    placeholder="Search"
                                    caseSensitive={false}
                                    label="Users"
                                    options={formatUsers()}
                                    onClick={(event) => handleSelectedItem(event)}
                                />
                            </div>
                            <div className="mb-5">
                                <div className="flex mb-5 text-red-500 text-xs">{errorMessage}</div>
                                <div className="flex flex-row-reverse">
                                    <div className="pl-2">
                                        <Button onClick={clearFields}>Clear</Button>
                                    </div>
                                    <div>
                                        <Button onClick={post}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <table className="table-auto bg-white w-full">
                                    <thead>
                                        <tr>
                                            <TableHeader>User Name</TableHeader>
                                            <TableHeader>Role Name</TableHeader>
                                            <TableHeader />
                                        </tr>
                                    </thead>
                                    <tbody>{renderUserRoles()}</tbody>
                                </table>
                            </div>
                        </div>
                    </TabsContainer>
                </div>
            </div>
        </div>
    );
}
