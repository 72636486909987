import { AxiosResponse } from 'axios';

const SYMBOLS = {
    USD: '$',
    ZWL: 'Z$',
    ZAR: 'R',
    R: 'R',
};

export function forceDownload(blobUrl: string, filename: string) {
    const a = document.createElement('a');
    a.download = filename;
    a.href = blobUrl;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleWebDownload = async (data: any, response: AxiosResponse<any> | undefined) => {
    let fileName = 'unknown';
    if (response && response.headers && response.headers['content-disposition']) {
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
        }
    }
    const url = window.URL.createObjectURL(new Blob([data as BlobPart]));
    forceDownload(url, fileName);
};

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const fomatCurrencySymbol = (symbol: string) => {
    if (symbol === 'R' || symbol === 'ZAR') {
        return SYMBOLS.ZAR;
    }
    if (symbol === 'USD') {
        return SYMBOLS.USD;
    }
    if (symbol === 'ZWL') {
        return SYMBOLS.ZWL;
    }
    return symbol;
};

export const formatCurrencyAmount = (rawNum: number) => {
    const currencyFractionDigits = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'ZAR',
    }).resolvedOptions().maximumFractionDigits;

    return rawNum.toLocaleString('en-US', {
        minimumFractionDigits: currencyFractionDigits,
    });
};

export const handleClearFilterFromURL = (params: string, filterKey: string) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const urlQueryParams = new URLSearchParams(params);

    if (urlQueryParams.has(filterKey)) {
        urlQueryParams.delete(filterKey);
        const newUrl = `${url.origin}${url.pathname}?${urlQueryParams.toString()}`;
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', newUrl);
    }
};

export const readQueryParams = (params: string, filter: string) => {
    const urlQueryParams = new URLSearchParams(params);
    return urlQueryParams.get(filter);
};
