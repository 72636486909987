import React, { useEffect } from 'react';
import {
    useCurrentBusinessUnitId,
    useCurrentProjectId,
    useLocalUser,
} from '../../queries/Accounts';
import { BusinessUnit, useGetBusinessUnits, useLocalContext } from '../../queries/BusinessUnits';
import { Project, useGetProjects } from '../../queries/Projects';
import ProfileDropdown from './ProfileDropdown';
import Sidebar from './Sidebar';
import logo from '../CompanyLogo/0.png';

export type Route = {
    path: string;
    name: string;
    parent?: boolean;
    children?: Route[];
};

export default function Nav({ children, routes }: { children: React.ReactNode; routes: Route[] }) {
    const currentProjectId = useCurrentProjectId();
    const currentBusinessUnitId = useCurrentBusinessUnitId();

    const businessUnits = useGetBusinessUnits();
    const projects = useGetProjects(undefined, undefined, currentBusinessUnitId.data);

    const localContexts = useLocalContext();
    const localUser = useLocalUser();

    const localUserId = localUser.data?.userid;

    const userContext = localContexts.data?.find((c) => c.userId === localUserId);

    const handleSelectCurrentProject = (id: string) => {
        currentProjectId.dispatch(id);
        localContexts.dispatch({
            userId: localUserId,
            bussinessUnit: currentBusinessUnitId.data,
            project: id,
        });
    };

    const handleSelectCurrentBusinessUnit = (id: string) => {
        currentBusinessUnitId.dispatch(id);
        currentProjectId.dispatch(undefined);
        localContexts.dispatch({
            userId: localUserId,
            bussinessUnit: id,
            project: undefined,
        });
    };

    useEffect(() => {
        if (projects.data?.items.length && currentProjectId.data === undefined) {
            currentProjectId.dispatch(projects.data?.items[0].id || '');
        }

        if (businessUnits.data?.items.length && currentBusinessUnitId.data === undefined) {
            currentBusinessUnitId.dispatch(businessUnits.data?.items[0].id || '');
        }
    }, [projects.data, businessUnits.data, currentProjectId, currentBusinessUnitId]);

    return (
        <div>
            <div className="p-2 border-b-2 border-gray-900 shadow-sm bg-gray-700 text-white">
                <header className="flex justify-between">
                    <div className="flex justify-center items-center">
                        <div className="w-44">
                            <img src={logo} className="w-10 h-10 ml-1" alt="" />
                        </div>
                        {businessUnits && businessUnits.data && (
                            <>
                                <div className="ml-12">
                                    <b>Business Unit</b>
                                </div>
                                <select
                                    className="ml-5 text-gray-700"
                                    onChange={(e) =>
                                        handleSelectCurrentBusinessUnit(e.target.value)
                                    }
                                    value={userContext?.bussinessUnit}
                                    disabled={businessUnits.data.totalCount <= 1}
                                >
                                    {businessUnits.data.items.map((obj: BusinessUnit) => (
                                        <option key={obj.id} value={obj.id}>
                                            {obj.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                        {projects && projects.data && (
                            <>
                                <div className="ml-12">
                                    <b>Project</b>
                                </div>
                                <select
                                    className="ml-5 text-gray-700"
                                    onChange={(e) => handleSelectCurrentProject(e.target.value)}
                                    value={userContext?.project}
                                    disabled={projects.data.totalCount <= 1}
                                >
                                    {projects.data.items.map((obj: Project) => (
                                        <option key={obj.id} value={obj.id}>
                                            {`${obj.jobCode} - ${obj.name}`}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}
                    </div>
                    <ProfileDropdown />
                </header>
            </div>

            <div className="flex min-h-screen">
                <Sidebar routes={routes} />
                <main className="w-screen bg-gray-500">{children}</main>
            </div>
        </div>
    );
}
