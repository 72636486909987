import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import Input from '../../components/Input';
import Button from '../../components/Button';
import {
    BusinessUnit,
    useCreateBusinessUnit,
    useGetBusinessUnits,
} from '../../queries/BusinessUnits';
import { TrashCan, Error, Loader } from '../../components/Icons';
import { TableRow, TableData, TableHeader } from '../../components/Table';
import { domain } from '../../d-man';
import { Company, useGetCompanies } from '../../queries/Companies';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';
import Select from '../../components/Select';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function BusinessUnits(props: RouteComponentProps) {
    const businessUnits = useGetBusinessUnits();
    const companies = useGetCompanies();
    const businessUnitsRef = useRef(businessUnits);

    const newBusinessUnit = useCreateBusinessUnit();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [name, setName] = useState('');
    const [prefix, setPrefix] = useState('');
    const [defaultVat, setDefaultVat] = useState('');
    const [defaultCurrency, setDefaultCurrency] = useState('');
    const [prefixRequisition, setPrefixRequisition] = useState('');

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        businessUnitsRef.current.reset();
    }, []);

    useEffect(() => {
        if (newBusinessUnit.error) {
            setErrorMessage(newBusinessUnit.error || 'Failed to create new Business Unit');
        }
    }, [newBusinessUnit.error]);

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');

        if (
            !companyId ||
            !name ||
            !prefix ||
            !defaultVat ||
            !defaultCurrency ||
            !prefixRequisition
        ) {
            setErrorMessage('Please capture all the fields above');
        } else {
            const data = {
                name,
                prefix,
                defaultVat: parseFloat(defaultVat),
                defaultCurrency,
                companyId,
                logo: undefined,
                prefixRequisition,
            };
            await newBusinessUnit.execute(data);
            businessUnits.reset();
        }
        setIsLoading(false);
    };

    const toDetail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.navigate) {
            const { id } = (event.target as unknown) as { id: string };
            props.navigate(`/business-units/${id}`);
        }
    };

    const clearFields = () => {
        setErrorMessage('');
        setCompanyId('');
        setName('');
        setPrefix('');
        setDefaultVat('');
        setDefaultCurrency('');
        setPrefixRequisition('');
    };

    /**
     * Does an HTTP Delete to the server for the specified id
     * @param id
     */
    const deleteBusinessUnit = async (id?: string) => {
        const remove = domain.delete(`/businessunits/${id}`);
        await remove.execute();
        businessUnits.reset();
    };

    /**
     * Formats the companies returned from the server for the
     * Typeahead component to render
     */
    const formattedCustomers = () => {
        if (companies && companies.data && companies.data.items.length > 0) {
            return companies.data.items.map((company: Company) => ({
                value: company.id as string,
                label: company.name as string,
            }));
        }
        return [];
    };

    /**
     * Triggers when the user click a search option
     * @param event
     */
    const handleSelectedItem = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = (event.target as unknown) as { value: string };
        setCompanyId(value);
    };

    const renderBusinessUnits = () => {
        if (businessUnits.loading) {
            return (
                <tr>
                    <td className="border p-2" colSpan={5}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Business Units from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (businessUnits.error) {
            return (
                <tr>
                    <td className="border p-2" colSpan={4}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Business Units list from the server (
                                {businessUnits.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (businessUnits.data) {
            return businessUnits.data.items.map((businessUnit: BusinessUnit) => (
                <TableRow hover key={businessUnit.id}>
                    <TableData id={businessUnit.id} onClick={(event) => toDetail(event)}>
                        {businessUnit.company?.name}
                    </TableData>
                    <TableData id={businessUnit.id} onClick={(event) => toDetail(event)}>
                        {businessUnit.name}
                    </TableData>
                    <TableData id={businessUnit.id} onClick={(event) => toDetail(event)}>
                        {businessUnit.prefix}
                    </TableData>
                    <TableData id={businessUnit.id} onClick={(event) => toDetail(event)}>
                        {businessUnit.prefixRequisition}
                    </TableData>
                    <TableData id={businessUnit.id} onClick={(event) => toDetail(event)}>
                        {businessUnit.defaultVat?.amount}
                    </TableData>
                    <TableData id={businessUnit.id} onClick={(event) => toDetail(event)}>
                        {businessUnit.defaultCurrency?.symbol}
                    </TableData>
                    <TableData>
                        <TrashCan
                            className="cursor-pointer flex"
                            id={businessUnit.id}
                            onClick={() => deleteBusinessUnit(businessUnit.id)}
                        />
                    </TableData>
                </TableRow>
            ));
        }
        return (
            <tr>
                <td className="border p-2" colSpan={4}>
                    <div className="flex flex-col items-center">
                        <div className="pt-3 pb-3">
                            <svg
                                className="w-8 h-8 text-orange-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        </div>
                        <div className="text-sm">
                            There are no Business Units, please create one
                        </div>
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <div className="flex">
            <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">
                <div className="text-sm p-3 font-bold">New Business Unit</div>
                <hr />
                <div className="p-3">
                    <div className="mb-5">
                        <Select
                            placeholder="Select Company"
                            options={formattedCustomers()}
                            onChange={(event) => handleSelectedItem(event)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Name"
                            value={name}
                            type="text"
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Order Prefix"
                            value={prefix}
                            type="text"
                            onChange={(event) => setPrefix(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Requisition Prefix"
                            value={prefixRequisition}
                            type="text"
                            onChange={(event) => setPrefixRequisition(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Default VAT"
                            value={defaultVat}
                            type="text"
                            onChange={(event) => setDefaultVat(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Default Currency"
                            value={defaultCurrency}
                            type="text"
                            onChange={(event) => setDefaultCurrency(event.target.value)}
                        />
                    </div>
                </div>
                <hr />
                <div className="p-3">
                    <div className="flex mb-5 text-red-500">{errorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2">
                            <Button onClick={clearFields}>Clear</Button>
                        </div>
                        <div>
                            <Button onClick={post}>
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-3/4 h-full m-4">
                <table className="table-auto bg-white w-full">
                    <thead>
                        <tr>
                            <TableHeader>Company</TableHeader>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Prefix Order</TableHeader>
                            <TableHeader>Prefix Requisition</TableHeader>
                            <TableHeader>Default Vat</TableHeader>
                            <TableHeader>Default Currency</TableHeader>
                            <TableHeader />
                        </tr>
                    </thead>
                    <tbody>{renderBusinessUnits()}</tbody>
                </table>
            </div>
        </div>
    );
}
