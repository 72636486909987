import { Link, useLocation } from '@reach/router';
import React from 'react';
import clsx from 'clsx';
import NavItemIcon from './NavIcon';

type Route = {
    path: string;
    name: string;
    parent?: boolean;
    children?: Route[];
};

function NavItem({ route, isChild }: { route: Route; isChild?: boolean }) {
    const location = useLocation();

    const renderIcon = (routeObj: {
        name: string;
        path: string;
        parent?: boolean;
        children?: { name: string; path: string }[];
    }) => {
        return <NavItemIcon route={routeObj.name} />;
    };

    const hasChildren = route.children && route.children.length > 0;

    const isActive = route.path === location.pathname;

    const content = (
        <>
            <div className="mr-3 ml-3">{renderIcon(route)}</div>
            <div
                className={clsx('text-sm', {
                    'font-bold': isActive,
                })}
            >
                {route.name}
            </div>
        </>
    );

    return (
        <>
            {hasChildren ? (
                <div className={clsx('flex items-center h-12', { 'pl-9': isChild })}>{content}</div>
            ) : (
                <Link
                    className={clsx(
                        'flex items-center hover:bg-gray-100 hover:text-gray-900 h-12',
                        {
                            'pl-9': isChild,
                        }
                    )}
                    to={route.path}
                >
                    {content}
                </Link>
            )}
            {route.children?.map((c) => (
                <NavItem route={c} key={c.name} isChild />
            ))}
        </>
    );
}

export default function Sidebar({ routes }: { routes: Route[] }) {
    return (
        <nav className="w-60 border-r border-gray-900 bg-gray-900 text-white">
            <div className="pt-1 pb-1">
                {routes.map((route) => {
                    return <NavItem key={route.name} route={route} />;
                })}
            </div>
        </nav>
    );
}
