import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { debounce } from 'throttle-debounce';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import { TableData, TableHeader, TableRow } from '../../components/Table';
import { Loader, TrashCan, Error } from '../../components/Icons';
import { domain } from '../../d-man';
import { Creditor, useCreateCreditor, useGetCreditors } from '../../queries/Creditors';
import { Company, useGetCompanies } from '../../queries/Companies';
import Pager from '../../components/Pager';
import { useCurrentProjectId } from '../../queries/Accounts';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Creditors(props: RouteComponentProps) {
    const PAGE_SIZE = 30;
    const DEBOUNCE_DELAY = 1000;
    const [skip, setSkip] = useState(0);
    const [creditorNameSearch, setCreditorNameSearch] = useState(undefined as string | undefined);
    const [creditorCodeSearch, setCreditorCodeSearch] = useState(undefined as string | undefined);
    const [companyIdSearch, setCompanyIdSearch] = useState('All' as string | undefined);

    const getCompaniesService = useGetCompanies();

    const newCreditor = useCreateCreditor();

    const [isLoading, setIsLoading] = useState(false);
    const [creditorErrorMessage, setErrorMessage] = useState('');

    const [name, setCreditorName] = useState('');
    const [code, setCreditorCode] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [postCode, setPostCode] = useState('');
    const [tel, setTel] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [URL, setURL] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [cell, setCell] = useState('');
    const [vatNo, setVatNo] = useState('');
    const [discount, setDiscount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [agingCategory, setAgingCategory] = useState('');
    const [beeStatus, setBEEStatus] = useState('');
    const [beeType, setBEEType] = useState('');
    const [beeLevel, setBEELevel] = useState('');
    // const [beeVav, setBEEVav] = useState(false);
    const [province, setProvince] = useState('');
    const [companyId, setCompanyId] = useState(undefined as string | undefined);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);

    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Creditor_Admin')
            ?.hasAccess &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Creditor_Read_Only')
            ?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const shouldGetCompanies = () => {
        if (companyIdSearch === 'All') return undefined;
        return companyIdSearch;
    };

    const getCreditorsService = useGetCreditors(
        skip,
        PAGE_SIZE,
        creditorNameSearch,
        creditorCodeSearch,
        shouldGetCompanies()
    );

    const creditorsRef = useRef(getCreditorsService);

    useEffect(() => {
        if (newCreditor.error) {
            setErrorMessage(newCreditor.error || 'Failed to create new creditor');
        }
    }, [newCreditor.error]);

    useEffect(() => {
        creditorsRef.current.reset();
    }, [creditorsRef]);

    const toDetail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.navigate) {
            const { id } = (event.target as unknown) as { id: string };
            props.navigate(`/creditors/${id}`);
        }
    };

    const debounced = useRef(
        debounce(
            DEBOUNCE_DELAY,
            (
                newCreditorNameSearch?: string,
                newCreditorCodeSearch?: string,
                newCompanyIdSearch?: string
            ) => {
                setCreditorNameSearch(newCreditorNameSearch);
                setCreditorCodeSearch(newCreditorCodeSearch);
                setCompanyIdSearch(newCompanyIdSearch);
                getCreditorsService.reset();
            }
        )
    );

    useEffect(() => {
        setSkip(0);
        if (
            creditorNameSearch !== undefined ||
            creditorCodeSearch !== undefined ||
            companyIdSearch !== undefined
        ) {
            debounced.current(creditorNameSearch, creditorCodeSearch, companyIdSearch);
        } else {
            creditorsRef.current.reset();
        }
    }, [creditorNameSearch, debounced, creditorsRef, creditorCodeSearch, companyIdSearch]);

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');
        if (!name || !code || !companyId) {
            setErrorMessage('Please capture all the fields above');
        } else {
            await newCreditor.execute({
                name,
                code,
                address1,
                address2,
                address3,
                postCode,
                tel,
                fax,
                email,
                url: URL,
                contactPerson,
                cell,
                vatNo,
                discount,
                paymentMethod,
                agingCategory,
                beeStatus,
                beeType,
                beeLevel,
                // beeVav,
                province,
                companyId,
            });
            getCreditorsService.reset();
        }
        setIsLoading(false);
    };

    const clearFields = () => {
        setErrorMessage('');
        setCreditorName('');
        setCreditorCode('');
    };

    const deleteCreditor = async (id: string) => {
        const remove = domain.delete(`/creditors/${id}`);
        await remove.execute();
        getCreditorsService.reset();
    };

    const renderCreditors = () => {
        if (getCreditorsService.loading) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Loader className="pt-3 pb-3" />
                            <div className="text-sm">Fetching Creditors from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (getCreditorsService.error) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <Error className="pt-3 pb-3" />
                            <div className="text-sm">
                                Failed to fetch Creditor list from the server (
                                {getCreditorsService.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (getCreditorsService.data) {
            const creditors = getCreditorsService.data.items;
            return (
                creditors &&
                creditors.map((creditor: Creditor) => (
                    <TableRow hover>
                        <TableData id={creditor.id} onClick={(event) => toDetail(event)}>
                            {creditor.name}
                        </TableData>
                        <TableData id={creditor.company?.id} onClick={(event) => toDetail(event)}>
                            {creditor.company?.displayName}
                        </TableData>
                        <TableData id={creditor.id} onClick={(event) => toDetail(event)}>
                            {creditor.code}
                        </TableData>
                        <TableData>
                            <TrashCan
                                className="cursor-pointer flex items-center"
                                id={creditor.id}
                                onClick={() => deleteCreditor(creditor.id as string)}
                            />
                        </TableData>
                    </TableRow>
                ))
            );
        }
        return null;
    };

    const formatCompanies = (search = false) => {
        if (getCompaniesService && getCompaniesService.data && getCompaniesService.data.items) {
            const selectAll = [{ value: '', label: 'All' }];
            const filteredCompanies = getCompaniesService.data.items.map(
                (companyItem: Company) =>
                    ({
                        value: companyItem.id,
                        label: companyItem.displayName,
                    } as { value: string; label: string })
            );
            return search ? selectAll.concat(filteredCompanies) : filteredCompanies;
        }
        return [];
    };

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        getCreditorsService.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        getCreditorsService.reset();
    };

    return (
        <div className="flex">
            {/* Add creditor */}
            {!getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Creditor_Read_Only')
                ?.hasAccess && (
                <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">
                    <div className="text-sm p-3 font-bold">Add New Creditor</div>
                    <hr />
                    <div className="flex justify-between p-3">
                        <div>
                            <div className="mb-5">
                                <Input
                                    label="Name"
                                    value={name}
                                    type="text"
                                    onChange={(event) => setCreditorName(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Code"
                                    value={code}
                                    type="text"
                                    onChange={(event) => setCreditorCode(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address 1"
                                    value={address1}
                                    type="text"
                                    onChange={(event) => setAddress1(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address 2"
                                    value={address2}
                                    type="text"
                                    onChange={(event) => setAddress2(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Address 3"
                                    value={address3}
                                    type="text"
                                    onChange={(event) => setAddress3(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="PostCode"
                                    value={postCode}
                                    type="text"
                                    onChange={(event) => setPostCode(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Tel"
                                    value={tel}
                                    type="text"
                                    onChange={(event) => setTel(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Fax"
                                    value={fax}
                                    type="text"
                                    onChange={(event) => setFax(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Email"
                                    value={email}
                                    type="text"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="URL"
                                    value={URL}
                                    type="text"
                                    onChange={(event) => setURL(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="ml-5">
                            <div className="mb-5">
                                <Input
                                    label="Cell"
                                    value={cell}
                                    type="text"
                                    onChange={(event) => setCell(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Contact Person"
                                    value={contactPerson}
                                    type="text"
                                    onChange={(event) => setContactPerson(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="VAT Number"
                                    value={vatNo}
                                    type="text"
                                    onChange={(event) => setVatNo(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Discount"
                                    value={discount.toString()}
                                    type="number"
                                    onChange={(event) =>
                                        setDiscount(parseFloat(event.target.value))
                                    }
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Payment Method"
                                    value={paymentMethod}
                                    type="text"
                                    onChange={(event) => setPaymentMethod(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="Aging Category"
                                    value={agingCategory}
                                    type="text"
                                    onChange={(event) => setAgingCategory(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="BEE Status"
                                    value={beeStatus}
                                    type="text"
                                    onChange={(event) => setBEEStatus(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="BEE Type"
                                    value={beeType}
                                    type="text"
                                    onChange={(event) => setBEEType(event.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <Input
                                    label="BEE Level"
                                    value={beeLevel}
                                    type="number"
                                    onChange={(event) => setBEELevel(event.target.value)}
                                />
                            </div>
                            <div className="mt-0">
                                <Input
                                    label="Province"
                                    value={province}
                                    type="text"
                                    onChange={(event) => setProvince(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-5 w-full px-3">
                        <Select
                            label="Company"
                            disabled={getCompaniesService.loading}
                            placeholder="Select Company"
                            options={formatCompanies()}
                            onChange={(event) => setCompanyId(event.target.value)}
                            dark
                        />
                    </div>
                    <hr />
                    <div className="p-3">
                        <div className="flex mb-5 text-red-500">{creditorErrorMessage}</div>
                        <div className="flex flex-row-reverse">
                            <div className="pl-2">
                                <Button onClick={clearFields}>Clear</Button>
                            </div>
                            <div>
                                <Button onClick={post}>
                                    {!isLoading ? 'Submit' : <div>Loading</div>}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*  */}
            <div className="h-full w-3/4 m-4">
                <div className="flex gap-4 mb-5">
                    <div className="w-1/4">
                        <Input
                            value={creditorNameSearch}
                            label="Search Creditor Name"
                            placeholder="Search Creditor Name"
                            type="text"
                            onChange={(event) =>
                                setCreditorNameSearch(
                                    event.target.value === '' ? undefined : event.target.value
                                )
                            }
                            dark
                        />
                    </div>
                    <div className="w-1/4">
                        <Input
                            value={creditorCodeSearch}
                            label="Search Supplier Code"
                            placeholder="Search Supplier Code"
                            type="text"
                            onChange={(event) =>
                                setCreditorCodeSearch(
                                    event.target.value === '' ? undefined : event.target.value
                                )
                            }
                            dark
                        />
                    </div>
                    <div className="w-1/4">
                        <Select
                            label="Companies"
                            disabled={getCompaniesService.loading}
                            placeholder="Select Company"
                            options={formatCompanies(true)}
                            onChange={(event) => setCompanyIdSearch(event.target.value)}
                        />
                    </div>
                </div>
                <table className="mb-5 w-full table-auto bg-white">
                    <thead>
                        <TableRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Company</TableHeader>
                            <TableHeader>Code</TableHeader>
                            <TableHeader />
                        </TableRow>
                    </thead>
                    <tbody>{renderCreditors()}</tbody>
                </table>
                {getCreditorsService.data && getCreditorsService.data.totalCount > PAGE_SIZE && (
                    <Pager
                        onNextPage={handleNextPage}
                        onPreviousPage={handlePreviousPage}
                        skip={skip}
                        pageSize={PAGE_SIZE}
                        objectList={getCreditorsService.data}
                    />
                )}
            </div>
        </div>
    );
}
