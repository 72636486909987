import { domain } from '../d-man';

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    emailConfirmed: boolean;
};

export function useGetUsers(skip?: number, take?: number, username?: string) {
    return domain.useGet<User[]>('/users', {
        requestConfig: {
            queryParams: {
                skip,
                take,
                username,
            },
        },
    });
}

export function useGetUser(userId: string) {
    return domain.useGet<User>(`/users/${userId}`);
}
