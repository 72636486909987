import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import clsx from 'clsx';
import { debounce } from 'throttle-debounce';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {
    Requisition,
    RequisitionItem,
    useCreateRequisition,
    useGetRequisitions,
} from '../../queries/Requisitions';
import Button from '../../components/Button';
import Table from '../../components/Table';
import Pager from '../../components/Pager';
import { useCurrentProjectId } from '../../queries/Accounts';
import { Trade, useGetTrades } from '../../queries/Trades';
import { useGetProject } from '../../queries/Projects';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { useGetUsers } from '../../queries/Users';
import { useGetPermissions } from '../../queries/Permissions';
import { readQueryParams, handleClearFilterFromURL } from '../../utils/Utils';
import Loader from '../../components/Loader';

const STATUS = {
    Completed: 'bg-green-200',
    Approved: 'bg-blue-200',
    Cancelled: 'bg-red-200',
    Draft: 'bg-yellow-100',
};

export default function Requisitions(props: RouteComponentProps) {
    const { navigate } = props;
    const PAGE_SIZE = 30;
    const DEBOUNCE_DELAY = 1000;

    const MAX_USERS = 10000;
    const users = useGetUsers(0, MAX_USERS);
    const usersRef = useRef(users);

    useEffect(() => {
        usersRef.current.reset();
    }, []);

    const params = window.location.search;

    const [skip, setSkip] = useState(0);
    const projectId = useCurrentProjectId();
    const [requisitionNumber, setRequisitionNumber] = useState(undefined as number | undefined);
    const [requisitionStatus, setRequisitionStatus] = useState(
        readQueryParams(params, 'status') || ('all' as string | undefined)
    );
    const [requisitionDateFrom, setRequisitionDateFrom] = useState(undefined as Date | undefined);
    const [requisitionDateTo, setRequisitionDateTo] = useState(undefined as Date | undefined);
    const [filterTrade, setFilterTrade] = useState(undefined as string | undefined);
    const [searchTrade, setSearchTrade] = useState(undefined as string | undefined);
    const [dateRequiredAt, setDateRequiredAt] = useState(undefined as Date | undefined);
    const [requisitionDatePayBy, setRequisitionDatePayBy] = useState(
        readQueryParams(params, 'payBy') || (undefined as Date | undefined)
    );
    const [stallRequisitions, setStallRequisitions] = useState(true);
    const [stallPermissions, setStallPermissions] = useState(true);

    const getPermissions = useGetPermissions('PROJECT', projectId.data, stallPermissions);

    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        getPermissions.data?.items.find((p) => p.code === 'Project_Show_Requisitions_screen')
            ?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    const shouldGetApprovedRequisitions = () => {
        if (
            requisitionStatus === 'approved' ||
            requisitionStatus === 'completed' ||
            requisitionStatus === 'sentBackByBuyer'
        ) {
            return true;
        }
        if (requisitionStatus === 'draft' || requisitionStatus === 'submittedToQS') {
            return false;
        }
        if (
            (requisitionStatus === 'all' || requisitionStatus === 'cancelled') &&
            getPermissions.data &&
            getPermissions.data?.items.find(
                (p) => p.code === 'Project_Show_Requisition_Approved_Status'
            )?.hasAccess
        ) {
            return undefined;
        }
        return false;
    };

    const shouldGetReviewRequested = () => {
        if (requisitionStatus === 'submittedToQS' || requisitionStatus === 'sentBackByBuyer') {
            return true;
        }
        if (requisitionStatus === 'all' || requisitionStatus === 'cancelled') {
            return undefined;
        }
        return false;
    };

    const shouldGetCompleted = () => {
        if (requisitionStatus === 'completed') {
            return true;
        }
        if (requisitionStatus === 'cancelled') {
            return undefined;
        }
        if (requisitionStatus !== 'all') {
            return false;
        }
        return undefined;
    };

    const shouldGetCancelled = () => {
        if (requisitionStatus === 'cancelled') {
            return true;
        }
        if (requisitionStatus === 'all') {
            return undefined;
        }
        return false;
    };

    const handleDateChange = (date: Date) => {
        setDateRequiredAt(
            new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0))
        );
    };

    const requisitions = useGetRequisitions(
        skip,
        PAGE_SIZE,
        projectId.data,
        shouldGetApprovedRequisitions(),
        shouldGetReviewRequested(),
        requisitionNumber,
        shouldGetCompleted(),
        shouldGetCancelled(),
        stallRequisitions,
        requisitionDateFrom,
        requisitionDateTo,
        filterTrade,
        requisitionDatePayBy ? new Date(requisitionDatePayBy) : undefined
    );

    const requisitionsRef = useRef(requisitions);

    const selectedProject = useGetProject(projectId.data);

    const trades = useGetTrades();

    const [tradeId, setTradeId] = useState(undefined as string | undefined);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpeningRequisitionDetail, setIsOpeningRequisitionDetail] = useState(false);
    const newRequisition = useCreateRequisition();

    const [searchRequisitionNumber, setSearchRequisitionNumber] = useState(
        undefined as number | undefined
    );
    const [searchRequisitionStatus, setSearchRequisitionStatus] = useState(
        readQueryParams(params, 'status') || (undefined as string | undefined)
    );
    const [searchRequisitionDateFrom, setSearchRequisitionDateFrom] = useState(
        undefined as Date | undefined
    );
    const [searchRequisitionDateTo, setSearchRequisitionDateTo] = useState(
        undefined as Date | undefined
    );
    const [searchRequisitionDatePayBy, setSearchRequisitionDatePayBy] = useState(
        readQueryParams(params, 'payBy') || (undefined as Date | undefined)
    );

    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Show_Requisition')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        if (projectId.data && getPermissions.data) {
            if (stallRequisitions) {
                setStallRequisitions(false);
            } else {
                setSkip(0);
                requisitionsRef.current.reset();
            }
        }
        if (projectId.data) {
            setStallPermissions(false);
        }
        // eslint-disable-next-line
    }, [projectId.data, getPermissions.data]);

    useEffect(() => {
        if (newRequisition.error) {
            setErrorMessage(newRequisition.error || 'Failed to create new requisition');
        }
    }, [newRequisition.error]);

    const toDetail = (id?: string) => {
        if (id) {
            window.open(`/requisitions/${id}`);
        }
    };

    const toDetailRef = useRef(toDetail);

    const post = async () => {
        setIsLoading(true);
        setErrorMessage('');
        if (!projectId.data || !tradeId || !dateRequiredAt) {
            setErrorMessage('Please capture all the fields above');
        } else {
            const data = {
                projectId: projectId.data,
                tradeId,
                requiredAt: dateRequiredAt,
            };
            await newRequisition.execute(data);
            requisitions.reset();
            setIsOpeningRequisitionDetail(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (isOpeningRequisitionDetail && newRequisition.data) {
            toDetailRef.current(newRequisition.data.id);
            setIsOpeningRequisitionDetail(false);
        }
    }, [newRequisition.data, isOpeningRequisitionDetail]);

    const getStatusClasses = (requisition: Requisition) => {
        if (
            requisition.isCancelled ||
            requisition.cancelledBy !== null ||
            requisition.cancelledAt !== null
        ) {
            return STATUS.Cancelled;
        }
        if (
            (requisition.approvedAt !== null || requisition.approvedBy !== null) &&
            requisition.lineItems &&
            requisition?.lineItems.length > 0 &&
            requisition.lineItems.every((i: RequisitionItem) => i.orderId !== null)
        ) {
            return STATUS.Completed;
        }
        if (requisition.approvedAt !== null || requisition.approvedBy !== null) {
            return STATUS.Approved;
        }
        return STATUS.Draft;
    };

    const getStatus = (requisition: Requisition) => {
        if (
            requisition.isCancelled ||
            requisition.cancelledBy !== null ||
            requisition.cancelledAt !== null
        ) {
            return 'Cancelled';
        }
        if (
            (requisition.approvedAt !== null || requisition.approvedBy !== null) &&
            requisition.lineItems &&
            requisition?.lineItems.length > 0 &&
            requisition.lineItems.every((i: RequisitionItem) => i.orderId !== null)
        ) {
            return 'Completed';
        }
        if (
            (requisition.reviewRequestedAt !== null || requisition.reviewRequestedBy !== null) &&
            (requisition.approvedAt !== null || requisition.approvedBy !== null)
        ) {
            return 'Sent back by Buyer';
        }
        if (requisition.approvedAt !== null || requisition.approvedBy !== null) {
            return 'Approved';
        }
        if (requisition.reviewRequestedAt !== null || requisition.reviewRequestedBy !== null) {
            return 'Submitted to QS';
        }
        return 'Draft';
    };

    const formatTrades = () => {
        if (trades && trades.data && trades.data.items.length > 0) {
            if (selectedProject.data?.isOverhead) {
                return trades.data.items
                    .filter((trade: Trade) => trade.description === 'OVERHEADS')
                    .map((trade: Trade) => ({
                        value: trade.id,
                        label: trade.description,
                    }));
            }
            return trades.data.items
                .filter((trade: Trade) => trade.description !== 'OVERHEADS')
                .map((trade: Trade) => ({
                    value: trade.id,
                    label: `${trade.code} - ${trade.description}`,
                }));
        }
        return [];
    };

    const handleNextPage = () => {
        setSkip(skip + PAGE_SIZE);
        requisitions.reset();
    };

    const handlePreviousPage = () => {
        setSkip(Math.max(skip - PAGE_SIZE, 0));
        requisitions.reset();
    };

    const debounced = useRef(
        debounce(
            DEBOUNCE_DELAY,
            (
                newRequisitionNumberSearch?: number,
                newRequisitionStatus?: string,
                newRequisitionDateFrom?: Date,
                newRequisitionDateTo?: Date,
                newTrade?: string,
                newRequisitionPayBy?: Date
            ) => {
                setRequisitionNumber(newRequisitionNumberSearch);
                setRequisitionStatus(newRequisitionStatus);
                setRequisitionDateFrom(newRequisitionDateFrom);
                setRequisitionDateTo(newRequisitionDateTo);
                if (newTrade && newTrade !== '') {
                    setFilterTrade(newTrade);
                } else {
                    setFilterTrade(undefined);
                }
                setRequisitionDatePayBy(newRequisitionPayBy);
                requisitions.reset();
            }
        )
    );

    useEffect(() => {
        setSkip(0);
        handleClearFilterFromURL(params, 'status');
        handleClearFilterFromURL(params, 'payBy');
        if (
            searchRequisitionNumber !== undefined ||
            searchRequisitionStatus !== undefined ||
            searchRequisitionDateFrom !== undefined ||
            searchRequisitionDateTo !== undefined ||
            searchTrade !== undefined ||
            searchRequisitionDatePayBy !== undefined
        ) {
            debounced.current(
                searchRequisitionNumber,
                searchRequisitionStatus,
                searchRequisitionDateFrom,
                searchRequisitionDateTo,
                searchTrade,
                searchRequisitionDatePayBy ? new Date(searchRequisitionDatePayBy) : undefined
            );
        } else {
            requisitionsRef.current.reset();
        }
    }, [
        searchRequisitionNumber,
        searchRequisitionStatus,
        searchRequisitionDateFrom,
        searchRequisitionDateTo,
        searchTrade,
        searchRequisitionDatePayBy,
        debounced,
        requisitionsRef,
        params,
    ]);

    const formatRequisitionStatuses = () => {
        const statuses: { value: string; label: string }[] = [
            { value: 'all', label: 'All' },
            { value: 'draft', label: 'Draft' },
            { value: 'submittedToQS', label: 'Submitted to QS' },
        ];

        if (
            getPermissions.data?.items.find(
                (p) => p.code === 'Project_Show_Requisition_Sent_Back_To_Buyer_Status'
            )?.hasAccess
        ) {
            statuses.push({ value: 'sentBackByBuyer', label: 'Sent back by Buyer' });
        }

        if (
            getPermissions.data?.items.find(
                (p) => p.code === 'Project_Show_Requisition_Approved_Status'
            )?.hasAccess
        ) {
            statuses.push({ value: 'approved', label: 'Approved' });
            statuses.push({ value: 'completed', label: 'Completed' });
            statuses.push({ value: 'cancelled', label: 'Cancelled' });
        }

        return statuses;
    };

    const renderRequisition = () => (
        <>
            <div className="flex flex-row mb-5 gap-4 flex-wrap">
                <div>
                    <Input
                        value={searchRequisitionNumber}
                        label="Requisition Number"
                        placeholder="Search Requisition Number"
                        disabled={requisitions.loading}
                        type="number"
                        onChange={(event) =>
                            setSearchRequisitionNumber(
                                event.target.value === ''
                                    ? undefined
                                    : parseInt(event.target.value, 10)
                            )
                        }
                        dark
                    />
                </div>
                <div>
                    <Select
                        label="Requisition Status"
                        disabled={requisitions.loading}
                        placeholder="Search Requisition Status"
                        options={formatRequisitionStatuses()}
                        onChange={(event) => setSearchRequisitionStatus(event.target.value)}
                        selected={searchRequisitionStatus}
                    />
                </div>
                <div className="self-center">
                    <Select
                        label="Trade"
                        disabled={requisitions.loading}
                        placeholder="Search Trade"
                        options={formatTrades()}
                        onChange={(event) => setSearchTrade(event.target.value)}
                    />
                </div>
                <div>
                    <div className="text-base text-white pb-1">Date From</div>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        disabled={requisitions.loading}
                        selected={searchRequisitionDateFrom}
                        onChange={(date: Date) => setSearchRequisitionDateFrom(date)}
                    />
                </div>
                <div>
                    <div className="text-base text-white pb-1">Date To</div>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        disabled={requisitions.loading}
                        selected={searchRequisitionDateTo}
                        onChange={(date: Date) => setSearchRequisitionDateTo(date)}
                    />
                </div>
                <div>
                    <div className="text-base text-white pb-1">Pay By</div>
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        disabled={requisitions.loading}
                        selected={requisitionDatePayBy ? new Date(requisitionDatePayBy) : undefined}
                        onChange={(date: Date) => setSearchRequisitionDatePayBy(date)}
                    />
                </div>
            </div>
            {/* Legend */}
            <div className="flex justify-end w-full">
                <div className="flex justify-end w-1/4">
                    <div className={`${STATUS.Completed} w-1/4 p-1 text-sm text-center`}>
                        Completed
                    </div>
                    <div className={`${STATUS.Approved} w-1/4 p-1 text-sm text-center`}>
                        Approved
                    </div>
                    <div className={`${STATUS.Cancelled} w-1/4 p-1 text-sm text-center`}>
                        Cancelled
                    </div>
                    <div className={`${STATUS.Draft} w-1/4 p-1 text-sm text-center`}>Draft</div>
                </div>
            </div>
            <Table
                loading={requisitions.loading && 'Fetching Requisitions from server'}
                error={
                    requisitions.error &&
                    `Failed to fetch Requisition list from the server (${requisitions.error})`
                }
                onClick={(requisition) => toDetail(requisition.id)}
                data={requisitions.data?.items}
                columns={[
                    {
                        key: 'reqNo',
                        title: 'Req. No',
                        render: (requisition) => {
                            const prefix =
                                requisition.project?.businessUnit?.prefixRequisition || '';
                            return `${prefix}${requisition.number.toString().padStart(6, '0')}`;
                        },
                    },
                    {
                        key: 'createdAt',
                        title: 'Created',
                        render: (requisition) =>
                            new Date(requisition.createdAt || '').toLocaleDateString('en-ZA'),
                    },
                    {
                        key: 'createdBy',
                        title: 'Created By',
                        render: (requisition) => {
                            const chosenUser =
                                requisition.createdBy &&
                                users.data?.find(
                                    (userItem) =>
                                        userItem.id?.toUpperCase() ===
                                        requisition.createdBy?.toUpperCase()
                                );
                            if (chosenUser) {
                                return `${chosenUser.firstName} ${chosenUser.lastName}`;
                            }
                            return '';
                        },
                    },
                    {
                        key: 'approvedBy',
                        title: 'Approved By',
                        render: (requisition) => {
                            const chosenUser =
                                requisition.approvedBy &&
                                users.data?.find(
                                    (userItem) =>
                                        userItem.id?.toUpperCase() ===
                                        requisition.approvedBy?.toUpperCase()
                                );
                            if (chosenUser) {
                                return `${chosenUser.firstName} ${chosenUser.lastName}`;
                            }
                            return '';
                        },
                    },
                    {
                        key: 'approvedAt',
                        title: 'Approved At',
                        render: (requisition) =>
                            requisition.approvedAt
                                ? new Date(requisition.approvedAt || '').toLocaleString('en-ZA')
                                : '',
                    },
                    {
                        key: 'trade',
                        title: 'Trade',
                        render: (requisition) => {
                            const { lineItems } = requisition;
                            return (
                                lineItems &&
                                lineItems[0] &&
                                lineItems[0].costCode?.trade?.description
                            );
                        },
                    },
                    {
                        key: 'note',
                        title: 'Status',
                        render: (requisition) => getStatus(requisition),
                    },
                    {
                        key: 'sentAt',
                        title: 'RFQ Sent',
                        render: (requisition) => (
                            <input type="checkbox" disabled checked={requisition.sentAt !== null} />
                        ),
                    },
                    {
                        key: 'approvedAllocation',
                        title: 'Allocated Buyer',
                        render: (requisition) =>
                            requisition.approvedAllocation
                                ? `${
                                      users.data?.find(
                                          (i) =>
                                              i.id.toUpperCase() ===
                                              requisition.approvedAllocation?.toUpperCase()
                                      )?.firstName
                                  } ${
                                      users.data?.find(
                                          (i) =>
                                              i.id.toUpperCase() ===
                                              requisition.approvedAllocation?.toUpperCase()
                                      )?.lastName
                                  }`
                                : '',
                    },
                ]}
                getStatus={(requisition) => getStatusClasses(requisition)}
            />
            {requisitions.data && requisitions.data.totalCount > PAGE_SIZE && (
                <Pager
                    onNextPage={handleNextPage}
                    onPreviousPage={handlePreviousPage}
                    skip={skip}
                    pageSize={PAGE_SIZE}
                    objectList={requisitions.data}
                />
            )}
        </>
    );

    return (
        <>
            {!getPermissions.data && <Loader message="Loading requisitions..." />}
            {getPermissions.data && (
                <div className="flex flex-col w-full">
                    {getPermissions.data?.items.find((p) => p.code === 'Project_Create_Requisition')
                        ?.hasAccess && (
                        <div className="flex bg-gray-400 border rounded-md m-4 justify-between items-center">
                            <div className="flex gap-4">
                                <div className="flex items-center gap-4">
                                    <div className="text-md p-3 font-bold">New Requisition</div>
                                    <div className="flex content-center">
                                        <Select
                                            // label="Trade"
                                            placeholder="Search Trades"
                                            options={formatTrades()}
                                            onChange={(event) => setTradeId(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <div className="text-md font-bold">Required At</div>
                                    <div>
                                        <DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={dateRequiredAt}
                                            placeholderText="Select Date"
                                            onChange={(date: Date) => handleDateChange(date)}
                                            className="border rounded-sm w-full pl-0.5"
                                        />
                                    </div>
                                </div>
                            </div>

                            {errorMessage && (
                                <div className="flex justify-end text-red-500">{errorMessage}</div>
                            )}

                            <div className="p-3 flex justify-end">
                                <div className="flex flex-row-reverse">
                                    <div>
                                        <Button disabled={isLoading} onClick={post}>
                                            {!isLoading ? 'Create' : <div>Loading</div>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div
                        className={clsx(`${requisitions.data ? 'h-full' : 'h-screen'} m-4`, {
                            'w-full': !getPermissions.data?.items.find(
                                (p) => p.code === 'Project_Create_Requisition'
                            )?.hasAccess,
                        })}
                    >
                        {renderRequisition()}
                    </div>
                </div>
            )}
        </>
    );
}
