import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { debounce } from 'throttle-debounce';
import Input from '../../components/Input';
import Button from '../../components/Button';
import {
    useCurrentProjectId,
    useEmailNewUser,
    useGetRegistrationLink,
    useNewUser,
} from '../../queries/Accounts';
import { useGetUsers, User } from '../../queries/Users';
import {
    Role,
    useCreateUserRole,
    useDeleteUserRoles,
    useGetRoles,
    useGetUserRoles,
    UserRole,
} from '../../queries/Roles';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import { Creditor, useGetCreditors } from '../../queries/Creditors';
import { BusinessUnit, useGetBusinessUnits } from '../../queries/BusinessUnits';
import { Project, useGetProjects } from '../../queries/Projects';
import { TrashCan } from '../../components/Icons';
import TypeAhead from '../../components/TypeAhead';
import { useGetPermissions } from '../../queries/Permissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Users(props: RouteComponentProps) {
    const DEBOUNCE_DELAY = 1000;

    const roles = useGetRoles();

    const newUser = useNewUser();
    const newEmailUser = useEmailNewUser();
    const registrationLinkUser = useGetRegistrationLink();
    const getUserRoles = useGetUserRoles();
    const getUserRolesRef = useRef(getUserRoles);
    const newUserRole = useCreateUserRole();
    const deleteUserRoles = useDeleteUserRoles();

    const businessUnits = useGetBusinessUnits(undefined, undefined);
    const projects = useGetProjects(undefined, undefined);
    const creditors = useGetCreditors(undefined, undefined);

    const [copied, setCopied] = useState(undefined as string | undefined);
    const [registrationLinks, setRegistrationLinks] = useState(
        [] as { userId: string; link: string }[]
    );
    const [userName, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [userErrorMessage, setUserErrorMessage] = useState('');
    const [isManageRolesModalOpen, setIsManageRolesModalOpen] = useState(false);

    const [usernameSearch, setUsernameSearch] = useState(undefined as string | undefined);
    const [currentUserName, setCurrentUserName] = useState(undefined as string | undefined);
    const [newRoleName, setNewRoleName] = useState(undefined as string | undefined);
    const [newRoleScope, setNewRoleScope] = useState(undefined as string | undefined);
    const [newRoleCreditorId, setNewRoleCreditorId] = useState(undefined as string | undefined);
    const [newRoleBusinessUnitId, setNewRoleBusinessUnitId] = useState(
        undefined as string | undefined
    );
    const [newRoleProjectId, setNewRoleProjectId] = useState(undefined as string | undefined);
    const [newRoleErrorMessage, setNewRoleErrorMessage] = useState('');

    const users = useGetUsers(undefined, undefined, usernameSearch);

    const usersRef = useRef(users);

    const projectId = useCurrentProjectId();
    const getPermissions = useGetPermissions('PROJECT', projectId.data);
    const { navigate } = props;
    // REDIRECT IF NOT CORRECT ROLE
    if (
        getPermissions.data &&
        !getPermissions.data?.items.find((p) => p.code === 'Project_Allow_Admin')?.hasAccess &&
        navigate
    ) {
        navigate(`/`);
    }

    useEffect(() => {
        getUserRolesRef.current.reset();
    }, []);

    const debounced = useRef(
        debounce(DEBOUNCE_DELAY, (newCreditorNameSearch?: string) => {
            setUsernameSearch(newCreditorNameSearch);
            users.reset();
        })
    );

    useEffect(() => {
        if (usernameSearch !== undefined) {
            debounced.current(usernameSearch);
        } else {
            usersRef.current.reset();
        }
    }, [usernameSearch, debounced, usersRef]);

    const postUserDetails = async () => {
        setUserErrorMessage('');
        setIsLoading(true);
        await newUser.execute({
            // callbackUrlRoute: `${process.env.REACT_APP_WEB_URL || ''}verification`,
            email,
            username: userName,
        });
        users.reset();
        setIsLoading(false);
    };

    const sendNewEmail = async (existingUser: User) => {
        setUserErrorMessage('');
        setIsLoading(true);
        await newEmailUser.execute({
            callbackUrlRoute: `${process.env.REACT_APP_WEB_URL || ''}verification`,
            userName: existingUser.userName,
        });
        users.reset();
        setIsLoading(false);
    };

    const copyRegistrationLink = async (existingUser: User) => {
        setUserErrorMessage('');
        setIsLoading(true);
        const link = await registrationLinkUser.execute({
            callbackUrlRoute: `${process.env.REACT_APP_WEB_URL || ''}verification`,
            userName: existingUser.userName,
        });
        if (link[1]) {
            setRegistrationLinks([
                ...registrationLinks,
                { userId: existingUser.id, link: link[1] },
            ]);
        }
        users.reset();
        setIsLoading(false);
    };

    const clearUserFields = () => {
        setUserErrorMessage('');
        setUsername('');
        setEmail('');
    };

    useEffect(() => {
        if (newUser.error) {
            setUserErrorMessage(newUser.error || 'Failed to create new user');
        }
    }, [newUser.error]);

    const getStatus = (user: User) => {
        if (!getUserRoles.data) {
            return 'Unable to load roles';
        }
        if (getUserRoles.data && !getUserRoles.data.some((i) => i.userName === user.userName)) {
            return 'Needs at least 1 role';
        }
        if (!user.emailConfirmed) {
            return 'User Registration incomplete';
        }
        return 'Ready to login';
    };

    const getDistinctUserRolesForUser = (user: User) => {
        const distinctRolesForUser: UserRole[] = [];
        if (getUserRoles.data) {
            getUserRoles.data
                .filter((i) => i.userName === user.userName)
                .forEach((i: UserRole) => {
                    if (!distinctRolesForUser.some((j: UserRole) => j.roleName === i.roleName)) {
                        distinctRolesForUser.push(i);
                    }
                });
        }
        return distinctRolesForUser;
    };

    const renderUsers = () => {
        if (users.loading) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="w-8 h-8 animate-spin text-orange-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">Fetching Users from server</div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (users.error) {
            return (
                <tr>
                    <td className="border p-1" colSpan={2}>
                        <div className="flex flex-col items-center">
                            <div className="pt-3 pb-3">
                                <svg
                                    className="text-red-500 w-8 h-8 animate-pulse"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                            </div>
                            <div className="text-sm">
                                Failed to fetch User list from the server ({users.error})
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
        if (users.data) {
            return users.data.map((user: User) => (
                <tr className="hover:bg-gray-200">
                    <td className="border p-1 text-xs">{user.userName}</td>
                    <td className="border p-1 text-xs">
                        {user.firstName} {user.lastName}
                    </td>
                    <td className="border p-1 text-xs">{user.email}</td>
                    <td className="border p-1 text-xs">
                        <div className="py-1 flex flex-wrap">
                            {getDistinctUserRolesForUser(user)?.map((userRole: UserRole) => (
                                <div className="border rounded-md mr-1 p-1 bg-gray-300">
                                    {userRole.roleName}
                                </div>
                            ))}
                        </div>
                    </td>
                    <td className="border p-1 text-xs">{getStatus(user)}</td>
                    <td className="border p-1 text-xs flex space-x-1">
                        <Button
                            onClick={() => {
                                setCurrentUserName(user.userName);
                                setIsManageRolesModalOpen(true);
                            }}
                        >
                            Manage Roles
                        </Button>
                        {getDistinctUserRolesForUser(user).length > 0 && (
                            <Button onClick={() => sendNewEmail(user)}>
                                Send Registration Email
                            </Button>
                        )}

                        {getDistinctUserRolesForUser(user).length > 0 &&
                            registrationLinks.every((i) => i.userId !== user.id) && (
                                <Button onClick={() => copyRegistrationLink(user)}>
                                    Create Registration Link
                                </Button>
                            )}

                        {registrationLinks.some((i) => i.userId === user.id) && (
                            <Button
                                className="text-sm text-blue-300 underline"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        registrationLinks.find((i) => i.userId === user.id)?.link ||
                                            ''
                                    );
                                    setCopied(user.id);
                                }}
                            >
                                {copied === user.id
                                    ? 'Copied Registration Link'
                                    : 'Copy Registration Link'}
                            </Button>
                        )}
                    </td>
                </tr>
            ));
        }
        return null;
    };

    const formatRoles = () => {
        if (roles && roles.data && roles.data.length > 0) {
            return roles.data.map((role: Role) => ({
                id: role.name,
                name: role.name,
            }));
        }
        return [];
    };

    const formatRoleCreditors = () => {
        if (creditors && creditors.data && creditors.data.items.length > 0) {
            return creditors.data.items.map((creditor: Creditor) => ({
                id: creditor.id || '',
                name: creditor.name || '',
            }));
        }
        return [];
    };

    const formatRoleBusinessUnits = () => {
        if (businessUnits && businessUnits.data && businessUnits.data.items.length > 0) {
            return businessUnits.data.items.map((businessUnit: BusinessUnit) => ({
                id: businessUnit.id || '',
                name: businessUnit.name || '',
            }));
        }
        return [];
    };

    const formatRoleProjects = () => {
        if (projects && projects.data && projects.data.items.length > 0) {
            return projects.data.items.map((project: Project) => ({
                id: project.id || '',
                name: `${project.jobCode} - ${project.name}` || '',
            }));
        }
        return [];
    };

    const clearNewRoleFields = () => {
        setNewRoleCreditorId(undefined);
        setNewRoleProjectId(undefined);
        setNewRoleBusinessUnitId(undefined);
        setNewRoleErrorMessage('');
    };

    const deleteUserRole = async (userRole: UserRole) => {
        await deleteUserRoles.execute(userRole);
        getUserRoles.reset();
    };

    const postCreateNewUserRole = async (roleName: string) => {
        setNewRoleErrorMessage('');
        if (!currentUserName) {
            setNewRoleErrorMessage('Please select a User Name');
        } else {
            await newUserRole.execute({
                userName: currentUserName,
                projectId: newRoleProjectId,
                businessUnitId: newRoleBusinessUnitId,
                creditorId: newRoleCreditorId,
                roleName,
            });
            getUserRoles.reset();
            users.reset();
        }
    };

    useEffect(() => {
        setNewRoleErrorMessage(newUserRole.error || '');
    }, [newUserRole.error]);

    return (
        <div className="flex">
            <Modal
                title="Manage Roles"
                onClose={() => {
                    setIsManageRolesModalOpen(false);
                    setCurrentUserName(undefined);
                    setNewRoleName(undefined);
                    setNewRoleScope(undefined);
                    clearNewRoleFields();
                }}
                isOpen={isManageRolesModalOpen}
            >
                <div className="mb-9 space-y-3">
                    <TypeAhead
                        placeholder="Search Roles"
                        caseSensitive={false}
                        label="Roles"
                        options={formatRoles()}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onClick={(event: any) => {
                            setNewRoleName(event.target.id);
                            setNewRoleScope(
                                roles.data?.find((i) => i.name === event.target.id)?.scope
                            );
                            clearNewRoleFields();
                        }}
                        onChanged={() => {
                            setNewRoleName(undefined);
                            setNewRoleScope(undefined);
                            clearNewRoleFields();
                        }}
                        onCleared={() => {
                            setNewRoleName(undefined);
                            setNewRoleScope(undefined);
                            clearNewRoleFields();
                        }}
                    />

                    {newRoleScope && newRoleScope === 'CREDITOR' && (
                        <TypeAhead
                            placeholder="Search Creditors"
                            caseSensitive={false}
                            label="Creditor"
                            options={formatRoleCreditors()}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(event: any) => setNewRoleCreditorId(event.target.id)}
                            onChanged={() => setNewRoleCreditorId(undefined)}
                            onCleared={() => setNewRoleCreditorId(undefined)}
                        />
                    )}

                    {newRoleName && newRoleScope === 'PROJECT' && (
                        <TypeAhead
                            placeholder="Search Projects"
                            caseSensitive={false}
                            label="Project"
                            options={formatRoleProjects()}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(event: any) => setNewRoleProjectId(event.target.id)}
                            onChanged={() => setNewRoleProjectId(undefined)}
                            onCleared={() => setNewRoleProjectId(undefined)}
                        />
                    )}

                    {newRoleName && newRoleScope === 'BUSINESSUNIT' && (
                        <TypeAhead
                            placeholder="Search Business Units"
                            caseSensitive={false}
                            label="Business Unit"
                            options={formatRoleBusinessUnits()}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(event: any) => setNewRoleBusinessUnitId(event.target.id)}
                            onChanged={() => setNewRoleBusinessUnitId(undefined)}
                            onCleared={() => setNewRoleBusinessUnitId(undefined)}
                        />
                    )}

                    <div className="flex mb-5 text-red-500 text-xs">{newRoleErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        {newRoleName && (
                            <Button onClick={() => postCreateNewUserRole(newRoleName)}>
                                Add Role
                            </Button>
                        )}
                    </div>
                </div>
                Existing Roles
                <div className="flex justify-between">
                    <Table<UserRole>
                        loading={getUserRoles.loading && 'Fetching Files from server'}
                        error={
                            getUserRoles.error &&
                            `Failed to fetch File list from the server (${getUserRoles.error})`
                        }
                        data={
                            (getUserRoles.data &&
                                getUserRoles.data.filter((i) => i.userName === currentUserName)) ||
                            []
                        }
                        columns={[
                            {
                                key: 'roleName',
                                title: 'Role Name',
                            },
                            {
                                key: 'projectId',
                                title: 'Project',
                                render: (role) =>
                                    projects.data &&
                                    projects.data.items.find((i) => i.id === role.projectId)?.name,
                            },
                            {
                                key: 'businessUnitId',
                                title: 'Business Unit',
                                render: (role) =>
                                    businessUnits.data &&
                                    businessUnits.data.items.find(
                                        (i) => i.id === role.businessUnitId
                                    )?.name,
                            },
                            {
                                key: 'creditorId',
                                title: 'Creditor',
                                render: (role) =>
                                    creditors.data &&
                                    creditors.data.items.find((i) => i.id === role.creditorId)
                                        ?.name,
                            },
                            {
                                key: 'actions',
                                title: 'Actions',
                                render: (role) => (
                                    <TrashCan
                                        className="cursor-pointer flex items-center"
                                        id=""
                                        onClick={() => deleteUserRole(role)}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                <div />
            </Modal>
            <div className="bg-gray-400 border rounded-md w-1/4 h-full m-4">
                <div className="text-sm p-3 font-bold">Add New User</div>
                <hr />
                <div className="p-3">
                    <div className="mb-5">
                        <Input
                            label="Username"
                            value={userName}
                            type="text"
                            onChange={(event) => setUsername(event.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <Input
                            label="Email"
                            value={email}
                            type="email"
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                </div>
                <hr />
                <div className="p-3">
                    <div className="flex mb-5 text-red-500">{userErrorMessage}</div>
                    <div className="flex flex-row-reverse">
                        <div className="pl-2">
                            <Button onClick={clearUserFields}>Clear</Button>
                        </div>
                        <div>
                            <Button onClick={postUserDetails}>
                                {!isLoading ? 'Submit' : <div>Loading</div>}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-3/4 h-full m-4">
                <div className="mb-5">
                    <Input
                        value={usernameSearch}
                        label="Search User"
                        placeholder="Search User"
                        type="text"
                        onChange={(event) =>
                            setUsernameSearch(
                                event.target.value === '' ? undefined : event.target.value
                            )
                        }
                        dark
                    />
                </div>
                <table className="table-auto bg-white w-full">
                    <thead>
                        <tr>
                            <th className="border p-1 text-xs">Username</th>
                            <th className="border p-1 text-xs">Name</th>
                            <th className="border p-1 text-xs">Email</th>
                            <th className="border p-1 text-xs">Roles</th>
                            <th className="border p-1 text-xs">Status</th>
                            <th className="border p-1 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>{renderUsers()}</tbody>
                </table>
            </div>
        </div>
    );
}
